import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';

import { AssetService } from '../asset.service';
import { AddHardwareTypesComponent } from './add-hardwaretypes/add-hardwaretypes.component';

@Component({
  selector: 'app-hardwaretypes',
  templateUrl: './hardwaretypes.component.html',
  styleUrls: ['./hardwaretypes.component.scss']
})
export class HardwaretypesComponent implements OnInit {
  @Input('filters') filters: any = {};
  assets: any = [];
  sbs: any = null;
  pageNo: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  constructor(
    private assetService: AssetService,
    private modalService: NgbModal,
    private _toastr: ToastrService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getAssets();
    this.getAssetCount();
  }
  getAssets(loader: boolean = true) {
    var req = `?pageNo=${this.pageNo}&pageSize=${this.pageSize}`;
    if (this.filters.searchText) {
      req += '&searchText=' + this.filters.searchText;
    }
    if (this.sbs) this.sbs.unsubscribe();
    this.sbs = this.assetService.getAssetsTypes(req, loader).subscribe((res: any) => {
      this.assets = this.assets.concat(res.map((data) => {
        data.subType = data.subTypes.map((sb) => { return sb.type; });
        return data;
      }));
    });
  }
  getAssetCount() {
    var req = ``;
    if (this.filters.searchText) {
      req += '?searchText=' + this.filters.searchText;
    }
    this.assetService.getAssetsTypeCount(req).subscribe((res: any) => {
      this.totalCount = res;
    });
  }
  deleteAsset(ma) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.modalData = {
      heading: 'Confirm to delete',
      description: 'Do you want to delete assets?'
    };
    modalRef.result.then(() => {
      this.assetService.deleteAssetsType(ma.id).subscribe((res: any) => {
        this.resetDetails();
        this._toastr.success('Assets deleted successfully');
      })
    }).catch(e => {
    });
  }
  onScroll(event) {
    if (this.assets && this.assets.length) {
      this.pageNo = event;
      this.getAssets();
    }
  }
  resetDetails() {
    this.assets = [];
    this.pageNo = 1;
    this.getAssets();
    this.getAssetCount();
  }
  onSearchTxt() {
    this.assets = [];
    this.pageNo = 1;
    this.getAssets(false);
    this.getAssetCount();
  }
  addAssets(data: any = null) {
    const modalRef = this.modalService.open(AddHardwareTypesComponent);
    if (data) {
      modalRef.componentInstance.assetDetails = data;
    }
    modalRef.result.then((result) => {
      if (result) {
        this.resetDetails();
      }
    }).catch(e => {
    });
  }
}
