import { NgZone, OnDestroy } from '@angular/core';
import { AfterContentInit, ContentChildren, Directive, EventEmitter, Input, Optional, Output, QueryList } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/common.service';
@Directive({
    selector: 'option'
})
export class DefaultDirective {
    @Input() default: boolean;
    @Input() value: string;
    constructor() {
    }
}
@Directive({
    selector: 'select'
})
export class SelectDefaultDirective implements AfterContentInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter(false);
    @Output() change: EventEmitter<any> = new EventEmitter(false);
    @ContentChildren(DefaultDirective) options: QueryList<DefaultDirective>;
    constructor(@Optional() private control: NgControl,
        private _ngZone: NgZone,
        private commonService: CommonService) {
        this.commonService.$updateDefault.
            pipe(
                takeUntil(this.destroyed$)).
            subscribe(() => {
                this.setDefault();
            })
    }
    ngAfterContentInit() {
        this.setDefault();
    }
    setDefault() {
        var _self = this;
        var obj: any = _self.control;
        if (_self.control && !obj.model && !_self.control.control.value) {
            this.options.map((option) => {
                if (option.default) {
                    if (Object.keys(obj).indexOf('model') != -1) {
                        this._ngZone.run(() => {
                            setTimeout(() => {
                                this.ngModelChange.emit(option.value);
                            }, 100);
                            //this.change.emit(new Event('change'));
                        }
                        );
                    } else {
                        this._ngZone.run(() => {
                            setTimeout(() => {
                                if (this.change) this.change.emit({ target: { value: option.value } });
                                _self.control.control.setValue(option.value);
                                _self.control.control.updateValueAndValidity();
                            }, 100)
                        })
                    }
                }
            })
        }
    }
    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}

