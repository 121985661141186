<form [formGroup]="assetsForm" (ngSubmit)="saveAsset()" class="row form m-1 mt-3">
    <div class="row form m-4">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <h1 class="headTitle">{{(assetDetails.id ? 'update_reseller' : 'add_reseller') | translate}}</h1>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="" translate="reseller_vendor_name"></label>
                <input class="form-control" formControlName="resellerName" placeholder="">
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <button type="submit" class="btn btn-primary btn-lg next" translate="reseller_save"></button>
            <button type="submit" class="btn btn-primary btn-lg prev"
                (click)="activeModal.dismiss()"><u>{{'reseller_cancel' | translate}}</u></button>
        </div>
    </div>
</form>