import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MsalService, MsalBroadcastService } from './msal';
import { MSAL_GUARD_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { CommonService } from './common.service';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Kereby';
  isIframe = false;
  loggedIn = false;
  showSettingMenus:boolean;
  navbarOpen: boolean = false;
  clicked
  _el  
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    public commonService:CommonService) {
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.checkAccount();
    this.authService.authResolve.subscribe(()=>{
      this.checkAccount();
    })
  }

  checkAccount() {
    this.loggedIn = this.authService.getAllAccounts().length > 0;
    console.log(this.loggedIn);
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.loginPopup({...this.msalGuardConfig.authRequest})
        .subscribe(() => {
          this.checkAccount();
        });
    } else {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest});
    }
  }

  logout() {
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('profile');
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  public toggleNavbar() {
    console.log("Iam gere")
    this.navbarOpen = !this.navbarOpen;
  }

  public onClick(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicked = true;
  }

  // @HostListener('document:click', ['event'])
  // private clickedOutside(event): void {
  //   if (this.clicked) {
  //       this._el.nativeElement.querySelector('.dropdown-menu').classList.toggle('show')
  //   }
  // }
}