<h1 class="headTitle" translate="email_email_template"></h1>
<!-- filter -->
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row">
		<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 filter">
			<h1 class="headTitle mt-2 ml-3">{{(id ? 'email_edit_email_template' : 'email_add_email_template') | translate}}</h1>
		</div>
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-right">
		</div>
	</div>
</div>
<!-- listing header -->
<div class="">
	<div class="card b-radius p-4">
		<form [formGroup]="emailTemplateForm" class="row form m-1 mt-3">
			<div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="email_templateName"></label>
					<input class="form-control" formControlName="templateName" placeholder="">
				</div>
			</div>
			<div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="email_templateType"></label>
					<select formControlName="templateType">
						<option value="WelcomeEmail">WelcomeEmail</option>
						<option value="GoodByeEmail">GoodByeEmail</option>
					</select>
				</div>
            </div>
            <div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="email_templatesubject"></label>
					<input class="form-control" formControlName="subject" placeholder="">
				</div>
            </div>
            <div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="email_templatefrom"></label>
					<input class="form-control" formControlName="from" placeholder="">
				</div>
            </div>
            <div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="email_templatereplyTo"></label>
					<input class="form-control" formControlName="replyTo" placeholder="">
				</div>
            </div>
            <div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="email_templatecc"></label>
					<input class="form-control" formControlName="cc" placeholder="">
				</div>
            </div>
            <div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="email_templatebcc"></label>
					<input class="form-control" formControlName="bcc" placeholder="">
				</div>
            </div>
            
			<div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="email_isActive"></label>
					<input formControlName="isActive" type="checkbox" />
					
				</div>
            </div>
            <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 mt-3">
				<div class="form-group">
                    <label for="" translate="email_templateBody"></label>
                    <angular-editor formControlName="body" [config]="editorConfig"></angular-editor>
				</div>
            </div>
            

			<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
				<button type="submit" class="btn btn-primary btn-lg" [disabled]="emailTemplateForm.invalid" (click)="saveEmailTemplate()" translate="email_save"></button>
				<button type="submit" class="btn btn-primary btn-lg" routerLink="/emailtemplate"><u>{{'email_cancel' | translate}}</u></button>
			</div>
		</form>
	</div>
</div>