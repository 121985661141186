import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Host, Inject, InjectionToken, Input, Optional, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY, fromEvent, merge, Observable, ReplaySubject } from 'rxjs';
import { shareReplay, takeUntil, tap } from 'rxjs/operators';

@Directive({
    selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective {
    submit$: Observable<Event>;
    ref: ComponentRef<ControlErrorComponent>;
    container: ViewContainerRef;
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1)
    constructor(private control: NgControl,
        @Optional() @Host() private form: FormSubmitDirective,
        @Inject(FORM_ERRORS) private errors,
        private vcr: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        @Optional() controlErrorContainer: ControlErrorContainerDirective,) {
        this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
        this.submit$ = this.form ? this.form.submit$ : EMPTY;
    }

    ngOnInit() {
        merge(
            this.submit$,
            this.control.valueChanges
        ).pipe(
            takeUntil(this.destroyed$),
            untilDestroyed(this)).subscribe((v) => {
                const controlErrors = this.control.errors;
                if (controlErrors) {
                    const firstKey = Object.keys(controlErrors)[0];
                    const getError = this.errors[firstKey];
                    const text = getError(controlErrors[firstKey]);
                    this.setError(text);
                } else if (this.ref) {
                    this.setError(null);
                }
            })
    }

    setError(text: string) {
        if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
            this.ref = this.vcr.createComponent(factory);
        }

        this.ref.instance.text = text;
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}


export const defaultErrors = {
    required: (error) => `This field is required`,
    minlength: ({ requiredLength, actualLength }) => `Expect ${requiredLength} but got ${actualLength}`
}

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors
});

@Directive({
    selector: 'form'
})
export class FormSubmitDirective {
    submit$ = fromEvent(this.element, 'submit')
        .pipe(tap(() => {
            if (this.element.classList.contains('submitted') === false) {
                this.element.classList.add('submitted');
            }
        }), shareReplay(1))

    constructor(private host: ElementRef<HTMLFormElement>) { }

    get element() {
        return this.host.nativeElement;
    }
}

@Component({
    template: `<p class="form-error" [class.hide]="_hide">{{_text}}</p>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlErrorComponent {
    _text: string;
    _hide = true;

    @Input() set text(value) {
        if (value !== this._text) {
            this._text = value;
            this._hide = !value;
            this.cdr.detectChanges();
        }
    };

    constructor(private cdr: ChangeDetectorRef) { }

}

@Directive({
    selector: '[controlErrorContainer]'
})
export class ControlErrorContainerDirective {
    constructor(public vcr: ViewContainerRef) { }
}