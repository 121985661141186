<h1 class="headTitle" translate="it"></h1>
<!-- filter -->
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row">
		<div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 filter">
			<h1 class="headTitle mt-2 ml-3">{{(id ? 'it_edit_ext_user' : 'it_add_ext_user') | translate}}
			</h1>
		</div>
	</div>
</div>
<!-- listing  -->

<div class="">
	<div class="marT">
		<div class="tab-content">
			<!-- tab one -->
			<div class="tab-pane fade show active">
				<div class="card b-radius p-4">
					<div *ngIf="id" class="row justify-content-between align-items-center px-5">
						<div>
							<h1 *ngIf="userData.firstName || userData.lastName">{{userData.firstName +'
								'+userData.lastName}}</h1>
						</div>
						<div class="d-flex justify-content-between align-items-center">
							<label>STOP</label>
							<label class="switch">
								<input type="checkbox" [(ngModel)]="start"
									[disabled]="commonService.userRole.Backoffice">
								<span class="slider round" [ngClass]="{'off':!start}"></span>
							</label>
							<label>START</label>
						</div>
					</div>
					<form [formGroup]="stepForm" (ngSubmit)="saveDetails()" class="row form m-1 mt-3">
						<div class="col-xl-12">

							<!-- Add Mode -->
							<div class="row form m-1 mt-3" *ngIf="!id">
								<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
									<div class="form-group">
										<label for="" translate="it_first_name"></label>
										<input formControlName="firstName" class="form-control">
									</div>
								</div>
								<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="it_last_name"></label>
										<input formControlName="lastName" class="form-control"
											placeholder="enter lastname here...">
									</div>
								</div>

								<!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="it_practice"></label>
										<select formControlName="practiceId">
											<option *ngFor="let p of commonService.commonData.practices"
												[default]="p.isDefault" [value]="p.id">
												{{p.name}}</option>
										</select>
									</div>
								</div> -->

								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="it_gender"></label>
										<select formControlName="gender">
											<option value="Male">Male</option>
											<option value="Female">Female</option>
										</select>
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-5 col-sm-12">
									<div class="form-group">
										<label for="" translate="it_emp_per"></label>
										<input class="form-control" formControlName="employeePer"
											placeholder="{{commonService.dateFormat}}" readonly ngbDatepicker
											#emp="ngbDatepicker" (click)="emp.toggle()">
									</div>
								</div>

								<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="it_level"></label>
										<select formControlName="levelId">
											<option *ngFor="let level of commonService.commonData.levels"
												[value]="level.id" [default]="level.isDefault">
												{{level.levelName}}</option>
										</select>
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="it_select_email_ext"></label>
										<select formControlName="extensionId">
											<option *ngFor="let level of commonService.commonData.mailExtensions"
												[value]="level.id" [default]="level.isDefault">
												{{level.name}}</option>
										</select>
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="it_location"></label>
										<select formControlName="location">
											<option value="">Select</option>
											<option *ngFor="let c of commonService.commonData.location" [value]="c.id"
												[default]="c.isDefault">
												{{c.name}}</option>
										</select>
									</div>
								</div>
								<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="it_it_start_Date"></label>
										<input formControlName="startTime" class="form-control"
											placeholder="{{commonService.dateFormat}}" ngbDatepicker
											#Startdate="ngbDatepicker" (click)="Startdate.toggle()">
									</div>
								</div>
								<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="it_contactNo"></label>
										<input formControlName="contactNo" class="form-control"
											placeholder="+00 000 0000">
									</div>
								</div>
								<ng-container *ngIf="viewInitialized">
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_select_groups"></label>
											<multiselect-dropdown [data]="commonService.allGroups"
												[selected]="groupIds">
											</multiselect-dropdown>
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_select_office365"></label>
											<multiselect-dropdown [selected]="office365Ids" [data]="office365">
											</multiselect-dropdown>
										</div>
									</div>
								</ng-container>
							</div>

							<!-- Edit Mode -->
							<div class="row form m-1 mt-3" *ngIf="id">
								<!-- <ng-container *ngIf="start"> -->
								<ng-container>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_emp_id"></label>
											<input class="form-control" formControlName="employeeId" placeholder="">
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_Initials"></label>
											<input class="form-control" formControlName="initials" #initials
												placeholder="" >
											<!-- <input class="form-control" formControlName="initials" #initials
												placeholder="" *ngIf="!start || (itStatus !== 'New')" disabled> -->
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_password"></label>
											<input class="form-control" formControlName="password" placeholder="" (keyup)="validatePassword($event.target.value)">
											<p *ngIf="!isValidPassword" class="form-error">To Update Azure - Min 10 characters, at least one uppercase letter, 
												one lowercase letter, one number and one special character required</p>
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_first_name"></label>
											<input formControlName="firstName" class="form-control" placeholder="">
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_last_name"></label>
											<input formControlName="lastName" class="form-control" placeholder="">
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_mobile_no"></label>
											<input class="form-control" formControlName="mobileNo" placeholder="">
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
										<div class="form-group">
											<label for="" translate="it_Equipment_ready"></label>
											<select formControlName="equipmentReady">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.EquipmentReady"
													[default]="val.isDefault">
													{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_it_start_date"></label>
											<input formControlName="itStartDate" class="form-control"
												placeholder="{{commonService.dateFormat}}" ngbDatepicker
												#itStartDate="ngbDatepicker" (click)="itStartDate.toggle()">
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_start"></label>
											<input formControlName="startTime" class="form-control" placeholder="{{commonService.dateFormat}}"
												ngbDatepicker #StartTime="ngbDatepicker" (click)="StartTime.toggle()">
										</div>
									</div>
									<div class="w-100 row m-0">
										<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 p-0">
											<div class="col-12">
												<div class="form-group">
													<label for="" translate="it_Comment"></label>
													<textarea class="form-control" formControlName="comment"
														placeholder="" style="height: 300px;"></textarea>
												</div>
											</div>
										</div>
										<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 p-0">
											<div class="col-12">
												<div class="form-group">
													<label for="" translate="it_department"></label>
													<select formControlName="departmentId" (change)="onChangeDepartment()">
														<option *ngFor="let des of commonService.commonData.departments"
															[value]="des.id" [default]="des.isDefault">{{des.name}} ({{des.managerEmail}})
														</option>
													</select>
												</div>
											</div>

											<div class="col-12">
												<div class="form-group">
													<label for="" translate="it_practice"></label>
													<select formControlName="practiceId">
														<option value="">Select</option>
														<option *ngFor="let p of pageData.practice" [value]="p.id"
														[default]="p.isDefault">
															{{p.name}}</option>
													</select>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group">
													<label for="" translate="it_location"></label>
													<select formControlName="location">
														<option value="">Select</option>
														<option *ngFor="let c of commonService.commonData.location"
															[value]="c.id" [default]="c.isDefault">
															{{c.name}}</option>
													</select>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group">
													<label for="" translate="it_select_email_ext"></label>
													<select formControlName="extensionId">
														<option value="">Select</option>
														<option
															*ngFor="let level of commonService.commonData.mailExtensions"
															[value]="level.id" [default]="level.isDefault">
															{{level.name}}</option>
													</select>
												</div>
											</div>
											<div class="col-12" *ngIf="!start">
												<div class="form-group">
													<label for="" translate="hr_it_termination_date"></label>
													<input formControlName="itTerminationDate" class="form-control"
														placeholder="{{commonService.dateFormat}}" readonly ngbDatepicker
														#itTerminationDate="ngbDatepicker" (click)="itTerminationDate.toggle()">
												</div>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="!start">
									
								<!--	<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_Stop_procedure_finished"></label>
											<select formControlName="stopProcedure">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.StopProcedure"
													[default]="val.isDefault">
													{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_Account_disabled"></label>
											<select formControlName="accountDisabled">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.AccountDisabled"
													[default]="val.isDefault">
													{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_Post_stop_finished"></label>
											<select formControlName="postStop">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.PostStop"
													[default]="val.isDefault">
													{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_PC"></label>
											<select formControlName="pc">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.PC"
													[default]="val.isDefault">{{val.value}}
												</option>
											</select>
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_Transfer_phone"></label>
											<select formControlName="transferPhone">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.TransferPhone"
													[default]="val.isDefault">
													{{val.value}}</option>
											</select>
										</div>
									</div> -->
									
								</ng-container>
								<ng-container *ngIf="viewInitialized">
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_select_groups"></label>
											<multiselect-dropdown [data]="commonService.allGroups"
												[selected]="groupIds">
											</multiselect-dropdown>
										</div>
									</div>
									<div class="col-xl-6 col-lg-12 col-md-5 col-sm-12">
										<div class="form-group">
											<label for="" translate="it_select_office365"></label>
											<multiselect-dropdown [selected]="office365Ids" [data]="office365">
											</multiselect-dropdown>
										</div>
									</div>
								</ng-container>
								<div class="col-xl-12 f-flex justify-content-end align-end">
									<button type="button" class="btn btn-primary btn-lg next"
										[disabled]="isRemoveDisabled()" (click)="removeAssets()">
										{{'it_remove' | translate}}
									</button>
								</div>
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
									<div class="table-responsive">
										<table>
											<thead>
												<th translate>it_name</th>
												<th translate>it_serialNo</th>
												<th translate>it_product</th>
												<th translate>it_type</th>
												<th translate>it_subType</th>
												<th translate>it_vendor</th>
												<th translate></th>
											</thead>
											<tbody>
												<tr *ngFor="let asset of selectedAssets">
													<ng-container *ngIf="!asset.isDeleted">
														<td>{{asset.name}}</td>
														<td>{{asset.serialNo}}</td>
														<td>{{asset.modelName}}</td>
														<td>{{asset.productViewModel.type}}</td>
														<td>{{asset.productViewModel.subType}}</td>
														<td>{{asset.productViewModel.vendor}}</td>
														<td>
															<input class="checkbox" type="checkbox"
																[(ngModel)]="asset.selected"
																[ngModelOptions]="{standalone: true}" />
														</td>
													</ng-container>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 mt-4">
									<div class="form-group">
										<input formControlName="searchAsset" (keyup)="searchAssets()"
											class="form-control" placeholder="search assets here...">
									</div>
								</div>
								<div class="col-xl-12 f-flex justify-content-end align-end">
									<button type="button" class="btn btn-primary btn-lg next"
										[disabled]="isAssignDisabled()" (click)="assignAssets()">
										{{'it_assign' | translate}}
									</button>
								</div>
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2 mb-5">
									<div class="table-responsive">
									<table>
										<thead>
											<th translate>it_name</th>
											<th translate>it_serialNo</th>
											<th translate>it_product</th>
											<th translate>it_vendor</th>
											<th translate>it_status</th>
											<th translate>it_initial_date</th>
											<th translate></th>
										</thead>
										<tbody>
											<tr *ngFor="let asset of searchedAssets">
												<td>{{asset.name}}</td>
												<td>{{asset.serialNo}}</td>
												<td>{{asset.modelName}}</td>
												<td>{{asset.reseller}}</td>
												<td>{{asset.status}}</td>
												<td>{{asset.addedDateTime | date : commonService.dateFormat}}</td>
												<td>
													<input class="checkbox" type="checkbox"
														[(ngModel)]="asset.selectedAssign"
														[ngModelOptions]="{standalone: true}" />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								</div>
							</div>

							<div class="row m-1 mb-4">
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
									<button type="submit" class="btn btn-primary btn-lg next">{{(id ? 'it_update' :
										'it_save') | translate}}</button>
									<button type="button" class="btn btn-primary btn-lg next" *ngIf="id && start && (itStatus === 'New')"
										(click)="updateToAzure()">{{'it_Update_to_azure' | translate}}</button>
									<button type="button" class="btn btn-primary btn-lg"
										(click)="_location.back()"><u>{{'it_cancel' | translate}}</u></button>
										<!-- <button type="button" style="float: right;" class="btn btn-primary btn-lg next" *ngIf="userData.isActive" (click)="changeStatus()">Disable</button>
										<button type="button" style="float: right;"class="btn btn-primary btn-lg next" *ngIf="!userData.isActive" (click)="changeStatus()">Enable</button> -->
								</div>
							</div>
							
						</div>
					</form>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>
	</div>
</div>