import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-tile',
  templateUrl: './hr-tile.component.html',
  styleUrls: ['./hr-tile.component.scss']
})
export class HrTileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
