import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { CheckListComponent } from '../shared/components/check-list/check-list.component';
import { HrService } from './hr.service';

@Component({
  selector: 'app-hr-list',
  templateUrl: './hr-list.component.html',
  styleUrls: ['./hr-list.component.scss']
})
export class HrListComponent implements OnInit {
  employees: any = [];
  filters: any = {};
  sbs: any = null;
  countSbs: any = null;
  pageNo: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  constructor(
    private hrService: HrService,
    private modalService: NgbModal,
    private _toastr: ToastrService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.filters.start = true;
    this.resetDetails();
    this.getTotalCount();
  }
  getEmployes(loader: boolean = true) {
    var req = `?type=HR&pageNo=${this.pageNo}&pageSize=${this.pageSize}&startstopStatus=${!!this.filters.start ? 'Start' : 'Stop'}`;
    if (this.filters.searchText) {
      req += '&searchText=' + this.filters.searchText;
    }
    if (this.filters.department) {
      req += '&departmentId=' + this.filters.department;
    }
    if (this.filters.status) {
      req += '&status=' + this.filters.status;
    }
    if (this.sbs) this.sbs.unsubscribe();
    this.sbs = this.hrService.getAll(req, loader).subscribe((res: any) => {
      this.employees = res.map((emp) => {
        if (emp.profileImage && !emp.profileImage.toString().includes('http')) {
          emp.profileImage = environment.apiUrl + emp.profileImage;
        }
        return emp;
      });
    });
  }
  getTotalCount() {
    var req = `?type=HR&startstopStatus=${!!this.filters.start ? 'Start' : 'Stop'}`;
    if (this.filters.searchText) {
      req += '&searchText=' + this.filters.searchText;
    }
    if (this.filters.department) {
      req += '&departmentId=' + this.filters.department;
    }
    if (this.filters.status) {
      req += '&status=' + this.filters.status;
    }
    if (this.countSbs) this.countSbs.unsubscribe();
    this.countSbs = this.hrService.GetAllCount(req).subscribe((res: any) => {
      this.totalCount = res;
    });
  }
  deleteEmp(emp) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.modalData = {
      heading: 'Confirm to delete',
      description: 'Do you want to delete employee?'
    };
    modalRef.result.then(() => {
      this.hrService.deleteEmp(emp.id).subscribe((res: any) => {
        this.resetDetails();
        this._toastr.success('employee deleted successfully');
      })
    }).catch(e => {
    });
  }
  showChecklist(emp) {
    const modalRef = this.modalService.open(CheckListComponent, { size: 'xl' });
    modalRef.componentInstance.modalData = emp;
    modalRef.componentInstance.isHR = true;
    modalRef.result.then(() => {
      this.resetDetails();
    }).catch(e => {
    });
  }
  onScroll(event) {
    if (this.employees && this.employees.length) {
      this.pageNo = event;
      this.getEmployes();
    }
  }
  onSearchTxt() {
    this.employees = [];
    this.pageNo = 1;
    this.getEmployes(false);
    this.getTotalCount();
  }
  resetDetails() {
    this.employees = [];
    this.pageNo = 1;
    this.getEmployes();
    this.getTotalCount();
  }
}
