<div class="row m-0" *ngFor="let checklist of emp.checklists" style="background: #f5f5f5;padding: 10px;">
    <div class="col-md-1">
        <div class="form-group">
            <label for="" translate="dashboard_deadline"></label>
            <!-- <span *ngIf="!checklist.editable">{{checklist.deadLine}}</span> -->
            <input class="form-control" [(ngModel)]="checklist.deadLine"
                placeholder="{{commonService.dateFormat}}" ngbDatepicker #deadline="ngbDatepicker"
                (click)="deadline.toggle()" [disabled]="commonService.isChecklistDisable(checklist)">
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group">
            <label for="" translate="dashboard_taskname"></label>
            <span *ngIf="!checklist.editable">{{checklist.taskListName}}</span>
            <input class="form-control" [(ngModel)]="checklist.taskListName" type="text"
                *ngIf="checklist.editable">
        </div>
    </div>
    <div class="col-md-1" *ngIf="!checklist.editable">
        <div class="form-group">
            <label for="" translate="dashboard_completed_by"></label>
            <span>{{checklist.completedBy}}</span>
            <!-- <input class="form-control" [(ngModel)]="checklist.taskListName" type="text"
                    [disabled]="!checklist.editable"> -->
        </div>
    </div>
    <div class="col-md-1" >
        <div class="form-check pl-0 pt-1 checkbox-block">
            <label class="form-check-label" for="defaultCheck1" translate="dashboard_completed"></label><br/>
            <span class="form-check-label" *ngIf="checklist.completed">{{ (checklist.updateDate !== null) ? (checklist.updateDate | date: commonService.dateFormat) : "" }}</span>
        </div>
    </div>
    <div class="col-md-2"  *ngIf="checklist.editable">
        <div class="form-group">
            <label for="" translate="dashboard_description"></label>
            <input class="form-control" [(ngModel)]="checklist.description" type="text"
                [disabled]="commonService.isChecklistDisable(checklist)">
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group">
            <label for="" translate="dashboard_status"></label>
            <!-- <span *ngIf="!checklist.editable">{{getStatusName(checklist.statusId)}}</span> -->
            <select [(ngModel)]="checklist.statusId" (change)="CheckStatus(checklist)">
                <option *ngFor="let des of commonService.commonData.taskListStatus" [value]="des.id"
                    [default]="des.isDefault" [disabled]="commonService.isChecklistDisable(checklist)">
                    {{des.name}}
                </option>
            </select>
        </div>
    </div>
    <div class="col-md-1">
        <div class="form-group">
            <label for="" translate="dashboard_responsible"></label>
            <select [(ngModel)]="checklist.responsible">
                <option value="">Select</option>
                <option [value]="res.key" *ngFor="let res of commonService._responsibleEnum | keyvalue">{{res.value}}</option>
            </select>
        </div>
    </div>
    <div class="col-md-1">
        <div class="form-check pl-0 pt-1 checkbox-block">
            <input class="form-check-input" type="checkbox" [(ngModel)]="checklist.completed"
                [disabled]="commonService.isChecklistDisable(checklist)"
                (change)="setCompleted($event,checklist)" id="defaultCheck1">
            <label class="form-check-label" for="defaultCheck1" translate="task_completed?"></label>
        </div>
    </div>
    <div class="col-md-1 text-right">
        <i class="fa fa-exclamation-circle m-2" (click)="addComment(checklist)"></i>
        <a href="javascript:void(0);" class="btn btn-outline-primary ml-2 btn-sm plus-icon"
            *ngIf="checklist.editable" (click)="deleteCheckList(checklist)">
            <i class="fa fa-times"></i>
        </a>
    </div>
</div>


<div class="row m-0">
    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 mt-2 mb-2 text-left">
        <button type="button" class="btn-sm btn-primary next mr-3" (click)="saveTask(emp)" translate="task_save"></button>
        <button type="button" class="btn-sm btn-primary next" (click)="addNewTask(emp)"
            translate="add_new_task"></button>
        <button type="button" class="btn btn-primary btn-lg" (click)="emp.isExpended = false;"><u>{{'task_cancel' |
                translate}}</u></button>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 mt-2">

    </div>
</div>