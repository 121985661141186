import { Component, OnInit, ViewChild  } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdToDatePipe } from '../shared/pipes/shared.pipes';

import { Chart, BarController, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { ReportDetailsComponent } from './reportdetails.component';
import { SharedService } from '../shared/services/shared.service';
import { $ } from 'protractor';
import { utf8Encode } from '@angular/compiler/src/util';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
 

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
   
  start_date_lbl:string='start_date';
  end_date_lbl:string='end_date';
  filters: any = {};
  reportdata=[];
  title = 'Kereby';
  statistics:any = {};
  view: string = 'total';
  monthlyData : Array<any> = [];

  constructor(
    private modalService:NgbModal,
    private service:SharedService
  ) { 
    
  }

  ngOnInit() {

    this.getStatistics();
    this.getSummaryReport();  

    Chart.register(BarController,CategoryScale,LinearScale,BarElement);
    //const ctx = document.getElementById('myChart').getContext('2d');            

  }


  onChangeDate(val, model) {
    this.filters[model] = val;
    this.getStatistics();
    this.getSummaryReport();   
  }

  getDateString(value){
    return moment([value.year, value.month - 1, value.day]).local().format("yyyy-MM-DDThh:mm:ss")
  }

  setDateFilters(filterModel){
    if(this.filters && this.filters.startDate){
      filterModel.fromDate = this.getDateString(this.filters.startDate);
    }
    if(this.filters && this.filters.endDate){
      filterModel.uptoDate = this.getDateString(this.filters.endDate);
    }    
  }
 
  showDetails(item,title){

    const modalRef = this.modalService.open(ReportDetailsComponent, { size: 'xl' });
    let filterModel:any = { type: item.type ? item.type : null, month: item.month ? item.month : null};
    this.setDateFilters(filterModel);
    console.log('filters',filterModel);
    modalRef.componentInstance.modalData = filterModel;   
    modalRef.componentInstance.title = title;    
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.result.then(() => {
      this.resetDetails();
    }).catch(e => {
    });
  }

  resetDetails() {
    
  }

  getSummaryReport(){
    this.monthlyData = [];
    let filterModel:any = { };
    this.setDateFilters(filterModel);
    this.service.getReportSummary(filterModel).subscribe(res=>{
      console.log('summary data',res);
      this.monthlyData = res as [];
      this.setMonthlyChart();
    })
  }

  getStatistics(){
    let filterModel:any = { };
    this.setDateFilters(filterModel);
    this.service.getStatistics(filterModel).subscribe(res=>{
      this.statistics = res;
      if(this.statistics){
        this.reportdata = [
          {
             category : "On Board",
             items : [
              {title:"Awaiting Onboarding",value: this.statistics.onboardingPending, type:'Awaiting Onboarding' },
              {title:"Onboarded",value:this.statistics.onboarded, type:'Onboarded'}
            ]
          },
          {
              category : "Off Board",
              items : [
                {title:"Pending Termination",value:this.statistics.terminationPending, type:'Pending Termination'},
                {title:"Terminated",value:this.statistics.terminated, type:'Terminated'}
              ]
           }
      ];
      }
      console.log('Statistics',res);
    })
  }

  setView(view){
    this.view = view;
    if(view === 'total'){
     document.getElementById('totalbtn').classList.add('active');
     document.getElementById('bymonthbtn').classList.remove('active');
    }else if(view === 'month'){      
      document.getElementById('totalbtn').classList.remove('active');
      document.getElementById('bymonthbtn').classList.add('active');
      this.setMonthlyChart();
    }
  }

  datasets_obj=null;
  months = [];
  myChart : Chart = null;
  setMonthlyChart(){

    if(this.myChart)
      this.myChart.destroy();

    const graphClickEvent = (event, array)=>{     
      console.log('graph data',array);       
      if(array && array.length>0 && array[0].datasetIndex>=0){      
        let type = this.datasets_obj[array[0].datasetIndex].label;
        let month= this.months[array[0].index];
        if(type=='Onboarded')type='Onboard';
        else if(type=='Offboarded')type='Terminated';
        let title = `${type} in month: ${month}`;
        this.showDetails({type,month},title);
      }      
    }

    this.months = [];    
    let onBoardData = {label:'Onboarded',data:[], backgroundColor:[]};
    let offBoardData = {label:'Offboarded',data:[], backgroundColor:[]};
    if(this.monthlyData){
      this.monthlyData.forEach(monthData=>{
        this.months.push(monthData.month);
        onBoardData.data.push(monthData.onboarding);
        onBoardData.backgroundColor.push('#81ae91')
        offBoardData.data.push(monthData.termination);
        offBoardData.backgroundColor.push('#BBD7C7')
      });
    }
    this.datasets_obj =  [
      onBoardData,
      offBoardData,
    ];
     this.myChart = new Chart('myChart',  {
      type: 'bar',
      data: {
          labels: this.months,
          datasets: this.datasets_obj
      },
      options: {
        onClick: graphClickEvent
      }
    });  
    console.log('my chart data',this.months, onBoardData, offBoardData);
  }

}
