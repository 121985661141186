import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpParams } from 'src/app/common.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }
  getPending(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Employee/GetPending${req}`, httpOptions);
  }
  getTotalCount(req) {
    return this.http.get(`api/Employee/GetPendingCount${req}`);
  }
  getCheckList(req) {
    return this.http.get(`api/CheckListTemplate/GetTaskListUser${req}`);
  }
  saveCheckList(data) {
    return this.http.post(`api/CheckListTemplate/AddUpdateTaskListUser`,data);
  }
  getPendingTask(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Employee/GetPendingCheckListUserForStart${req}`, httpOptions);
  }
  getTerminatedTask(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Employee/GetPendingCheckListUserForStop${req}`, httpOptions);
  }
  AddUpdateTaskListUser(req) {
    return this.http.post(`api/CheckListTemplate/AddUpdateTaskListUser`,req);
  }
}
