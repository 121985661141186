import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpParams } from 'src/app/common.service';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(
    private http: HttpClient
  ) { }
  getAll(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/EmailTemplates/GetAll`, httpOptions);
  }
  getById(id) {
    return this.http.get(`api/EmailTemplates/GetById/${id}`);
  }
  AddUpdate(req) {
    return this.http.post(`api/EmailTemplates/AddUpdateEmailTemplates`, req);
  }
  Delete(id) {
    return this.http.delete(`api/EmailTemplates/DeleteById/${id}`);
  }
}
