import { Component, OnInit,Input  } from '@angular/core';
import { SharedService } from '../shared/services/shared.service';

@Component({
  selector: 'app-report-details',
  templateUrl: './reportdetails.component.html'
})
export class ReportDetailsComponent implements OnInit {
  @Input() modalData: any = {};
  @Input() title: string = "";
  @Input() modalRef:any = null;
  pageNo:number=1;
  pageSize:number=10;
  @Input() totalCount:number=0; 
  summaryData:any;
  detailData:any;
    constructor(
      private service: SharedService,
      ) {
       
      }

    ngOnInit(): void {
      // this.service.getReportSummary().subscribe(
      //   (res: any) => {
      //     this.summaryData = res;
      //   }); 
      this.getDetailReport();
    }

    closeIt(){
      if(this.modalRef)
        this.modalRef.close();
    }

    onScroll(event) {
      if (this.detailData && this.detailData.length) {
        this.pageNo = event;
        this.getDetailReport();
      }
    }

    getDetailReport(){
      let filter : any = {type:this.modalData.type,month:this.modalData.month};
      if(filter && this.modalData.fromDate){
        filter.fromDate = this.modalData.fromDate;
      }
      if(filter && this.modalData.uptoDate){
        filter.uptoDate = this.modalData.uptoDate;
      }
      filter.pageNo = this.pageNo;
      filter.pageSize = this.pageSize;
      this.service.getReportDetails(filter).subscribe(
        (res: any) => {
          console.log('report details data',res);
          if(res && res.length>0)
            this.totalCount = res[0].totalRecords;
          else this.totalCount = 0;
          this.detailData = res;
        });
    }

    showStopDate()
    {
      return this.title && this.title.indexOf('Termi')>=0;
    }


}