import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetService } from '../../asset.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  @Input() assetDetails: any = {};
  productForm: FormGroup;
  data:any={};
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _service: AssetService,
    private _toastr: ToastrService,
    public commonService:CommonService
  ) {
  }

  ngOnInit(): void {
    this.productForm = this.formBuilder.group({
      name: [this.assetDetails.name || null, Validators.required],
      typeId: [this.assetDetails.typeId || null],
      subTypeId: [this.assetDetails.subTypeId || null],
      vendorId: [this.assetDetails.vendorId || null, Validators.required],
      lifeTimeMonth: [this.assetDetails.lifeTimeMonth || null],
      price: [this.assetDetails.price || null]
    });
    this.getVendors();
    this.getSubType();
  }
  saveProduct() {
    if(this.productForm.invalid) return;
    var _self = this;
    var req = {
      ..._self.productForm.getRawValue()
    };
    if (_self.assetDetails.id) {
      req.id = _self.assetDetails.id;
    }
    _self._service.addProduct(req).subscribe((res: any) => {
      _self._toastr.success(_self.assetDetails.id ? 'product updated successfully' : 'product added successfully');
      _self.activeModal.close(true);
    })
  }
  getVendors() {
    this._service.getVendors('',true).subscribe((res: any) => {
      this.data.vendors =res;
    });
  }
  getSubType(reset:boolean = false) {
    var typeId = this.productForm.get('typeId').value;
    if(typeId) {
      if(reset) {
        this.productForm.get('subTypeId').setValue(null);
      }
      this._service.getSubType(typeId).subscribe((res:any)=>{
        this.productForm.updateValueAndValidity();
        this.data.subtype = res;
      })
    }
  }
}
