import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {
  @Input() data: any = {};
  commentForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.commentForm = this.formBuilder.group({
      comments: [this.data.modalData[this.data.commentObj] || null, Validators.required]
    });
  }
  saveComment() {
    if (this.commentForm.invalid) return;
    var _self = this;
    var req = _self.data.modalData;
    req[_self.data.commentObj] = _self.commentForm.getRawValue().comments;
    _self.data.api(req).subscribe((res: any) => {
      _self._toastr.success('comment updated successfully');
      _self.activeModal.close(true);
    })
  }
}
