<form [formGroup]="assetsForm" (ngSubmit)="saveAsset()" class="row form m-1 mt-3">
    <div class="row form m-4">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <h1 class="headTitle">{{(assetDetails.id ? 'update_hardware' : 'add_hardware') | translate}}</h1>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_name"></label>
                    <input class="form-control" formControlName="name" tabindex="1" placeholder="">
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_model"></label>
                    <input class="form-control" formControlName="modelName" tabindex="3" placeholder="">
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_serialNo"></label>
                    <input class="form-control" formControlName="serialNo" tabindex="5" placeholder="">
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_product"></label>
                    <select tabindex="7" formControlName="productId">
                        <option *ngFor="let product of data.products" [value]="product.id"  [default]="product.isDefault">{{product.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_reseller"></label>
                    <select tabindex="9" formControlName="resellerId">
                        <option *ngFor="let res of data.resellers" [value]="res.id" [default]="res.isDefault">{{res.resellerName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_initial_date"></label>
                    <input class="form-control" tabindex="11" formControlName="initialDate" readonly placeholder="" ngbDatepicker
                        #initialDate="ngbDatepicker" (click)="initialDate.toggle()">
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_emp_name"></label>
                    <select tabindex="2" formControlName="employeeId" tabindex="2">
                        <option *ngFor="let emp of data.employees" [value]="emp.id" [default]="emp.isDefault">{{emp.firstName +' '+ emp.lastName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_status"></label>
                    <select tabindex="4" formControlName="status">
                        <option>-- Select --</option>
                        <option value="Not assigned" default="true">Not assigned</option>
                        <option value="Handed Out">Handed Out</option>
                        <option value="Repair">Repair</option>
                        <option value="Equipment Ready">Equipment Ready</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_comments"></label>
                    <textarea class="form-control" tabindex="6" formControlName="comments" placeholder=""
                        style="height: 230px;"></textarea>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="hardware_attachment"></label>
                    <input class="form-control" type="file" style="color: transparent;" tabindex="8" (change)="savePhoto($event)"
                        placeholder="" accept="image/*">
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="row">
                    <ng-container *ngIf="assetsForm.get('uploadImages') as FormArray">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 attachments" formArrayName="uploadImages"
                            *ngFor="let image of assetsForm.get('uploadImages').controls; let i = index;">
                            <img [src]="image.controls.image.value" />
                            <i class="fa fa-times" (click)="removeImg(i)"></i>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <button type="submit" class="btn btn-primary btn-lg next" translate="hardware_save"></button>
            <button type="button" class="btn btn-primary btn-lg prev"
                (click)="activeModal.dismiss()"><u>{{'hardware_cancel' | translate}}</u></button>
        </div>
    </div>
</form>