<h1 class="headTitle">Report</h1>
<div class="card p-4 b-radius-bl b-radius-br ">
	<div class="row form m-1 mt-1 mb-1" translate="heading"><h6></h6></div>

	<div class="row form m-1 mt-1 mb-1">
		 
		 <div class="form-group">
			<label for="" translate="select_period"></label>
			 
			<div class="row">
				<div class="col-md-6">
					<!-- <label for="" translate="start_date"></label> -->
					<input  placeholder="{{start_date_lbl | translate}}"
					(ngModelChange)="onChangeDate($event,'startDate')" [(ngModel)]="filters.startDate" ngbDatepicker
					#startDate="ngbDatepicker" (click)="startDate.toggle()">
				</div>
				<div class="col-md-6">
					<!-- s -->
					<input  placeholder="{{end_date_lbl | translate}}"
					(ngModelChange)="onChangeDate($event,'endDate')" [(ngModel)]="filters.endDate" ngbDatepicker
					#endDate="ngbDatepicker" (click)="endDate.toggle()">
				</div>
				
					
			</div> 

			 
		</div> 
	</div>

	<div class="row m-1 mt-1 mb-4">
		<div class="btn-group center" role="group" style="margin-left: auto; margin-right: auto;">
			<button type="button" id="totalbtn" (click)="setView('total')" class="btn btn-secondary" [ngClass]="{'active': view === 'total'}">Total</button>
			<button type="button" id="bymonthbtn" (click)="setView('month')" class="btn btn-secondary" [ngClass]="{'active': view === 'month'}">By Month</button> 			
		</div>
	</div>

	 <div [hidden]="view!='total'" id="totalView">
		<div class="row" *ngFor="let cate of reportdata">
			<h5>{{cate.category}}</h5>
			<br>
				<ul>
					<li *ngFor="let item of cate.items">
					<a href="javascript:void(0);"> <span (click)="showDetails(item,item.title)"> {{item.title}}  {{item.value}} </span></a>
					</li>
				</ul>
		</div>
	 </div>
	

	<div [hidden]="view!='month'" id="monthView">	 
		<div>
		  <div style="display: block">
			<canvas id="myChart" height="100" width="300px"></canvas>
		  </div> 
		</div>
	</div>
	 

</div>