import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedService } from './services/shared.service';
import { CustomLoader } from 'src/app/common.service';
import { ControlErrorsDirective, FormSubmitDirective, ControlErrorContainerDirective } from './directives/validate.directive';
import { SelectDefaultDirective, DefaultDirective } from './directives/select-default.directive';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateToNgbdPipe, MomentDateFormatter, NgbdToDatePipe } from './pipes/shared.pipes';
import { disableFormDirective } from './directives/disableForm.directive';
import { AddCommentComponent } from './components/add-comment/add-comment.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { MultiselectDropdownComponent } from './components/multiselect-dropdown/multiselect-dropdown.component';
@NgModule({
  declarations: [
    CheckListComponent,
    ControlErrorsDirective,
    AddCommentComponent,
    SelectDefaultDirective,
    DefaultDirective,
    FormSubmitDirective,
    ControlErrorContainerDirective,
    DateToNgbdPipe,
    NgbdToDatePipe,
    disableFormDirective,
    MultiselectDropdownComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        //useFactory: HttpLoaderFactory,
        useClass: CustomLoader,
        deps: [HttpClient]
      },
      isolate: false
    }),
  ],
  exports: [
    TranslateModule,
    CheckListComponent,
    ControlErrorsDirective,
    AddCommentComponent,
    SelectDefaultDirective,
    DefaultDirective,
    FormSubmitDirective,
    ControlErrorContainerDirective,
    DateToNgbdPipe,
    NgbdToDatePipe,
    disableFormDirective,
    MultiselectDropdownComponent
  ],
  providers: [
    SharedService,
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    DateToNgbdPipe,
    NgbdToDatePipe,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: []
    }
  }
}
