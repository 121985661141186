import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { HrService } from '../hr.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/common.service';
import { environment } from 'src/environments/environment';
import { NgbdToDatePipe, DateToNgbdPipe } from '../../shared/pipes/shared.pipes';
import { DashboardService } from '../../dashboard/dashboard.service';
@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit {
  stepForm: object = {};
  step: number = 1;
  id: any = null;
  start: boolean = true;
  profilePhoto: any = {};
  groupIds = [];
  office365Ids = [];
  userData: any = {};
  pageData: any = {};
  loadTaskComponent: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private _hrService: HrService,
    private _router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private ngbdToDatePipe: NgbdToDatePipe,
    private dashboardService: DashboardService,
    private dateToNgbPipe: DateToNgbdPipe,
    private dateToNgbdPipe: DateToNgbdPipe) {
    var id = this.route.snapshot.paramMap.get('id');
    this.setForm();
    debugger;
    if (id && id != 'new') {
      this.id = id;
      this._hrService.getEmpById(id).subscribe((res: any) => {
        this.setForm(res);
        this.userData = res;
        this.start = res.startStopStatus == 'Start';
        this.loadTaskComponent = true;
        // this.showTasks(res);

        this.onChangePractice();
        var countryId = res.countryId ||
          (this.commonService.commonData.countries.find((c) => { return c.isDefault }) ? this.commonService.commonData.countries.find((c) => { return c.isDefault }).id :
            this.commonService.commonData.countries[0].id);
        this.getCity(countryId);
        var departmentId = res.departmentId ||
          (this.commonService.commonData.departments.find((c) => { return c.isDefault }) ? this.commonService.commonData.departments.find((c) => { return c.isDefault }).id :
            this.commonService.commonData.departments[0].id);
        this.onChangeDepartment(departmentId);
        if (res.employeeSecurityGroupViewModels && res.employeeSecurityGroupViewModels.length) {
          this.groupIds = res.employeeSecurityGroupViewModels.map((group) => {
            return group.groupId;
          })
        }
        if (res.employeeOffice365LicenseViewModels && res.employeeOffice365LicenseViewModels.length) {
          this.office365Ids = res.employeeOffice365LicenseViewModels.map((office) => {
            return office.office365LicenseId;
          })
        }
        if (!res.profileImage) {
          this.profilePhoto.url = '';//'https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg';
        } else if (!res.profileImage.toString().includes('http')) {
          this.profilePhoto.url = environment.apiUrl + res.profileImage;
        } else {
          this.profilePhoto.url = res.profileImage;
        }
        this.profilePhoto.name = res.imageName || 'Test';
        this.stepForm[1].patchValue({
          itStartDate: dateToNgbdPipe.transform(res.itStartDate),
          itTerminationDate: dateToNgbdPipe.transform(res.itTerminationDate),
          lastPaymentSalaryDate: dateToNgbdPipe.transform(res.lastPaymentSalaryDate)
        });
        this.stepForm[2].patchValue({
          hireOn: dateToNgbdPipe.transform(res.hireOn),
          startTime: dateToNgbdPipe.transform(res.startTime),
          endTime: dateToNgbdPipe.transform(res.endTime)
        });
        this.stepForm[3].patchValue({
          introDate: dateToNgbdPipe.transform(res.introDate)
        });
      })
    }
    else{
      var departmentId = null;
      var departmentId = (this.commonService.commonData.departments.find((c) => { return c.isDefault }) ? this.commonService.commonData.departments.find((c) => { return c.isDefault }).id :
        this.commonService.commonData.departments[0].id);

      this.onChangeDepartment(departmentId);
    }
    this.getLocation();
  }

  setForm(data: any = {}) {
    this.stepForm[1] = this.formBuilder.group({
      userAccountId: [data.userAccountId || null],
      firstName: [data.firstName || null, Validators.required],
      lastName: [data.lastName || null, Validators.required],
      employeeId: [data.employeeId || null],
      contactNo: [data.contactNo || null],
      itStartDate: [],
      contract: [data.contract || null],
      employeeData: [data.employeeData],
      onBoarding: [data.onBoarding],
      welcomeLetter: [data.welcomeLetter],
      businessCards: [data.businessCards],
      informailHelloGoodBye: [data.informailHelloGoodBye],
      sendToCIA: [data.sendToCIA],
      itTerminationDate: [],
      lastPaymentSalaryDate: [],
      indexValue: [data.indexValue],
      monthSince: [data.monthSince],
      exitBuddyId: [data.exitBuddyId],
      alumniEmail: [data.alumniEmail],
      newWorkPlace: [data.newWorkPlace],
      transferPhone: [data.transferPhone]
    });
    this.stepForm[2] = this.formBuilder.group({
      jobPosition: [data.jobPosition || null],
      //departmentId: [data.departmentId || null, Validators.required],
      departmentId: [data.departmentId || null],
      practiceId: [data.practiceId || null],
      practiceLeadId: [data.practiceLeadId || null],
      //practiceLeadId: [data.practiceLeadId || null],
      location: [data.location || null],
      levelId: [data.levelId || null],
      serviceLineId: [data.serviceLineId || null],
      workPatternId: [data.workPatternId || null],
      type: [data.type || null],
      hireOn: [null],
      startTime: [null],
      endTime: [null]
    })
    this.stepForm[3] = this.formBuilder.group({
      address: [data.address || null],
      phone: [data.phone || null],
      ssn: [data.ssn || null],
      zipCode: [data.zipCode || null],
      // cityId: [data.cityId || null, Validators.required],
      gender: [data.gender || null],
      countryId: [data.countryId || null],
      maritalStatus: [data.maritalStatus || null],
      introDate: [],
      buddyId: [data.buddyId],
      staffedMinimum: [data.staffedMinimum],
      hidden: [data.hidden],
      champagne: [data.champagne],
      termination: [data.termination],
      terminationArchieved: [data.terminationArchieved],
      clause: [data.clause],
      exitInterview: [data.exitInterview],
      hrManagerAccess: [data.hrManagerAccess],
      linkedinAlumni: [data.linkedinAlumni],
      helloGoodBye: [data.helloGoodBye],
      goodByeEmail: [data.goodByeEmail],
      cityName: [data.cityName || null],
    });
    console.log(this.stepForm[3]);
    this.stepForm[4] = this.formBuilder.group({
      emergencyName: [data.emergencyName || null],
      emergencyPhoneNo: [data.emergencyPhoneNo || null],
      emergencyEmail: [data.emergencyEmail || null],
      additionalInformation: [data.additionalInformation || null],
      jobType: ['HR']
    });
  }

  ngOnInit(): void {
    this.getEmployes();
  }
  getEmployes() {
    this._hrService.GetAllForDropdown().subscribe((res: any) => {
      this.pageData.buddies = res;
    });
  }
  goToStep(number) {
    if (number == 1) {
      this.step = number;
      return;
    }
    if (this.stepForm[number - 1].invalid) {
      for (let i = 1; i <= Object.keys(this.stepForm).length; i++) {
        if (this.stepForm[i].invalid) {
          this.step = i;
          setTimeout(() => {
            for (let j in this.stepForm[i].controls) {
              this.stepForm[i].controls[j].updateValueAndValidity();
            };
          }, 200);
          break;
        }
      }
      return;
    }
    if (this.validatePreviousSteps(number - 1)) {
      this.step = number;
    }
  }
  validatePreviousSteps(step: number = null) {
    var _self = this;
    var goToNext = true;
    for (var i = 1; i <= step; i++) {
      if (_self.stepForm[i].invalid) {
        goToNext = false;
        //_self.goToStep(i);
      }
    }
    return goToNext;
  }
  onChangePractice() {
    debugger;
    var req = '?practiceId=' + this.stepForm[2].get('practiceId').value;
    this._hrService.getEmpByDeptId(req).subscribe((res: any) => {
      this.commonService.commonData.practiceLead = res;
      this.pageData.practiceLead = res;
      //this.onChangePracticeLead();
      //this._hrService.GetPracticeLeadByDepartmentId(req).subscribe((res: any) => {
        //this.pageData.practiceLead = res;
      //})
    })
  }
  onChangeDepartment(departmentId: any = null) {
    debugger;
    departmentId = departmentId || this.stepForm[2].get('departmentId').value;
    var req = '?departmentId=' + departmentId;
    //  this._hrService.GetPracticeLeadByDepartmentId(req).subscribe((res: any) => {
    //    this.pageData.practiceLead = res;
    //  })
    //  this._hrService.GetPracticeByDepartmentId(req).subscribe((res: any) => {
    //    this.pageData.practice = res;
    //  })
    //this.onChangePracticeLead();
    this._hrService.GetPracticeWithManager(req).subscribe((res: any) => {
      //debugger;
      this.pageData.practice = res;
    })
    
    this.pageData.practiceLead = this.commonService.commonData.departments.find((dept) => { return dept.id == departmentId }).manager;
    this.pageData.practiceLeadEmail = this.commonService.commonData.departments.find((dept) => { return dept.id == departmentId }).managerEmail;
  }
  onChangePracticeLead() {
    debugger;
    var req = '?practiceLeadId=' + this.stepForm[2].get('practiceLeadId').value;
    this._hrService.getFCLead(req).subscribe((res: any) => {
      this.commonService.commonData.fCLead = res;
    })
  }
  getCity(countryId: string = null) {
    var req = '?countryId=' + (countryId || this.stepForm[3].get('countryId').value);
    this._hrService.getCities(req).subscribe((res: any) => {
      this.commonService.commonData.cities = res;
    })
  }
  getLocation() {
    var req = '?countryId=5bf31835-d71b-4507-b697-3d439fce49ff';
    this._hrService.getCities(req).subscribe((res: any) => {
      this.commonService.commonData.location = res;
    })
  }
  saveNext(number) {
    debugger;
    console.log(this.stepForm[number - 1]);
    if (this.stepForm[number - 1].invalid) return;
    if (this.validatePreviousSteps(number - 1)) {
      this.saveDetails(false, number);
    }
  }
  saveDetails(isPublish: boolean = false, nextStep: number = null) {
    if (!nextStep && this.stepForm[4].invalid) return;
    var _self = this;
    var req: any = {
      employeeViewModel: {
        ...this.userData,
        ..._self.stepForm[1].getRawValue(),
        ..._self.stepForm[2].getRawValue(),
        ..._self.stepForm[3].getRawValue(),
        ..._self.stepForm[4].getRawValue()
      },
      "isHR": true,
      "isPublish": isPublish
    };
    if (_self.id) {
      req.employeeViewModel.id = _self.id;
    }
    req.employeeViewModel.startStopStatus = this.start ? 'Start' : 'Stop';
    req.employeeViewModel.hireOn = _self.ngbdToDatePipe.transform(req.employeeViewModel.hireOn);
    req.employeeViewModel.startTime = _self.ngbdToDatePipe.transform(req.employeeViewModel.startTime);
    req.employeeViewModel.endTime = _self.ngbdToDatePipe.transform(req.employeeViewModel.endTime);
    req.employeeViewModel.itStartDate = _self.ngbdToDatePipe.transform(req.employeeViewModel.itStartDate),
      req.employeeViewModel.itTerminationDate = _self.ngbdToDatePipe.transform(req.employeeViewModel.itTerminationDate),
      req.employeeViewModel.lastPaymentSalaryDate = _self.ngbdToDatePipe.transform(req.employeeViewModel.lastPaymentSalaryDate),
      req.employeeViewModel.introDate = _self.ngbdToDatePipe.transform(req.employeeViewModel.introDate)
    req.employeeViewModel.profileImage = _self.profilePhoto.url;
    req.employeeViewModel.imageName = _self.profilePhoto.name;
    
    req.groupDetails = this.commonService.allGroups.filter((group) => {
      return !!this.groupIds.includes(group.id);
    }).map((group) => {
      return { id: group.id, name: group.displayName };
    })
    req.office365Ids = this.office365Ids;
    if (_self.id) {
      req.employeeViewModel.id = _self.id;
    }
    _self._hrService.addEmployee(req).subscribe((res: any) => {
      if (!nextStep) {
        _self.toastr.success(_self.id ? 'Employee updated successfully' : 'Employee added successfully');
        _self._router.navigate(['/hr-list']);
      } else {
        _self.step = nextStep;
        this.id = res.id;
      }
    })
  }
  savePhoto(event) {
    var _self = this;
    _self.profilePhoto = {};
    var file = event.target.files[0];
    _self.profilePhoto.name = file.name;
    _self.commonService.imgToBase64(file).then(url => {
      _self.profilePhoto.url = url;
    })
  }
  getImgUrl() {
    return this.profilePhoto.url;
  }
  showTasks(emp): void {
    if (emp.isExpended) {
      emp.isExpended = false;
      return;
    }
    let _self = this;
    var req = '?employeeId=' + emp.id +
      '&type=' + emp.startStopStatus;
      if(emp.practiceId) {
        req = req + '&practiceId=' + emp.practiceId;
      }
      if(emp.departmentId) {
        req = req + '&departmentId=' + emp.departmentId;
      }
    this.dashboardService.getCheckList(req).subscribe(
      (res: any) => {
        emp.isExpended = true;
        emp.checklists = res.map((r) => {
          r.employeeId = emp.id;
          r.completed = (r.statusId == this.commonService.commonData.taskListStatus.find((c) => { return c.name.toString().toLowerCase().includes('completed') }).id);
          r.editable = false;
          if(!r.deadLine) {
            if (emp.startStopStatus == 'Start') {
              r.deadLine = _self.dateToNgbPipe.transform(emp.itStartDate);
            } else {
              r.deadLine = _self.dateToNgbPipe.transform(emp.itEndDate);
            }
          } else {
            r.deadLine = _self.dateToNgbPipe.transform(r.deadLine);
          }
          return r;
        })
      },
      (error:any)=>{
        emp.isExpended = true;
        emp.checklists = [];
      }
    )
  }
}
