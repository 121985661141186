import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { ItService } from '../it.service';
import { CommonService } from 'src/app/common.service';
import { Location } from '@angular/common';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgbdToDatePipe, DateToNgbdPipe } from '../../shared/pipes/shared.pipes';
@Component({
  selector: 'app-add-it',
  templateUrl: './add-it.component.html',
  styleUrls: ['./add-it.component.scss']
})
export class AddItComponent implements OnInit, AfterViewInit {
  @ViewChild('initials') initials: ElementRef<HTMLFormElement>;
  stepForm: FormGroup;
  step: number = 1;
  id: any = null;
  sbs: any = null;
  start: boolean = true;
  initialVerified: false;
  userData: any = {};
  selectedAssets: any = [];
  searchedAssets: any = [];
  selectedGroups = [];
  selectedOffice365 = [];
  office365: any = [];
  viewInitialized: boolean;
  groupIds = [];
  office365Ids = [];
  pageData: any = {};
  itStatus: string;
  isinitial: boolean;
  isValidPassword = true;
  private _passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
  
  constructor(
    private formBuilder: FormBuilder,
    private _itService: ItService,
    private _router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private ngbdToDatePipe: NgbdToDatePipe,
    private dateToNgbdPipe: DateToNgbdPipe,
    public _location: Location) {
    var _self = this;
    var id = _self.route.snapshot.paramMap.get('id');
    console.log(commonService.allGroups);

    _self.setForm();
    if (id && id != 'new') {
      _self.id = id;
      _self._itService.getEmpById(id).subscribe((res: any) => {
        _self.start = res.startStopStatus == 'Start';
        _self.itStatus = res.itStatus;
        if(!(this.start && (this.itStatus === 'New'))) {
          this.initials.nativeElement.disabled = true;
        }
        _self.setForm(res);
        _self.userData.startTime = _self.userData.startTime || _self.userData.itStartDate;
        _self.userData = res;
        _self.selectedAssets = res.assetsViewModels;
        _self.stepForm.patchValue({
          startTime: dateToNgbdPipe.transform(res.startTime),
          employeePer: dateToNgbdPipe.transform(res.employeePer),
          itStartDate: dateToNgbdPipe.transform(res.itStartDate),
          itTerminationDate: dateToNgbdPipe.transform(res.itTerminationDate),

        });
        var departmentId = res.departmentId ||
          (this.commonService.commonData.departments.find((c) => { return c.isDefault }) ? this.commonService.commonData.departments.find((c) => { return c.isDefault }).id :
            this.commonService.commonData.departments[0].id);
        this.onChangeDepartment(departmentId);

        if (res.employeeSecurityGroupViewModels && res.employeeSecurityGroupViewModels.length) {
          _self.groupIds = res.employeeSecurityGroupViewModels.map((group) => {
            return group.groupId;
          })
        }
        if (res.employeeOffice365LicenseViewModels && res.employeeOffice365LicenseViewModels.length) {
          _self.office365Ids = res.employeeOffice365LicenseViewModels.map((office) => {
            return office.office365LicenseId;
          })
        }
      })
    }
    _self.getLocation();
  }
  setForm(data: any = {}) {
    this.stepForm = this.formBuilder.group({
      firstName: [{ value: data.firstName || null, disabled: !this.commonService.userRole.Admin }, Validators.required],
      lastName: [{ value: data.lastName || null, disabled: !this.commonService.userRole.Admin }, Validators.required],
      departmentId: [{ value: data.departmentId || null, disabled: !this.commonService.userRole.Admin }],
      practiceId: [{ value: data.practiceId || null, disabled: !this.commonService.userRole.Admin }],
      contactNo: [data.contactNo || null],
      gender: [data.gender || null],
      levelId: [data.levelId || null],
      location: [{ value: data.location || null, disabled: !this.commonService.userRole.Admin }],
      startTime: [],
      employeePer: [data.employeePer || null],
      extensionId: [data.extensionId || null],
      employeeId: [{ value: data.employeeId, disabled: !this.commonService.userRole.Admin }],
      searchAsset: [],
      stopProcedure: [data.stopProcedure],
      accountDisabled: [data.accountDisabled],
      postStop: [data.postStop],
      pc: [data.pc],
      transferPhone: [data.transferPhone],
      initials: [data.initials],
      password: [data.password],
      mobileNo: [data.mobileNo],
      equipmentReady: [data.equipmentReady],
      hidden: [data.hidden],
      comment: [data.comment],
      profileImage: [data.profileImage],
      jobType: ['IT'],
      itTerminationDate: [],
      itStartDate: [{ value: null, disabled: true }]
    });
    setTimeout(() => {
      this.commonService.$updateDefault.next();
    }, 200);
  }

  getLocation() {
    var req = '?countryId=5bf31835-d71b-4507-b697-3d439fce49ff';
    this._itService.getCities(req).subscribe((res: any) => {
      this.commonService.commonData.location = res;
    })
  }

  changeStatus() {
    this._itService.changeStatus(this.id, !this.userData.isActive).subscribe((res: any) => {
      if (res) this.userData.isActive = !this.userData.isActive;
    })
  }

  ngOnInit(): void {
    this.getOffice365();
    this.commonService.FetchAllGroups();
  }
  ngAfterViewInit() {
    console.log('----------------------------------', this.commonService.userRole.Admin);
    setTimeout(() => {
      this.viewInitialized = true;
    }, 500);
    if (this.initials) {
      let sbscription: any = null;
      fromEvent(this.initials.nativeElement, 'keyup').pipe(debounceTime(500)).subscribe((event: any) => {
        if (event.target.value) {
          if (sbscription) sbscription.unsubscribe();
          sbscription = this._itService.getUserInfo(event.target.value, false).subscribe((res: any) => {
            if (res && Object.keys(res).length) {
              this.toastr.error('initial is not available');
              this.stepForm.get('initials').setValue('');
            }
          },
            (error: any) => {
              this.toastr.success('initial is available!!');
            }
          )
        }
      })
    }
  }

  onChangeDepartment(departmentId: any = null) {
    departmentId = departmentId || this.stepForm.get('departmentId').value;
    var req = '?departmentId=' + departmentId;
    this._itService.GetPracticeByDepartmentId(req).subscribe((res: any) => {
      this.pageData.practice = res;
    })
  }
  onChangePracticeLead() {
    var req = '?practiceLeadId=' + this.stepForm.get('practiceLeadId').value;
    this._itService.getFCLead(req).subscribe((res: any) => {
      this.commonService.commonData.fCLead = res;
    })
  }
  getCity() {
    var req = '?countryId=' + this.stepForm.get('countryId').value;
    this._itService.getCities(req).subscribe((res: any) => {
      this.commonService.commonData.cities = res;
    })
  }

  searchAssets() {
    if (this.stepForm.get('searchAsset').value) {
      if (this.sbs) this.sbs.unsubscribe();
      var req = '?searchText=' + this.stepForm.get('searchAsset').value + '&status=new';
      this.sbs = this._itService.getAssets(req, false).subscribe((res: any) => {
        this.searchedAssets = res;
      })
    }
  }

  assignAssets() {
    this.selectedAssets = this.selectedAssets.concat(this.searchedAssets.filter((res: any) => {
      return !!res.selectedAssign;
    }))
  }

  removeAssets() {
    this.selectedAssets = this.selectedAssets.map((res: any) => {
      res.isDeleted = !!res.selected;
      return res;
    })
  }

  isRemoveDisabled() {
    return (!this.selectedAssets || this.selectedAssets.length == 0 || (this.selectedAssets && this.selectedAssets.length > 0 &&
      !this.selectedAssets.find(asset => { return !!asset.selected })));
  }
  isAssignDisabled() {
    return (!this.searchedAssets || this.searchedAssets.length == 0 || (this.searchedAssets && this.searchedAssets.length > 0 &&
      !this.searchedAssets.find(asset => { return !!asset.selectedAssign })))
  }

  saveDetails(isADPublish: boolean = false) {
    this.isValidPassword = true;
    if (this.stepForm.invalid) return;
    var _self = this;
    var req: any = {
      employeeViewModel: {
        ...this.userData,
        ..._self.stepForm.getRawValue()
      },
      "isHR": false,
      "isADPublish": isADPublish,
      assetsDetails: []
    };
    if (_self.selectedAssets && _self.selectedAssets.length > 0) {
      req.assetsDetails = _self.selectedAssets.map(asset => {
        return { id: asset.id, isDeleted: asset.isDeleted };
      });
    }
    req.groupDetails = this.commonService.allGroups.filter((group) => {
      return !!this.groupIds.includes(group.id);
    }).map((group) => {
      return { id: group.id, name: group.displayName };
    })
    req.office365Ids = this.office365Ids;
    if (_self.id) {
      req.employeeViewModel.id = _self.id;
    }
    req.employeeViewModel.itTerminationDate = _self.ngbdToDatePipe.transform(req.employeeViewModel.itTerminationDate),
      req.employeeViewModel.itStartDate = _self.ngbdToDatePipe.transform(req.employeeViewModel.itStartDate),
      req.employeeViewModel.startStopStatus = this.start ? 'Start' : 'Stop';
    req.employeeViewModel.startTime = _self.ngbdToDatePipe.transform(req.employeeViewModel.startTime);
    req.employeeViewModel.employeePer = _self.ngbdToDatePipe.transform(req.employeeViewModel.employeePer);
    req.employeeViewModel.practice = null;
    req.employeeViewModel.bag = parseInt(req.employeeViewModel.bag);
    console.log("============================================");
    console.log(req);
    _self._itService.addEmployee(req).subscribe((res: any) => {
      this.toastr.success(this.id ? 'IT User updated successfully' : 'IT User added successfully');
      _self._router.navigate(['/IT']);
    })
  }
  updateToAzure() {
    var req = {
      "initials": this.stepForm.get('initials').value,
      "password": this.stepForm.get('password').value,
      "sharedMailbox": true,
      "initialPassword": "",
      "firstname": this.stepForm.get('firstName').value,
      "lastname": this.stepForm.get('lastName').value,
      "emailExtention": this.userData.mailExtension,
      "managerInitials": "",
      "mobileNumber": this.userData.mobileNo,
      "department": this.userData.department,
      "location": {
        "name": this.stepForm.get('firstName').value + this.stepForm.get('lastName').value,
        "country": this.userData.country,
        "streetAddress": "",
        "city": this.userData.city
      }
    }
    /* 
      If password is not blank then validate it
        To Update Azure - Min 8 characters, at least one uppercase letter, 
												one lowercase letter, one number and one special character required
    */
    if(!this._passwordPattern.test(req.password)) {
      this.isValidPassword = false;
      return false;
    } else {
      this.isValidPassword = true;
    }
    // this._itService.modifyTranslations(req).subscribe((res: any) => {
    //   if (res && res.Message) {
    //     this.toastr.error(res.Message);
    //   } else {
    //     this.toastr.success("User created on AD!!");
    //   }
    // })
    this.saveDetails(true)
  }

  validatePassword(password) {
    if(this._passwordPattern.test(password)) {
      this.isValidPassword = true;
    }
  }

  getOffice365() {
    this._itService.getOffice365('', true).subscribe((res) => {
      this.office365 = res;
    })
  }
}