<div ngbDropdown>
    <div ngbDropdownToggle (click)="reset()">
        <select></select>
        <div class="options">
            <div *ngIf="!selectedValues">Select</div>
            <div class="multi-label-select" selected *ngIf="selectedValues">
                <span>{{selectedValues[0].displayName}}</span>
                <span class="select-more-lbl" *ngIf="selectedValues.length > 1">+{{selectedValues.length - 1}} more</span>
            </div>
        </div>
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <div class="list-dropdown-item search-field">
            <input class="form-control" [(ngModel)]="searchTxt"
             placeholder="Search..." (keyup)="onSearch($event)">
        </div>
        <ul class="list-dropdown">
            <li class="list-dropdown-item" *ngFor="let item of filteredList;let i = index;">
                <input type="checkbox" [id]="item.id" [(ngModel)]="checkboxSelected[item.id]"
                    (ngModelChange)="triggerSelected($event,item.id)" />
                <label [for]="item.id">{{item.displayName}}</label>
            </li>
        </ul>
    </div>
</div>