<h1 class="headTitle" translate="email_template"></h1>
<!-- filter -->
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row">
		<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 filter">
			<div class="row">
				<div class="col-xl-6 col-lg-12 col-md-6 col-sm-4">
					<div class="input-group searchBox">
						<input class="form-control" placeholder="Search by keyword" (keyup)="onSearchTxt()"
						[(ngModel)]="filters.searchText">
						<span class="input-group-addon"><i class="fa fa-search"></i></span>
					</div>
				</div>

			</div>
		</div>
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-right">
			<button type="button" class="btn btn-primary btn-lg" routerLink="/emailtemplate/new" translate="email_add_email_template"></button>
		</div>
	</div>
</div>
<!-- listing header -->
<div class="mail-listing header">
	<div class="card b-radius">
		<table class="table table-custom mb-0">
			<tbody>
				<tr>
					<!-- <td>ID</td> -->
					<td>{{'email_templateType' | translate}}</td>
					<td>{{'email_templateName' | translate}}</td>
					<td>{{'email_templatesubject' | translate}}</td>
					<td>{{'email_templatefrom' | translate}}</td>
					<td></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<!-- -->
<div class="mail-listing" infiniteScroll (scrolled)="onScroll()">
	<div class="card b-radius" *ngFor="let ma of emailtemplates; let i = index">
		<table class="table table-custom mb-0">
			<tbody>
				<tr>
					<!-- <td>
						<div class="greenFont lightFont">0000</div>
					</td> -->
					<td>
						<div class=" smFont">{{ma.templateType}}</div>
					</td>
					<td>
						<div>{{ma.templateName}}</div>
					</td>
					<td>
						<div>{{ma.subject}}</div>
					</td>
					<td>
						<div>{{ma.from}}</div>
					</td>
					<td align="right">
						<div class="dropdown action_{{i}}">
							<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
								<i class="fa fa-ellipsis-h"></i>
							</a>
							<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">		
								<a [routerLink]="['/emailtemplate', ma.id]" class="dropdown-item"><i
									class="fa fa-pencil mr-2"></i> {{'email_edit' | translate}}</a>
								<a href="javascript:void(0);" class="dropdown-item" (click)="delete(ma)"><i
									class="fa fa-times mr-2"></i> {{'email_delete' | translate}}</a>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<!-- -->
