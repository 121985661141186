import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetService } from '../../asset.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent implements OnInit {
  @Input() assetDetails: any = {};
  assetsForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _service: AssetService,
    private _toastr: ToastrService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.assetsForm = this.formBuilder.group({
      vendorName: [this.assetDetails.vendorName || null, Validators.required]
    });
  }
  addSubtype(subtype) {
    this.modalService.open(subtype)
  }
  saveAsset() {
    if(this.assetsForm.invalid) return;
    var _self = this;
    var req = {
      ..._self.assetsForm.getRawValue()
    };
    if (_self.assetDetails.id) {
      req.id = _self.assetDetails.id;
    }
    _self._service.addVendor(req).subscribe((res: any) => {
      _self._toastr.success(_self.assetDetails.id ? 'asset updated successfully' : 'asset added successfully');
      _self.activeModal.close(true);
    })
  }
}
