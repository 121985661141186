<nav class="navbar">
	<div class="container">
	<div class="d-flex flexSec">
		<div class="leftPanel ml-2 mr-4">
			<a class="navbar-brand p-0" href="#"><img src="assets/images/Kereby-logo.png"></a>
			<button class="navbar-toggler" type="button" aria-label="Toggle navigation" (click)="CallToggleNavbar()"><span class="fa fa-bars"></span></button>
		</div>
		<div class="rightPanel ml-2  mr-2">
			<!-- <div class="top-left-panel">
				<div class="d-inline-block">
					<div class="input-group searchBox">
						<span class="input-group-addon"><i class="fa fa-search"></i></span>
						<input class="form-control" placeholder="">							
					</div>
				</div>
			</div> -->
			<div class="top-right-panel">
				<!-- <a href="#" class="btn btn-outline-primary btn-sm"><i class="fa fa-plus"></i></a>
				<a href="#" class="btn btn-outline-primary btn-sm"><i class="fa fa-bell fa-rotate-45"></i><span class="value">6</span></a> -->
				
				<div class="dropdown user">
					<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
						<div class="user-panel d-flex">
							<div class="image">
								<img  class="img-circle elevation-4" alt="User" [src]="commonService.profile.imgUrl"  onerror="this.src='assets/images/default-img.png'"
								     *ngIf="!isImageLoading; else noImageFound">
								<ng-template #noImageFound>
								     <img src="fallbackImage.png"  class="img-circle elevation-4" alt="User">
								</ng-template>
								<!-- <img src="https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg" class="img-circle elevation-4" alt="User"> -->
							</div>
							<div class="info"><span href="#"><b>{{commonService.profile.name}}</b></span> <span><i class="fa fa-chevron-down ml-3"></i></span></div>
						</div>
					</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">		
						<a href="javascript:void(0);" (click)="authService.logout()" class="dropdown-item"><i class="fas fa-sign-out-alt mr-2"></i> {{'logout' | translate}}</a>
					</div>
				</div>
				
				<!-- <div class="dropdown flag">
					<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
							<div class="user-panel d-flex">
								<div class="info"><span><img src="assets/images/dk.png" alt=""></span></div>
							</div>
						</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">		
						<a href="javascript:void(0);" class="dropdown-item" *ngFor="let lng of commonService.languages" (click)="commonService.updateLanguage(lng)"><span><img src="assets/images/dk.png" alt=""></span>{{lng.name}}</a>
					</div>
				</div> -->
			</div>
			
		</div>	
		
	</div>
	</div>
</nav>