<h1 class="headTitle">HR</h1>
	<!-- filter -->
	<div class="card p-4 b-radius-bl b-radius-br mb-4">
		<div class="row">
			<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 filter">
				<div class="row">
					<div class="col-xl-6 col-lg-12 col-md-6 col-sm-4">
						<div class="input-group searchBox">
							<input class="form-control" placeholder="Search by name or email">
							<span class="input-group-addon"><i class="fa fa-search"></i></span>
						</div>
					</div>
					<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4">
						<select>
							<option>Status</option>
						</select>
					</div>
					<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4">
						<select>
							<option>Department</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-right">
				<button type="button" class="btn btn-primary btn-lg">Add Employee</button>
			</div>
		</div>
	</div>
	<!-- listing -->
	<div class="hr-tile">
		<div class="row m-0">
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
				<div class="card b-radius">
					<table class="table table-custom mb-0">
						<tbody>
							<tr>
								<td>
									<div class="image"><img
											src="https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg"
											class="img-circle elevation-4" alt="User"></div>
								</td>
								<td>
									<div class="greenFont">0000</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class=" mFont mb-2">Marui Speedwagon</div>
									<div>Business Manager</div>
									<a href="#" class="greenFont lightFont">ms@kereby.dk</a>
								</td>
							</tr>
							<tr>
								<td colspan="2"></td>
							</tr>
							<tr class="gray">
								<td><span class="greenFont lightFont">Active</span> </td>
								<td align="right">
									<div class="dropdown action">
										<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
											<i class="fa fa-ellipsis-h"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
											<a href="#" class="dropdown-item"><i class="fas fa-pencil-alt mr-2"></i>
												Edit</a>
											<a href="#" class="dropdown-item"><i class="fas fa-times mr-2"></i>
												Delete</a>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- -->
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
				<div class="card b-radius">
					<table class="table table-custom mb-0">
						<tbody>
							<tr>
								<td>
									<div class="image"><img
											src="https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg"
											class="img-circle elevation-4" alt="User"></div>
								</td>
								<td>
									<div class="greenFont">0000</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class=" mFont mb-2">Marui Speedwagon</div>
									<div>Business Manager</div>
									<a href="#" class="greenFont lightFont">ms@kereby.dk</a>
								</td>
							</tr>
							<tr>
								<td colspan="2"></td>
							</tr>
							<tr class="gray">
								<td><span class="greenFont lightFont">Active</span> </td>
								<td align="right">
									<div class="dropdown action">
										<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
											<i class="fa fa-ellipsis-h"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
											<a href="#" class="dropdown-item"><i class="fas fa-pencil-alt mr-2"></i>
												Edit</a>
											<a href="#" class="dropdown-item"><i class="fas fa-times mr-2"></i>
												Delete</a>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- -->
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
				<div class="card b-radius">
					<table class="table table-custom mb-0">
						<tbody>
							<tr>
								<td>
									<div class="image"><img
											src="https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg"
											class="img-circle elevation-4" alt="User"></div>
								</td>
								<td>
									<div class="greenFont">0000</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class=" mFont mb-2">Marui Speedwagon</div>
									<div>Business Manager</div>
									<a href="#" class="greenFont lightFont">ms@kereby.dk</a>
								</td>
							</tr>
							<tr>
								<td colspan="2"></td>
							</tr>
							<tr class="gray">
								<td><span class="greenFont lightFont">Active</span> </td>
								<td align="right">
									<div class="dropdown action">
										<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
											<i class="fa fa-ellipsis-h"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
											<a href="#" class="dropdown-item"><i class="fas fa-pencil-alt mr-2"></i>
												Edit</a>
											<a href="#" class="dropdown-item"><i class="fas fa-times mr-2"></i>
												Delete</a>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- -->
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
				<div class="card b-radius">
					<table class="table table-custom mb-0">
						<tbody>
							<tr>
								<td>
									<div class="image"><img
											src="https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg"
											class="img-circle elevation-4" alt="User"></div>
								</td>
								<td>
									<div class="greenFont">0000</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class=" mFont mb-2">Marui Speedwagon</div>
									<div>Business Manager</div>
									<a href="#" class="greenFont lightFont">ms@kereby.dk</a>
								</td>
							</tr>
							<tr>
								<td colspan="2"></td>
							</tr>
							<tr class="gray">
								<td><span class="greenFont lightFont">Active</span> </td>
								<td align="right">
									<div class="dropdown action">
										<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
											<i class="fa fa-ellipsis-h"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
											<a href="#" class="dropdown-item"><i class="fas fa-pencil-alt mr-2"></i>
												Edit</a>
											<a href="#" class="dropdown-item"><i class="fas fa-times mr-2"></i>
												Delete</a>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- -->
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
				<div class="card b-radius">
					<table class="table table-custom mb-0">
						<tbody>
							<tr>
								<td>
									<div class="image"><img
											src="https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg"
											class="img-circle elevation-4" alt="User"></div>
								</td>
								<td>
									<div class="greenFont">0000</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class=" mFont mb-2">Marui Speedwagon</div>
									<div>Business Manager</div>
									<a href="#" class="greenFont lightFont">ms@kereby.dk</a>
								</td>
							</tr>
							<tr>
								<td colspan="2"></td>
							</tr>
							<tr class="gray">
								<td><span class="greenFont lightFont">Active</span> </td>
								<td align="right">
									<div class="dropdown action">
										<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
											<i class="fa fa-ellipsis-h"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
											<a href="#" class="dropdown-item"><i class="fas fa-pencil-alt mr-2"></i>
												Edit</a>
											<a href="#" class="dropdown-item"><i class="fas fa-times mr-2"></i>
												Delete</a>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- -->
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
				<div class="card b-radius">
					<table class="table table-custom mb-0">
						<tbody>
							<tr>
								<td>
									<div class="image"><img
											src="https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg"
											class="img-circle elevation-4" alt="User"></div>
								</td>
								<td>
									<div class="greenFont">0000</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class=" mFont mb-2">Marui Speedwagon</div>
									<div>Business Manager</div>
									<a href="#" class="greenFont lightFont">ms@kereby.dk</a>
								</td>
							</tr>
							<tr>
								<td colspan="2"></td>
							</tr>
							<tr class="gray">
								<td><span class="greenFont lightFont">Active</span> </td>
								<td align="right">
									<div class="dropdown action">
										<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
											<i class="fa fa-ellipsis-h"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
											<a href="#" class="dropdown-item"><i class="fas fa-pencil-alt mr-2"></i>
												Edit</a>
											<a href="#" class="dropdown-item"><i class="fas fa-times mr-2"></i>
												Delete</a>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- -->
			<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 p-0">
				<div class="card b-radius">
					<table class="table table-custom mb-0">
						<tbody>
							<tr>
								<td>
									<div class="image"><img
											src="https://images.generated.photos/z19zw__hkFyITHgyssYzvMg6BPFKzqa8Oe0fMRdqmDs/rs:fit:512:512/Z3M6Ly9nZW5lcmF0/ZWQtcGhvdG9zL3Yz/XzAwNDI4ODQuanBn.jpg"
											class="img-circle elevation-4" alt="User"></div>
								</td>
								<td>
									<div class="greenFont">0000</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class=" mFont mb-2">Marui Speedwagon</div>
									<div>Business Manager</div>
									<a href="#" class="greenFont lightFont">ms@kereby.dk</a>
								</td>
							</tr>
							<tr>
								<td colspan="2"></td>
							</tr>
							<tr class="gray">
								<td><span class="greenFont lightFont">Active</span> </td>
								<td align="right">
									<div class="dropdown action">
										<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
											<i class="fa fa-ellipsis-h"></i>
										</a>
										<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
											<a href="#" class="dropdown-item"><i class="fas fa-pencil-alt mr-2"></i>
												Edit</a>
											<a href="#" class="dropdown-item"><i class="fas fa-times mr-2"></i>
												Delete</a>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- -->
		</div>

	</div>