<div class="row form m-3">
    <div class="col-sm-12">
        <h3 translate="check_lists"></h3>
        <div class="row check-list" *ngFor="let checklist of checklists">
            <!-- <input type="checkbox" [(ngModel)]="checklist.isChecked"/> -->
            <div class="col-xl-2 col-lg-1 col-md-1 col-sm-1 task-name">
                <label></label>
                <div class="form-group">
                    <label for="" translate="task_name"></label>
                    <span *ngIf="!checklist.editable">{{checklist.taskListName}}</span>
                    <input *ngIf="checklist.editable" class="form-control" [(ngModel)]="checklist.taskListName"
                        placeholder="">
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                <div class="form-group">
                    <label for="" translate="status"></label>
                    <select [(ngModel)]="checklist.statusId" (change)="CheckStatus(checklist)"
                        [disabled]="commonService.isChecklistDisable(checklist)">
                        <option *ngFor="let des of commonService.commonData.taskListStatus" [value]="des.id"
                            [default]="des.isDefault">
                            {{des.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <label for="" translate="deadline"></label>
                    <input class="form-control" [(ngModel)]="checklist.deadLine"
                        placeholder="{{commonService.dateFormat}}" ngbDatepicker #deadline="ngbDatepicker"
                        (click)="deadline.toggle()" [disabled]="commonService.isChecklistDisable(checklist)">
                </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <label for="" translate="task_description"></label>
                    <span class="word-break-all" *ngIf="!checklist.editable">{{checklist.taskListDescription ||
                        checklist.description}}</span>
                    <input *ngIf="checklist.editable" class="form-control" [(ngModel)]="checklist.description"
                        tabindex="3" placeholder="">
                </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <label for="" translate="comment"></label>
                    <input class="form-control" [(ngModel)]="checklist.comments" tabindex="3" placeholder=""
                        [disabled]="commonService.isChecklistDisable(checklist)">
                </div>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 d-inline-flex">
                <div class="form-group m-0">
                    <label for="" translate="task_completed?"></label>
                    <input class="form-control checkbox" [(ngModel)]="checklist.completed"
                        [disabled]="commonService.isChecklistDisable(checklist)"
                        (change)="setCompleted($event,checklist)" type="checkbox">
                </div>
                <a href="javascript:void(0);" class="btn btn-outline-primary btn-sm plus-icon ml-2"
                    *ngIf="checklist.editable" (click)="deleteCheckList(checklist)">
                    <i class="fa fa-times"></i>
                </a>
            </div>
        </div>
        <div *ngIf="checklists?.length == 0" class="d-block text-center">
            <span translate="no_task_checklist_available"></span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
            <button type="button" class="btn btn-primary btn-lg next" *ngIf="checklists?.length != 0" (click)="save()"
                translate="task_save"></button>
            <button type="button" class="btn btn-primary btn-lg next" (click)="addNewTask()"
                translate="add_new_task"></button>
        </div>
    </div>
</div>