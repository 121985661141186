<!-- listing -->
<div class="assets-listing">
	<div class="card b-radius" *ngFor="let ma of assets">
		<table class="table table-custom mb-0">
			<tbody>
				<tr>
					<td>
						<div class="greenFont lightFont cursor-pointer" (click)="addAssets(ma)">{{ma.name}}</div>
						<div class="mFont pt-3 pb-1">{{ma.modelName}}</div>
						<div class="pb-2">{{ma.serialNo}}</div>
					</td>
					<td align="right">
						<div class="mFont greenFont d-inline-block mb-4">{{ma.status}}</div>
						<div class="dropdown action d-inline-block">
							<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
								<i class="fa fa-ellipsis-h"></i>
							</a>
							<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
								<a href="javascript:void(0);" class="dropdown-item" (click)="addAssets(ma)"><i
										class="fa fa-pencil mr-2"></i> {{'hardware_edit' | translate}}</a>
								<a href="javascript:void(0);" class="dropdown-item" (click)="addComment(ma)"><i class="fa fa-comment mr-2"></i>
									{{'hardware_comment' | translate}}</a>
								<a href="javascript:void(0);" class="dropdown-item" (click)="deleteAsset(ma)"><i
										class="fa fa-times mr-2"></i> {{'hardware_delete' | translate}}</a>
							</div>
						</div>
						<br />
						<div class="d-inline-block mr-3">
							<span class="greenFont lightFont">{{'hardware_added' | translate}} </span>
							<span>{{ma.addedDateTime | date: commonService.dateFormat}}</span>
						</div>
						<div class="d-inline-block">
							<span class="greenFont lightFont">{{'hardware_return' | translate}} </span>
							<span>{{ma.returnDateTime | date: commonService.dateFormat}}</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<ngb-pagination
	[(page)]="pageNo"
	[pageSize]="pageSize"
	[collectionSize]="totalCount"
	(pageChange)="onScroll($event)"
	></ngb-pagination>
</div>