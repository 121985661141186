import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {

  constructor(
    private http: HttpClient
    ) { }

    getCheckList(req) {
      return this.http.get(`api/CheckListTemplate/GetTaskListUser${req}`);
    }
    
    saveCheckList(data) {
      return this.http.post(`api/CheckListTemplate/AddUpdateTaskListUser`,data);
    } 

    getStatistics(reqbody) {
      return this.http.post(`api/Report/GetStatistics`,reqbody);
    }

    getReportSummary(reqbody) {
      return this.http.post(`api/Report/GetSummary`,reqbody);
    }

    getReportDetails(reqbody) {
      return this.http.post(`api/Report/GetDetails`,reqbody);
    }
}
