import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/common.service';
import { ItService } from '../it.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input() modalData:any = {};
  info:any={};
  fullName:string='';
  constructor(
    private itService:ItService,
    public activeModal: NgbActiveModal,
    public commonService: CommonService
    ) { }

  ngOnInit(): void {
    this.fullName=this.modalData.firstName+' '+this.modalData.lastName;   
    this.itService.getUserInfo(this.modalData.initials,true).subscribe(
      (res:any)=>{
        this.info = res;           
      }
    )   
  }

}
