<h1 class="headTitle" translate="shared_mailbox"></h1>
<!-- filter -->
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row">
		<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 filter">
			<h1 class="headTitle mt-2 ml-3">{{(id ? 'edit_mailbox' : 'add_mailbox') | translate}}</h1>
		</div>
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-right">
		</div>
	</div>
</div>
<!-- listing header -->
<div class="">
	<div class="card b-radius p-4">
		<form [formGroup]="mailForm" class="row form m-1 mt-3">
			<div class="col-xl-5 col-lg-7 col-md-7 col-sm-12">
				<div class="form-group">
					<label for="" translate="mailbox"></label>
					<input class="form-control" formControlName="mailbox" placeholder="">
				</div>
			</div>
			<div class="col-xl-4 col-lg-5 col-md-5 col-sm-12">
				<div class="form-group">
					<label for="" translate="mail_ext"></label>
					<select formControlName="extensionId">
						<option *ngFor="let me of commonService.commonData.mailExtensions" [value]="me.id" [default]="me.isDefault">{{me.name}}</option>
					</select>
				</div>
			</div>
			<div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 mt-3">
				<div class="form-group">
					<label for="" translate="status"></label>
					<select formControlName="status">
						<option>-- Select --</option>
						<option>Created in Active Directory</option>
					</select>
					
				</div>
			</div>
			<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
				<button type="submit" class="btn btn-primary btn-lg" [disabled]="mailForm.invalid" (click)="saveMail()" translate="save"></button>
				<button type="submit" class="btn btn-primary btn-lg" routerLink="/shared-mailbox"><u>{{'cancel' | translate}}</u></button>
			</div>
		</form>
	</div>
</div>