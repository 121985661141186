import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomHttpParams } from 'src/app/common.service';

@Injectable({
  providedIn: 'root'
})
export class ItService {
  constructor(
    private http: HttpClient) { }
  addEmployee(data) {
    return this.http.post(`api/Employee/AddUpdateEmployee`, data);
  }
  updatePartial = (data) => {
    var req = `?id=${data.id}&comment=${data.comment.replace(new RegExp('\n', 'g'), "%0A").replace(new RegExp('#', 'g'), "%23")}`;
    return this.http.post(`api/Employee/PartialUpdate` + req, {});
  }
  getEmpById(req) {
    return this.http.get(`api/Employee/GetById/${req}`);
  }
  getPracticeLead(req) {
    return this.http.get(`api/Dropdown/GetPracticeLeadByPracticeId${req}`);
  }
  getFCLead(req) {
    return this.http.get(`api/Dropdown/GetFocusContLeadByPracticeLeadId${req}`);
  }
  getCities(req) {
    return this.http.get(`api/Dropdown/GetCityByCountryId${req}`);
  }
  deleteEmp(id) {
    return this.http.delete(`api/Employee/DeleteById/${id}`);
  }
  getAll(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Employee/GetAll${req}`, httpOptions);
  }
  getAllCount(req) {
    const httpOptions = {
      params: new CustomHttpParams(false)
    };
    return this.http.get(`api/Employee/getAllCount${req}`,httpOptions);
  }
  getAssets(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Assets/GetAll${req}`, httpOptions);
  }
  resendEmail(employeeId, type) {
    return this.http.get(`api/EmailTemplates/SendEmail/${employeeId}/${type}`);
  }
  enableUser(initials) {
    return this.http.put(`api/User/UserEnable/${initials}`, {});
  }
  disableUser(initials) {
    return this.http.put(`api/User/UserDisable/${initials}`, {});
  }
  changeStatus(id, status) {
    const data = {
      id,
      isActive: status
    }
    return this.http.post(`api/Employee/PartialUpdateForStatus?id=${id}&isActive=${status}`, null);
  }
  removeUserFromGroup(initials) {
    return this.http.post(`api/User/RemoveUserFromGroup`, initials);
    
  }
  getUserInfo(initials, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/User/GetUser/${initials}`, httpOptions);
  }
  modifyTranslations(req) {
    return this.http.post(`api/User/AddUpdateTranslations`, req);
  }
  getOffice365(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Office365License/GetAll${req}`, httpOptions);
  }
  GetPracticeByDepartmentId(req) {
    return this.http.get(`api/Dropdown/GetPracticeByDepartmentId${req}`);
  }
}
