<form [formGroup]="productForm" (ngSubmit)="saveProduct()" class="row form m-1 mt-3">
    <div class="row form m-4">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <h1 class="headTitle">{{(assetDetails.id ? 'update_products' : 'add_products') | translate}}</h1>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="products_name"></label>
                    <input class="form-control" formControlName="name" tabindex="1" placeholder="">
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="products_type"></label>
                    <select formControlName="typeId" tabindex="3" (change)="getSubType(true)">
                        <option *ngFor="let type of commonService.commonData.types" [value]="type.id" [default]="type.isDefault">{{type.type}}</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="products_Sub_type"></label>
                    <select formControlName="subTypeId" tabindex="5">
                        <option *ngFor="let type of data.subtype" [value]="type.id" [default]="type.isDefault">{{type.type}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="products_vendor"></label>
                    <select formControlName="vendorId" tabindex="2">
                        <option *ngFor="let vendor of data.vendors" [value]="vendor.id" [default]="vendor.isDefault">{{vendor.vendorName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="products_lifetime_month"></label>
                    <input class="form-control" formControlName="lifeTimeMonth" tabindex="4" type="text" placeholder="">
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label for="" translate="products_price"></label>
                    <input class="form-control" formControlName="price" tabindex="6" type="number" placeholder="">
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <button type="submit" class="btn btn-primary btn-lg next" translate="products_save"></button>
            <button type="submit" class="btn btn-primary btn-lg prev"
                (click)="activeModal.dismiss()"><u>{{'products_cancel' | translate}}</u></button>
        </div>
    </div>
</form>