<h1 class="headTitle" translate>hr</h1>
<!-- filter -->
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row">
		<div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 filter">
			<div class="row">
				<div class="col-xl-3 col-lg-12 col-md-6 col-sm-4">
					<div class="input-group searchBox">
						<input class="form-control" placeholder="Search by name or email" (keyup)="onSearchTxt()"
							[(ngModel)]="filters.searchText">
						<span class="input-group-addon"><i class="fa fa-search"></i></span>
					</div>
				</div>
				<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4">
					<select [(ngModel)]="filters.status" (change)="resetDetails()">
						<option value="" default="true">Select</option>
						<option value="User Created">User Created</option>
						<option value="User Published">User Published</option>
						<option value="Disabled">Disabled</option>
					</select>
				</div>
				<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4">
					<select [(ngModel)]="filters.department" (change)="resetDetails()">
						<option value="" default="true">Select</option>
						<option *ngFor="let dep of commonService.commonData.departments" [value]="dep.id"
							[default]="dep.isDefault">{{dep.name}}
						</option>
					</select>
				</div>
				<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4">
					<div class="row ml-3 align-items-center">
						<label>STOP</label>
						<label class="switch">
							<input type="checkbox" [(ngModel)]="filters.start" (ngModelChange)="resetDetails()">
							<span class="slider round" [ngClass]="{'off':!filters.start}"></span>
						</label>
						<label>START</label>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 text-right">
			<button type="button" class="btn btn-primary btn-lg" routerLink="/emp/new"
				translate>hr_add_employee</button>
		</div>
	</div>
</div>
<!-- listing -->
<div class="hr-listing">
	<div class="card b-radius" *ngFor="let emp of employees">
		<div class="itlist-item">
			<!-- <div class="cursor-pointer user-img-block">
				<div class="image"><img [src]="emp.profileImage" onerror="this.src='assets/images/default-img.png'"
						class="img-circle elevation-4" alt="User">
				</div>
				<div class=" mFont lightFont">
					<span translate>Employee ID</span>
					{{emp.employeeId}}
				</div>
			</div>
			<div class="hr-list-em-name">
				<div class="greenFont mFont cursor-pointer" routerLink="/emp/{{emp.id}}">
					{{emp.firstName + ' ' + emp.lastName}}</div>
				<div>{{emp.jobPosition}}</div>
			</div>
			<div class="hr-list-email-phone">
				<a href="#" class="greenFont lightFont">{{emp.emergencyEmail}}</a>
				<div>{{emp.phone}}</div>
			</div>
			<div class="job-role">
				<div class="greenFont lightFont" translate="hr_job_role"></div>
				<div>{{emp.practice || emp.jobPosition}}</div>
			</div>
			<div class="job-post">
				<div class="greenFont lightFont" translate="hr_manager"></div>
				<div>{{emp.practiceLead}}</div>
			</div> -->
			<!-- ===================================== -->
			<div class="cursor-pointer user-img-block">
				<div class="image">
					<img [src]="emp.profileImage" onerror="this.src='assets/images/default-img.png'"
						class="img-circle elevation-4" alt="User">
				</div>
				<div routerLink="/emp/{{emp.id}}" [attr.aria-expanded]="!emp.isExpended"
					class="cursor-pointer position-post" aria-controls="collapseExample">
					<div class=" mFont">{{emp.firstName + ' '+ emp.lastName}}</div>
					<div><span>{{emp.practice || emp.jobPosition}}</span></div>
				</div>
			</div>

			<div class="user-mobile">
				<div class="greenFont lightFont">{{'dashboard_mobile' | translate}}</div>
				<div>{{emp.mobileNo}}</div>
			</div>
			<div class="user-department">
				<div class="greenFont lightFont">{{'dashboard_department' | translate}}</div>
				<div>{{emp.department}}</div>
			</div>
			<div class="user-post">
				<div class="greenFont lightFont">{{'hr_manager' | translate}}</div>
				<div>{{emp.managerName || emp.practiceLead}}</div>
			</div>
			<div class="user-select-date">
				<div class="startdate" *ngIf="emp.startStopStatus==='Start'">
					<span class="greenFont lightFont">{{'dashboard_start_date' | translate}}</span>
					<span>{{emp.startTime | date:commonService.dateFormat}}</span>
				</div>
				<div *ngIf="emp.startStopStatus==='Stop'">
					<span class="greenFont lightFont">{{'hr_it_termination_date' | translate}}</span>
					<span>{{(emp.itTerminationDate) | date: commonService.dateFormat}}</span>
				</div>
				<div *ngIf="emp.startStopStatus==='Stop'">
					<span class="greenFont lightFont">{{'hr_last_payment_of_salary' | translate}}</span>
					<span>{{(emp.lastPaymentSalaryDate) | date: commonService.dateFormat}}</span>
				</div>
				<div class="taskname"><span class="greenFont lightFont">{{'hr_tasks' | translate}}</span>
					<span translate="tasks_completed" *ngIf="(emp.totalCompletedTask != emp.totalTask) else completed"
						[translateParams]="{completed:emp.totalCompletedTask, total:emp.totalTask}"></span>
					<ng-template #completed>
						<span translate="tasks_all_completed"></span>
					</ng-template>
				</div>
			</div>
			<!-- <div class="greenFont lightFont mFont">{{emp.hrStatus}}</div> -->
			<!-- <div class="hr-task-aa-asign">
				<div class="greenFont lightFont" translate="hr_tasks"></div>
				<div translate="tasks_completed" *ngIf="(emp.totalCompletedTask != emp.totalTask) else completed"
					[translateParams]="{completed:emp.totalCompletedTask, total:emp.totalTask}"></div>
				<ng-template #completed>
					<div translate="tasks_all_completed"></div>
				</ng-template>
			</div> -->
			
			<div class="greenFont lightFont mFont" *ngIf="emp.isActive !== false">{{emp.hrStatus || 'New'}}</div>
			<div class="greenFont lightFont mFont" *ngIf="emp.isActive===false">Disabled</div>
			<div class="user-new">
				<div class="dropdown action">
					<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
						<i class="fa fa-ellipsis-h"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
						<a href="javascript:void(0);" routerLink="/emp/{{emp.id}}" class="dropdown-item"><i
								class="fa fa-pencil mr-2"></i> {{'hr_edit' | translate}}</a>
						<a href="javascript:void(0);" (click)="showChecklist(emp)" class="dropdown-item"><i
								class="fa fa-check mr-2"></i> {{'hr_checklist' | translate}}</a>
						<a href="javascript:void(0);" class="dropdown-item" (click)="deleteEmp(emp)"><i
								class="fa fa-times mr-2"></i> {{'hr_delete' | translate}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<ngb-pagination
	[(page)]="pageNo"
	[pageSize]="pageSize"
	[collectionSize]="totalCount"
	(pageChange)="onScroll($event)"
	></ngb-pagination>
</div>