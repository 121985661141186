 
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Report Details : <span *ngIf="title">{{title}}</span> </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeIt()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
<table>
    <thead>
        <tr>            
            <th>Name</th>
            <th>Email</th>
            <th>Department</th>
            <th>Start Date</th>
            <th *ngIf="showStopDate()">Stop Date</th>                        
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let itm of detailData">            
            <td>{{itm.name}}</td>
            <td>{{itm.email}}</td>
            <td>{{itm.department}}</td>
            <td>{{itm.startTimeToString}}</td>
            <td *ngIf="showStopDate()">{{itm.terminationDate}}</td>                     
        </tr>
    </tbody>
    <tfoot>
        <ngb-pagination
        [(page)]="pageNo"
        [pageSize]="pageSize"
        [collectionSize]="totalCount"
        (pageChange)="onScroll($event)"
        ></ngb-pagination>
    </tfoot>
</table>
</div>