import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { EmailTemplateService } from '../emailtemplate.service';

@Component({
  selector: 'app-addtemplates',
  templateUrl: './addtemplates.component.html',
  styleUrls: ['./addtemplates.component.scss']
})
export class AddtemplatesComponent implements OnInit {

  emailTemplateForm: FormGroup;
  id: any = '';
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '600',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  constructor(
    private emailTemplateService: EmailTemplateService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private _router: Router) {
    var id = this.route.snapshot.paramMap.get('id');
    this.setForm();
    if (id && id != 'new') {
      this.id = id;
      this.emailTemplateService.getById(`/${this.id}`).subscribe((res: any) => {
        this.setForm(res);
      });
    }
  }
  setForm(data: any = {}) {
    this.emailTemplateForm = this.formBuilder.group({
      isActive:[data.isActive || null],
      templateType: [data.templateType || null, Validators.required],
      templateName: [data.templateName || null, Validators.required],
      from: [data.from || null, Validators.email],
      replyTo: [data.replyTo || null, Validators.email],
      body: [data.body || null, Validators.required],
      cc: [data.cc || null, Validators.email],
      bcc: [data.bcc || null, Validators.email],
      subject: [data.subject || null, Validators.required]
    });
  }

  ngOnInit(): void {
  }
  saveEmailTemplate() {
    var _self = this;
    var req = {
      ..._self.emailTemplateForm.getRawValue()
    };
    if(_self.id) {
      req.id = _self.id;
    }
    _self.emailTemplateService.AddUpdate(req).subscribe((res: any) => {
      _self.toastr.success('Email Template added successfully');
      _self._router.navigate(['/emailtemplate']);
    })
  }
}
