<form [formGroup]="assetsForm" (ngSubmit)="saveAsset()" class="row form m-1 mt-3">
    <div class="row form m-4">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <h1 class="headTitle">{{(assetDetails.id ? 'update_hardware_type' : 'add_hardware_type') | translate}}</h1>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="" translate="hardware_type"></label>
                <input class="form-control" formControlName="type" placeholder="add type here...">
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="" translate="hardware_type_view"></label>
                <select formControlName="view">
                    <option>Default</option>
                    <option>Mobile</option>
                </select>
            </div>
        </div>
        <div class="d-flex justify-content-end col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <button type="button" class="btn btn-primary btn-sm btn-lg next" (click)="addSubtype(subtype)"
                translate="hardware_type_add_subtype"></button>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <button type="submit" class="btn btn-primary btn-lg next" translate="hardware_type_save"></button>
            <button type="button" class="btn btn-primary btn-lg prev"
                (click)="activeModal.dismiss()"><u>{{'hardware_type_cancel' | translate}}</u></button>
        </div>
    </div>
    <ng-template #subtype #content let-modal>
        <div class="row form m-4" *ngIf="assetsForm.get('subTypes') as FormArray">
            <div class="col-12" formArrayName="subTypes"
            *ngFor="let list of assetsForm.get('subTypes').controls; let i = index;let $last=last;">
                <div class="row" [formGroup]="list" *ngIf="!list.get('isDeleted').value">
                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                        <div class="form-group">
                            <label for="" translate=""></label>
                            <input class="form-control" formControlName="type" placeholder="add subtype here...">
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                        <a href="javascript:void(0);" class="btn btn-outline-primary btn-sm plus-icon mt-1"
                            (click)="removeSubtypelist(i)">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                <a href="javascript:void(0);" class="btn btn-outline-primary btn-sm plus-icon"
                    (click)="addSubtypelist()">
                    <i class="fa fa-plus"></i>
                </a>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <button type="button" class="btn btn-primary btn-lg next"
                    (click)="modal.close()" translate="hardware_type_save"></button>
            </div>
        </div>
    </ng-template>
</form>