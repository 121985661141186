<h1 class="headTitle">HR</h1>
<!-- filter -->
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row">
		<div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 filter">
			<h1 class="headTitle mt-2 ml-3">{{(id ? 'hr_edit_employee' : 'hr_add_employee') | translate}}
			</h1>
			<h1 class="d-block ml-3 mt-3" *ngIf="userData.firstName || userData.lastName">
				{{userData.firstName +' '+userData.lastName}}
			</h1>
		</div>
		<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 text-right">
			<div class="stepView">
				<div class="navbar stepNav">
					<div class="progressContainer">
						<div>
							<div class="progress">
								<div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="1"
									aria-valuemin="1" aria-valuemax="4" style="width: 25%;"></div>
							</div>
						</div>
					</div>
					<div class="navbar-inner">
						<ul class="nav nav-pills">
							<li>
								<a href="javascript:void(0);" [ngClass]="{'active':step==1}" (click)="goToStep(1)"
									data-step="1">
									<span class="digit">1</span>
									<span class="step-label">{{'hr_step_1'|translate}}</span>
								</a>
							</li>
							<li>
								<a href="javascript:void(0);" [ngClass]="{'active':step==2}" (click)="goToStep(2)"
									data-step="2">
									<span class="digit">2</span>
									<span class="step-label">{{'hr_step_2'|translate}}</span>
								</a>
							</li>
							<li>
								<a href="javascript:void(0);" [ngClass]="{'active':step==3}" (click)="goToStep(3)"
									data-step="3">
									<span class="digit">3</span>
									<span class="step-label">{{'hr_step_3'|translate}}</span>
								</a>
							</li>
							<li>
								<a href="javascript:void(0);" [ngClass]="{'active':step==4}" (click)="goToStep(4)"
									data-step="4">
									<span class="digit">4</span>
									<span class="step-label">{{'hr_step_4'|translate}}</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- listing  -->

<div class="">
	<div class="marT">
		<div class="tab-content">
			<!-- tab one -->
			<div class="tab-pane fade" [ngClass]="{'show active':step==1}">
				<div class="card b-radius p-4">
					<div class="row justify-content-end align-items-center pr-5" *ngIf="id">
						<label>STOP</label>
						<label class="switch">
							<input type="checkbox" [(ngModel)]="start">
							<span class="slider round" [ngClass]="{'off':!start}"></span>
						</label>
						<label>START</label>
					</div>
					<form [formGroup]="stepForm[1]" (ngSubmit)="saveNext(2)" *ngIf="step==1" class="row form m-1 mt-3">
						<div class="col-xl-3 col-lg-5 col-md-4 col-sm-3">
							<div class="imgSec">
								<div class="image"><img [src]="getImgUrl()"
										onerror="this.src='assets/images/default-img.png'"
										class="img-circle elevation-4" alt="User"></div>
								<a href="javascript:void(0);" (click)="profilePhoto.click();" class="camera"><i
										class="fa fa-camera"></i></a>
								<input type="file" style="display:none;" #profilePhoto (change)="savePhoto($event)" />
								<br />
								<a translate="hr_change_photo"></a>
							</div>
						</div>
						<div class="col-xl-9 col-lg-7 col-md-8 col-sm-9">
							<div class="row form m-1 mt-3 mb-4">
								<!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_user_account"></label>
										<select formControlName="userAccountId">
											<option *ngFor="let user of commonService.commonData.userAccounts"
												[value]="user.id">{{user.type}}</option>
										</select>
									</div>
								</div> -->
								<div class="col-xl-4 col-lg-12 col-md-5 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_first_name"></label>
										<input formControlName="firstName" class="form-control">
									</div>
								</div>
								<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_last_name"></label>
										<input formControlName="lastName" class="form-control">
									</div>
								</div>
								<ng-container *ngIf="start">
									<!-- <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_it_start_date"></label>
											<input formControlName="itStartDate" class="form-control"
												placeholder="{{commonService.dateFormat}}" readonly ngbDatepicker
												#itStartDate="ngbDatepicker" (click)="itStartDate.toggle()">
										</div>
									</div> -->
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_contract"></label>
											<select formControlName="contract">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.ContractType"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
									<!-- <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_emp_data"></label>
											<select formControlName="employeeData">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.EmployeeData"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div> -->
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_onboarding"></label>
											<select formControlName="onBoarding">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.OnBoarding"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_welcome_letter"></label>
											<select formControlName="welcomeLetter">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.WelcomeLetter"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_business_cards"></label>
											<select formControlName="businessCards">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.BusinessCards"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
									<!-- <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_informail_hello_goodbye"></label>
											<select formControlName="informailHelloGoodBye">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.InformailHelloGoodBye"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div> -->
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_send_cia"></label>
											<select formControlName="sendToCIA">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.SendToCIA"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="!start">
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_it_termination_date"></label>
											<input formControlName="itTerminationDate" class="form-control"
												placeholder="{{commonService.dateFormat}}" readonly ngbDatepicker
												#itTerminationDate="ngbDatepicker" (click)="itTerminationDate.toggle()">
										</div>
									</div>
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_last_payment_of_salary"></label>
											<input formControlName="lastPaymentSalaryDate" class="form-control"
												placeholder="{{commonService.dateFormat}}" readonly ngbDatepicker
												#lastPaymentSalaryDate="ngbDatepicker"
												(click)="lastPaymentSalaryDate.toggle()">
										</div>
									</div>
									<!-- <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_index"></label>
											<input formControlName="indexValue" class="form-control">
										</div>
									</div>
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_month_since"></label>
											<input formControlName="monthSince" class="form-control">
										</div>
									</div> -->
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_exit_buddy"></label>
											<select formControlName="exitBuddyId">
												<option>Select</option>
											</select>
										</div>
									</div>
									<!-- <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_alumni_email"></label>
											<input formControlName="alumniEmail" class="form-control">
										</div>
									</div> -->
									<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_new_workplace"></label>
											<input formControlName="newWorkPlace" class="form-control">
										</div>
									</div>
									<!-- <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_transfer_phone"></label>
											<select formControlName="transferPhone">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.TransferPhone"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div> -->
								</ng-container>
								<div class="col-xl-4 col-lg-6 col-md-5 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_emp_id"></label>
										<input formControlName="employeeId" class="form-control" placeholder="0000">
									</div>
								</div>
								<div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_contactNo"></label>
										<input formControlName="contactNo" class="form-control">
									</div>
								</div>
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
									<button type="submit" class="btn btn-primary btn-lg next"
										translate="hr_save_next"></button>
									<button type="button" class="btn btn-primary btn-lg"
										routerLink="/hr-list"><u>{{'hr_cancel' | translate}}</u></button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<!-- tab two -->
			<div class="tab-pane fade" [ngClass]="{'show active':step==2}">
				<div class="card b-radius p-4">
					<form [formGroup]="stepForm[2]" (ngSubmit)="saveNext(3)" *ngIf="step==2" class="row form mt-3">
						<div class="col-lg-12 col-md-12 col-sm-12">
							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
								<h1 class="headTitle" translate="hr_emp_details"></h1>
							</div>

							<div class="row form m-1 mt-4 mb-4">
								<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_job_position"></label>
										<input formControlName="jobPosition" class="form-control"
											placeholder="job position">
										<!-- <select formControlName="jobPosition">
												<option *ngFor="let des of commonService.commonData.designations" [value]="des.id">{{des.name}}
												</option>
											</select> -->
									</div>
								</div>
								<!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_department"></label>
										<select formControlName="departmentId" (change)="onChangeDepartment()">
											<option *ngFor="let des of commonService.commonData.departments"
												[value]="des.id" [default]="des.isDefault">{{des.name}} ({{des.managerEmail}})
											</option>
										</select>
									</div>
								</div> -->
								<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_practice"></label>
										<select formControlName="practiceId" (change)="onChangePractice()">
											<option value="" selected>Select</option>
											<option *ngFor="let p of pageData.practice" [value]="p.id"
												[default]="p.isDefault">
												{{p.name}}</option>
										</select>
									</div>
								</div>
								<!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_practice_lead"></label>
										<select formControlName="practiceLeadId" (change)="onChangePracticeLead()">
											<option value="" selected>Select</option>
											<option *ngFor="let pl of commonService.commonData.practiceLead"
												[value]="pl.id">{{pl.leadName}}</option>
										</select>
									</div>
								</div> -->
								<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_focus_cont_lead"></label>
										<!-- <span class="d-block">{{pageData.practiceLead}} ({{pageData.practiceLeadEmail}})</span>-->
										<select formControlName="practiceLeadId">
											<option value="null" selected>Select</option>
											<option *ngFor="let pl of pageData.practiceLead" [value]="pl.id">
												{{pl.firstName}} {{pl.lastName}}</option>
										</select> 
									</div>
								</div>
								<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_location"></label>
										<select formControlName="location">
											<option value="">Select</option>
											<option *ngFor="let c of commonService.commonData.location" [value]="c.id"
												[default]="c.isDefault">
												{{c.name}}</option>
										</select>
									</div>
								</div>
								<!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_level">Level</label>
										<select formControlName="levelId">
											<option *ngFor="let level of commonService.commonData.levels"
												[value]="level.id">{{level.levelName}}</option>
										</select>
									</div>
								</div> -->
								<!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_service_line"></label>
										<select formControlName="serviceLineId">
											<option *ngFor="let line of commonService.commonData.serviceLines"
												[value]="line.id">{{line.name}}</option>
										</select>
									</div>
								</div> -->
								<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_work_pattern"></label>
										<select formControlName="workPatternId">
											<option *ngFor="let pattern of commonService.commonData.workPatterns"
												[default]="pattern.isDefault" [value]="pattern.id">{{pattern.type}}
											</option>
										</select>
									</div>
								</div>
								<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_type"></label>
										<select formControlName="type">
											<option [value]="'Full Time'" [default]="true">Full Time</option>
											<option [value]="'Part Time'">Part Time</option>
										</select>
									</div>
								</div>
								<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_hire_on"></label>
										<input formControlName="hireOn" class="form-control" placeholder="{{commonService.dateFormat}}"
											ngbDatepicker #HireOn="ngbDatepicker" (click)="HireOn.toggle()">
									</div>
								</div>
								<div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_start"></label>
										<input formControlName="startTime" class="form-control" placeholder="{{commonService.dateFormat}}"
											ngbDatepicker #StartTime="ngbDatepicker" (click)="StartTime.toggle()">
									</div>
								</div>
								<!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
									<div class="form-group">
										<label for="" translate="hr_end"></label>
										<input formControlName="endTime" class="form-control" placeholder="{{commonService.dateFormat}}"
											ngbDatepicker #EndTime="ngbDatepicker" (click)="EndTime.toggle()">
									</div>
								</div> -->
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
									<button type="submit" class="btn btn-primary btn-lg next"
										translate="hr_save_next"></button>
									<button type="button" class="btn btn-primary btn-lg prev"
										(click)="goToStep(1)"><u>{{'hr_cancel' | translate}}</u></button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<!-- tab three -->
			<div class="tab-pane fade" [ngClass]="{'show active':step==3}">
				<div class="card b-radius p-4">
					<form [formGroup]="stepForm[3]" (ngSubmit)="saveNext(4)" *ngIf="step==3" class="row form mt-3">
						<div class="col-lg-12 col-md-12 col-sm-9">
							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
								<h1 class="headTitle" translate="hr_personal_details"></h1>
							</div>
							<div class="row col-sm-12">
								<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_address"></label>
										<input class="form-control" formControlName="address"
											placeholder="Enter the address...">
									</div>
								</div>
								<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_zip_code"></label>
										<input formControlName="zipCode" class="form-control" placeholder="00000">
									</div>
								</div>
								<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_country"></label>
										<select formControlName="countryId" (change)="getCity()">
											<option *ngFor="let c of commonService.commonData.countries" [value]="c.id"
												[default]="c.isDefault">
												{{c.name}}</option>
										</select>
									</div>
								</div>
								<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_city"></label>
										<input formControlName="cityName" class="form-control">
										<!-- <select formControlName="cityId">
		<option value="" selected>Select</option>
		<option *ngFor="let c of commonService.commonData.cities" [value]="c.id"
			[default]="c.isDefault">
			{{c.name}}</option>
	</select> -->
									</div>
								</div>

								<ng-container *ngIf="start">
									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_intro_date"></label>
											<input formControlName="introDate" class="form-control"
												placeholder="{{commonService.dateFormat}}" readonly ngbDatepicker
												#introDate="ngbDatepicker" (click)="introDate.toggle()">
										</div>
									</div>
									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_buddy"></label>
											<select formControlName="buddyId">
												<option [value]="emp.id" *ngFor="let emp of pageData.buddies"
													[default]="emp.isDefault">{{emp.firstName +' '+ emp.lastName}}</option>
											</select>
										</div>
									</div>
									<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_staffed_min"></label>
											<select formControlName="staffedMinimum">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.StaffedMinimum"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div> -->
									<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_hidden"></label>
											<select formControlName="hidden">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.Hidden"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div> -->
									<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_champagne"></label>
											<select formControlName="champagne">
												<option>Select</option>
											</select>
										</div>
									</div> -->
								</ng-container>
								<ng-container *ngIf="!start">
									<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_termination"></label>
											<select formControlName="termination">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.Termination"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div> -->
									<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_termination_achieved"></label>
											<select formControlName="terminationArchieved">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.TerminationArchieved"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div> -->
									<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_clause"></label>
											<select formControlName="clause">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.Clause"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_exit_interview"></label>
											<select formControlName="exitInterview">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.ExitInterview"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_hr_manager_access"></label>
											<select formControlName="hrManagerAccess">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.HRManagerAccess"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_linkedin_alumni"></label>
											<select formControlName="linkedinAlumni">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.LinkedinAlumni"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_hello_goodbye"></label>
											<select formControlName="helloGoodBye">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.HelloGoodBye"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div> -->
									<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
										<div class="form-group">
											<label for="" translate="hr_goodbye_email"></label>
											<select formControlName="goodByeEmail">
												<option [value]="val.value"
													*ngFor="let val of commonService.commonData.typeValues.GoodByeEmail"
													[default]="val.isDefault">{{val.value}}</option>
											</select>
										</div>
									</div>
								</ng-container>

								<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_phone"></label>
										<input formControlName="phone" class="form-control" placeholder="+00 0000 000">
									</div>
								</div>
								<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_gender"></label>
										<select formControlName="gender">
											<option value="Male">Male</option>
											<option value="Female">Female</option>
										</select>
									</div>
								</div>
								<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_marital_status"></label>
										<select formControlName="maritalStatus">
											<option value="Single">Single</option>
											<option value="Married">Married</option>
										</select>
									</div>
								</div>
								<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_ssn"></label>
										<input formControlName="ssn" class="form-control" placeholder="">
									</div>
								</div> -->
							</div>
							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
								<button type="submit" class="btn btn-primary btn-lg next"
									translate="hr_save_next"></button>
								<button type="button" class="btn btn-primary btn-lg prev"
									(click)="goToStep(2)"><u>{{'hr_cancel' | translate}}</u></button>
							</div>

						</div>
					</form>
				</div>
			</div>
			<!-- tab four -->
			<div class="tab-pane fade" [ngClass]="{'show active':step==4}">
				<div class="card b-radius p-4">
					<form [formGroup]="stepForm[4]" *ngIf="step==4" class="row form  mt-3">
						<div class="col-lg-12 col-md-12 col-sm-9">
							<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
								<h1 class="headTitle" translate="hr_emergency_contact"></h1>
							</div>
							<div class="row form m-1 mt-4 mb-4">
								<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_name"></label>
										<input formControlName="emergencyName" class="form-control">
									</div>
								</div>
								<div class="col-xl-3 col-lg-6 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_phone"></label>
										<input formControlName="emergencyPhoneNo" class="form-control">
									</div>
								</div>
								<div class="col-xl-3 col-lg-6 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_email"></label>
										<input formControlName="emergencyEmail" class="form-control">
									</div>
								</div>
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
									<div class="form-group">
										<label for="" translate="hr_additional_info"></label>
										<input formControlName="additionalInformation" class="form-control"
											placeholder="">
									</div>
								</div>
								<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
									<button type="submit" class="btn btn-primary btn-lg next" (click)="saveDetails()"
										translate="hr_save_close"></button>
									<button type="submit" class="btn btn-primary btn-lg next"
										(click)="saveDetails(true)" translate="hr_publish_it"></button>
									<button type="button" class="btn btn-primary btn-lg prev"
										(click)="goToStep(3)"><u>{{'hr_cancel' | translate}}</u></button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>
	</div>
</div>

<div class="tab-content" >
	<h3 translate="check_lists"></h3>
	<div class="tab-pane show active">
		<div class="card b-radius p-4">
			<div class="form pt-0">
				<app-check-list-hr [modalData]="userData" *ngIf="loadTaskComponent"></app-check-list-hr>
			</div>
		</div>
	</div>
</div>	