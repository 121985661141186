import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { AddEmployeeComponent } from './views/hr-list/add-employee/add-employee.component';
import { AssetsComponent } from './views/assets/assets.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { HrListComponent } from './views/hr-list/hr-list.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HrTileComponent } from './views/hr-tile/hr-tile.component';
import { ITComponent } from './views/it/it.component';
import { SharedMailboxComponent } from './views/shared-mailbox/shared-mailbox.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddAssetsComponent } from './views/assets/hardware/add-assets/add-assets.component';
import { AppInterceptor } from './app.interceptor';
import { AssetService } from './views/assets/asset.service';
import { AddMailboxComponent } from './views/shared-mailbox/add-mailbox/add-mailbox.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MailboxService } from './views/shared-mailbox/mailbox.service';
import { HrService } from './views/hr-list/hr.service';
import { ItService } from './views/it/it.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddItComponent } from './views/it/add-it/add-it.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { commonProviderFactory, CommonService, CustomLoader } from './common.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService } from './msal';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalInterceptorConfig } from './msal/msal.interceptor.config';
import { environment } from 'src/environments/environment';
import { ProductsComponent } from './views/assets/products/products.component';
import { HardwaretypesComponent } from './views/assets/hardwaretypes/hardwaretypes.component';
import { VendorsComponent } from './views/assets/vendors/vendors.component';
import { ResellersComponent } from './views/assets/resellers/resellers.component';
import { HardwareComponent } from './views/assets/hardware/hardware.component';
import { AddProductComponent } from './views/assets/products/add-product/add-product.component';
import { AddHardwareTypesComponent } from './views/assets/hardwaretypes/add-hardwaretypes/add-hardwaretypes.component';
import { AddResellerComponent } from './views/assets/resellers/add-reseller/add-reseller.component';
import { AddVendorComponent } from './views/assets/vendors/add-vendor/add-vendor.component';
import { NotFoundComponent } from './404/404.component';
import { SharedModule } from './views/shared/shared.module';
import { EmailtemplatesComponent } from './views/emailtemplates/emailtemplates.component';
import { AddtemplatesComponent } from './views/emailtemplates/addtemplates/addtemplates.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InfoComponent } from './views/it/info/info.component';
import { TaskDetailComponent } from './views/dashboard/task-detail/task-detail.component';
import { AddCommentComponent } from './views/dashboard/add-comment/add-comment.component';
import { LandingPageComponent } from './landing/landing-page.component';
import { CheckListHRComponent } from './views/hr-list/check-list-hr/check-list-hr.component';
import { ReportComponent } from './views/reports/report.component';
import { ReportDetailsComponent } from './views/reports/reportdetails.component';

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msClientId,
      redirectUri: environment.msRedirectionUrl
    }
  });
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com', ['user.read']);
  protectedResourceMap.set(environment.apiUrl, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AddEmployeeComponent,
    AddMailboxComponent,
    AssetsComponent,
    DashboardComponent,
    HrListComponent,
    HrTileComponent,
    ITComponent,
    SharedMailboxComponent,
    AddAssetsComponent,
    AddItComponent,
    ConfirmModalComponent,
    ProductsComponent,
    HardwaretypesComponent,
    VendorsComponent,
    ResellersComponent,
    HardwareComponent,
    AddProductComponent,
    AddHardwareTypesComponent,
    AddResellerComponent,
    AddVendorComponent,
    NotFoundComponent,
    EmailtemplatesComponent,
    AddtemplatesComponent,
    InfoComponent,
    TaskDetailComponent,
    AddCommentComponent,
    LandingPageComponent,
    CheckListHRComponent,
    ReportComponent,
    ReportDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    InfiniteScrollModule,
    AngularEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        //useFactory: HttpLoaderFactory,
        useClass: CustomLoader,
        deps: [HttpClient]
      },
      isolate: false
    }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    SharedModule.forRoot()
  ],
  providers: [
    AssetService,
    MailboxService,
    HrService,
    ItService,
    CommonService,
    {
      provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true
    },
    { provide: APP_INITIALIZER, useFactory: commonProviderFactory, deps: [CommonService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddAssetsComponent,
    ConfirmModalComponent,
    InfoComponent
  ],
  exports: []
})
export class AppModule { }
