import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddEmployeeComponent } from './views/hr-list/add-employee/add-employee.component';
import { AddMailboxComponent } from './views/shared-mailbox/add-mailbox/add-mailbox.component';
import { AssetsComponent } from './views/assets/assets.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { HrListComponent } from './views/hr-list/hr-list.component';
import { HrTileComponent } from './views/hr-tile/hr-tile.component';
import { ITComponent } from './views/it/it.component';
import { SharedMailboxComponent } from './views/shared-mailbox/shared-mailbox.component';
import { AddItComponent } from './views/it/add-it/add-it.component';
import { MsalGuard } from './msal';
import { AddtemplatesComponent } from './views/emailtemplates/addtemplates/addtemplates.component';
import { EmailtemplatesComponent } from './views/emailtemplates/emailtemplates.component';
import { ReportComponent } from './views/reports/report.component';

const routes: Routes = [
  {
    path: 'emp/:id',
    component: AddEmployeeComponent,
    canActivate: [MsalGuard],
    data: { parent: 'hr-list' }
  },
  {
    path: 'mailbox/:id',
    component: AddMailboxComponent,
    canActivate: [MsalGuard],
    data: { parent: 'shared-mailbox' }
  },
  {
    path: 'asset',
    component: AssetsComponent,
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'hr-list',
    component: HrListComponent,
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'hr-tile',
    component: HrTileComponent,
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'IT',
    component: ITComponent,
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'IT/:id',
    component: AddItComponent,
    canActivate: [MsalGuard],
    data: { parent: 'IT' }
  },
  {
    path: 'shared-mailbox',
    component: SharedMailboxComponent,
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'emailtemplate',
    component: EmailtemplatesComponent,
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'emailtemplate/:id',
    component: AddtemplatesComponent,
    canActivate: [MsalGuard],
    data: { parent: 'emailtemplate' }
  },
  {
    path: 'tasks',
    loadChildren: () => import('./views/tasks/tasks.module').then(m => m.TasksModule),
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'settings',
    loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'locale',
    loadChildren: () => import('./views/localization/localization.module').then(m => m.LocalizationModule),
    canActivate: [MsalGuard],
    data: { parent: null }
  },
  {
    path: 'reports', 
    component: ReportComponent,
    canActivate: [MsalGuard],
    data: { parent: 'dashboard' }
  },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
