import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetService } from '../../asset.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { environment } from 'src/environments/environment';
import { NgbdToDatePipe, DateToNgbdPipe } from 'src/app/views/shared/pipes/shared.pipes';

@Component({
  selector: 'app-add-assets',
  templateUrl: './add-assets.component.html',
  styleUrls: ['./add-assets.component.scss']
})
export class AddAssetsComponent implements OnInit {
  @Input() assetDetails: any = {};
  assetsForm: FormGroup;
  data: any = {};
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _service: AssetService,
    private _toastr: ToastrService,
    private ngbdToDatePipe: NgbdToDatePipe,
    private dateToNgbdPipe: DateToNgbdPipe,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    if (this.assetDetails.initialDate) {
      this.assetDetails.initialDate = this.dateToNgbdPipe.transform(this.assetDetails.initialDate);
    }
    let uploadImages;
    let empId;
    if (this.assetDetails.uploadImages && this.assetDetails.uploadImages.length) {
      uploadImages = this.assetDetails.uploadImages.map((item) => {
        return this.formBuilder.group({
          image: environment.apiUrl + item.image,
          imageName: item.imageName
        });
      })
    }
    if (this.assetDetails.employeeAssetsList && this.assetDetails.employeeAssetsList.length) {
      empId = this.assetDetails.employeeAssetsList[0].employeeId;
    }
    this.assetsForm = this.formBuilder.group({
      name: [this.assetDetails.name || null, Validators.required],
      modelName: [this.assetDetails.modelName || null, Validators.required],
      serialNo: [this.assetDetails.serialNo || null, Validators.required],
      status: [this.assetDetails.status || null, Validators.required],
      productId: [this.assetDetails.productId || null, Validators.required],
      resellerId: [this.assetDetails.resellerId || null, Validators.required],
      initialDate: [this.assetDetails.initialDate || null, Validators.required],
      employeeId: [empId || null],
      comments: [this.assetDetails.comments || null, Validators.required],
      uploadImages: new FormArray(uploadImages || [])
    });

    this.getProducts();
    this.getReseller();
    this.getEmployees();
  }
  saveAsset() {
    if(this.assetsForm.invalid) return;
    var _self = this;
    var req = {
      ..._self.assetsForm.getRawValue()
    };
    if (_self.assetDetails.id) {
      req.id = _self.assetDetails.id;
    }
    req.initialDate = _self.ngbdToDatePipe.transform(req.initialDate);
      _self._service.addAsset(req).subscribe((res: any) => {
        _self._toastr.success(_self.assetDetails.id ? 'asset updated successfully' : 'asset added successfully');
        _self.activeModal.close(true);
      })
  }

  getProducts() {
    this._service.getProducts('', true).subscribe((res: any) => {
      this.data.products = res;
    });
  }
  getReseller() {
    this._service.getResellers('', true).subscribe((res: any) => {
      this.data.resellers = res;
    });
  }
  getEmployees() {
    this._service.getEmployees('').subscribe((res: any) => {
      this.data.employees = res;
      console.log(this.assetDetails.employeeId);
      console.log(res.find((r)=>r.id == this.assetDetails.employeeId));
    });
  }
  savePhoto(event) {
    var _self = this;
    var file = event.target.files[0];
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i; 
    if(!allowedExtensions.exec(file.name)) {
      _self._toastr.error("only images are allowed to attach!!");
      return;
    }
    var form = _self.assetsForm.get('uploadImages') as FormArray;
    _self.commonService.imgToBase64(file).then(url => {
      var formGroup = _self.formBuilder.group({
        image: url,
        imageName: file.name
      });
      form.push(formGroup);
    })
  }
  removeImg(i) {
    var form = this.assetsForm.get('uploadImages') as FormArray;
    form.removeAt(i);
  }
}
