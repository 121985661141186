<div class="row form m-1 mt-3">
    <div class="row form m-4">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <h1 class="headTitle">{{'description' | translate}}</h1>
            <span class="d-block">
                {{checklist.taskListDescription || checklist.description}}
            </span>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="" translate="commments"></label>
                <textarea class="form-control" tabindex="6" [(ngModel)]="checklist.comments" placeholder=""
                    style="height: 230px;"></textarea>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <button type="button" (click)="activeModal.dismiss()" class="btn btn-primary btn-lg next"
                translate="it_save"></button>
            <button type="button" class="btn btn-primary btn-lg prev" (click)="activeModal.dismiss()"><u>{{'it_cancel' |
                    translate}}</u></button>
        </div>
    </div>
</div>