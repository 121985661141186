import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomHttpParams } from 'src/app/common.service';

@Injectable({
  providedIn: 'root'
})
export class MailboxService {

  constructor(
    private http: HttpClient
  ) { }
  saveMail(data) {
    return this.http.post(`api/MailBox/AddUpdateMailBox`, data);
  }
  getMail(req,loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/MailBox/GetAll${req}`, httpOptions);
  }
  deleteEmp(id) {
    return this.http.delete(`api/MailBox/DeleteById/${id}`);
  }
  getMailById(req) {
    return this.http.get(`api/MailBox/GetById${req}`);
  }
}
