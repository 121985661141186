<h1 class="headTitle" translate="assets"></h1>
<!-- filter -->
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row">
		<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 filter">
			<div class="row">
				<div class="col-xl-6 col-lg-12 col-md-6 col-sm-4">
					<div class="input-group searchBox">
						<input class="form-control" placeholder="Search by keyword" (keyup)="asset.onSearchTxt()"
							[(ngModel)]="filters.searchText">
						<span class="input-group-addon"><i class="fa fa-search"></i></span>
					</div>
				</div>
				<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4">
					<select [(ngModel)]="filters.category">
						<option value="asset_hardware">{{'asset_hardware' | translate}}</option>
						<option value="asset_products">{{'asset_products' | translate}}</option>
						<option value="asset_hardware_types">{{'asset_hardware_types' | translate}}</option>
						<option value="asset_vendors">{{'asset_vendors' | translate}}</option>
						<option value="asset_resellers">{{'asset_resellers' | translate}}</option>
					</select>
				</div>
			</div>
		</div>
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-right">
			<button type="button" class="btn btn-primary btn-lg" (click)="asset.addAssets()">Add
				{{filters.category | translate}}</button>
		</div>
	</div>
</div>
<ng-container *ngIf="filters.category == 'asset_hardware'">
	<app-hardware [filters]="filters" #asset></app-hardware>
</ng-container>
<ng-container *ngIf="filters.category == 'asset_products'">
	<app-products [filters]="filters" #asset></app-products>
</ng-container>
<ng-container *ngIf="filters.category == 'asset_hardware_types'">
	<app-hardwaretypes [filters]="filters" #asset></app-hardwaretypes>
</ng-container>
<ng-container *ngIf="filters.category == 'asset_vendors'">
	<app-vendors [filters]="filters" #asset></app-vendors>
</ng-container>
<ng-container *ngIf="filters.category == 'asset_resellers'">
	<app-resellers [filters]="filters" #asset></app-resellers>
</ng-container>