<h1 class="headTitle" translate="it"></h1>
<!-- filter -->
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row">
		<div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 filter">
			<div class="row">
				<div class="col-xl-3 col-lg-12 col-md-6 col-sm-4">
					<div class="input-group searchBox">
						<input class="form-control" placeholder="Search by name or email" (keyup)="onSearchTxt()"
							[(ngModel)]="filters.searchText">
						<span class="input-group-addon"><i class="fa fa-search"></i></span>
					</div>
				</div>
				<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4" *ngIf="filters.start">
					<input class="form-control" placeholder="Start Date"
						(ngModelChange)="onChangeDate($event,'startDate')" [(ngModel)]="filters.startDate" ngbDatepicker
						#startDate="ngbDatepicker" (click)="startDate.toggle()">
				</div>
				<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4" *ngIf="!filters.start">
					<input class="form-control" placeholder="End Date" (ngModelChange)="onChangeDate($event,'endDate')"
						[(ngModel)]="filters.endDate" ngbDatepicker #endDate="ngbDatepicker" (click)="endDate.toggle()">
				</div>
				<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4">
					<select [(ngModel)]="filters.isActive" (change)="resetDetails()">
						<option value="" [default]="true">Select</option>
						<option value="0">New</option>
						<option value="1">Active</option>
						<option value="2">Disabled Users</option>
					</select>
				</div>
				<div class="col-xl-3 col-lg-6 col-md-3 col-sm-4">
					<div class="row ml-3 align-items-center">
						<label>STOP</label>
						<label class="switch">
							<input type="checkbox" [(ngModel)]="filters.start" (ngModelChange)="resetDetails()">
							<span class="slider round" [ngClass]="{'off':!filters.start}"></span>
						</label>
						<label>START</label>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 text-right">
			<button type="button" class="btn btn-primary btn-lg" *ngIf="!commonService.userRole.Backoffice"
				routerLink='/IT/new' translate="it_add_ext_user"></button>
		</div>
	</div>
</div>
<!-- listing -->
<div class="hr-listing it-listing">
	<div class="card b-radius" *ngFor="let emp of employees">
		<div class="itlist-item">
			<!-- <div class="cursor-pointer user-img-block">
				<div class="image">
					<img [src]="emp.profileImage" onerror="this.src='assets/images/default-img.png'"
						class="img-circle elevation-4" alt="User">
				</div>
				<div class="greenFont mFont lightFont">{{emp.employeeId}}</div>
			</div>
			<div class="it-list-em-name">
				<div class=" mFont cursor-pointer" routerLink="/IT/{{emp.id}}">
					{{emp.firstName + ' ' + emp.lastName}}</div>
			</div>
			<div class="it-list-email">
				<a class="greenFont lightFont">{{getInitialExt(emp)}}</a>
				<div>{{emp.mobileNo}}</div>
			</div>
			<div class="start">
				<div class="greenFont lightFont">Start</div>
				<div>{{(emp.itStartDate || emp.startTime) | date: commonService.dateFormat}}</div>
			</div> -->
			<div class="cursor-pointer user-img-block">
				<div class="image">
					<img [src]="emp.profileImage" onerror="this.src='assets/images/default-img.png'"
						class="img-circle elevation-4" alt="User">
				</div>
				<div routerLink="/IT/{{emp.id}}" [attr.aria-expanded]="!emp.isExpended"
					class="cursor-pointer position-post" aria-controls="collapseExample">
					<div class=" mFont">{{emp.firstName + ' '+ emp.lastName}}</div>
					<div><span>{{emp.practice || emp.jobPosition}}</span></div>
				</div>
			</div>

			<div class="user-mobile">
				<div class="greenFont lightFont">{{'dashboard_mobile' | translate}}</div>
				<div>{{emp.mobileNo}}</div>
			</div>
			<div class="user-department">
				<div class="greenFont lightFont">{{'dashboard_department' | translate}}</div>
				<div>{{emp.department}}</div>
			</div>
			<div class="user-post">
				<div class="greenFont lightFont">{{'hr_manager' | translate}}</div>
				<div>{{emp.managerName || emp.practiceLead}}</div>
			</div>
			<div class="user-select-date">
				<div *ngIf="emp.startStopStatus==='Start'">
					<span class="greenFont lightFont">{{'dashboard_start_date' | translate}}</span>
					<span>{{(emp.itStartDate || emp.startTime) | date: commonService.dateFormat}}</span>
				</div>
				<div *ngIf="emp.startStopStatus==='Stop'">
					<span class="greenFont lightFont">{{'hr_it_termination_date' | translate}}</span>
					<span>{{(emp.itTerminationDate) | date: commonService.dateFormat}}</span>
				</div>
				<!-- <div *ngIf="emp.startStopStatus==='Stop'">
					<span class="greenFont lightFont">{{'hr_last_payment_of_salary' | translate}}</span>
					<span>{{(emp.lastPaymentSalaryDate) | date: commonService.dateFormat}}</span>
				</div> -->
				<div><span class="greenFont lightFont">{{'hr_tasks' | translate}}</span>
					<span translate="tasks_completed" *ngIf="(emp.totalCompletedTask != emp.totalTask) else completed"
						[translateParams]="{completed:emp.totalCompletedTask, total:emp.totalTask}"></span>
					<ng-template #completed>
						<span translate="tasks_all_completed"></span>
					</ng-template>
				</div>
			</div>
			<!-- <div class="equipment">
				<div class="greenFont lightFont">Equipment</div>
				<div>{{emp.equipmentReady == 'Yes' ? 'Ready' : 'Not Ready'}}</div>
			</div> -->
			<!-- <div class="greenFont lightFont mFont" *ngIf="emp.isActive !== false">{{emp.hrStatus || 'New'}}</div> -->
			<div class="greenFont lightFont mFont" *ngIf="emp.isActive===false">Disabled</div>
			<div class="greenFont lightFont mFont" *ngIf="emp.isActive!==false">{{emp.itStatus || 'New'}}</div>
			<div class="user-new">
				<div class="dropdown action">
					<a class="nav-link pt-0" data-toggle="dropdown" href="javascript:void(0);" aria-expanded="true">
						<i class="fa fa-ellipsis-h"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
						<a href="javascript:void(0);" class="dropdown-item" (click)="showInfo(emp)"><i
								class="fa fa-exclamation-circle mr-2"></i>
							{{'it_info' | translate}}</a>
						<a href="javascript:void(0);" class="dropdown-item" *ngIf="!commonService.userRole.Backoffice"
							(click)="resendEmail(emp)"><i class="fa fa-envelope mr-2"></i>{{'it_resend' | translate}}
						</a>
						<a href="javascript:void(0);" class="dropdown-item" *ngIf="!commonService.userRole.Backoffice"
							(click)="addComment(emp)"><i class="fa fa-comment mr-2"></i>{{'it_comment' | translate}}
						</a>
						<a href="javascript:void(0);" class="dropdown-item" *ngIf="!commonService.userRole.Backoffice"
							(click)="disableUser(emp)"><i class="fa fa-minus-circle mr-2"></i>{{'it_Close' |
							translate}}</a>
						<a href="javascript:void(0);" class="dropdown-item" *ngIf="!commonService.userRole.Backoffice"
							(click)="enableUser(emp)"><i class="fa fa-repeat mr-2"></i>{{'it_reopen' | translate}}</a>
						<a href="javascript:void(0);" (click)="showChecklist(emp)" class="dropdown-item"><i
								class="fa fa-check mr-2"></i> {{'it_tasklist' | translate}}</a>
						<a href="javascript:void(0);" class="dropdown-item" *ngIf="!commonService.userRole.Backoffice"
							(click)="deleteEmp(emp)"><i class="fa fa-times mr-2"></i>{{'it_delete' | translate}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<ngb-pagination [(page)]="pageNo" [pageSize]="pageSize" [collectionSize]="totalCount"
		(pageChange)="onScroll($event)"></ngb-pagination>
</div>