import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/common.service';
import { environment } from 'src/environments/environment';
import { InfoComponent } from '../it/info/info.component';
import { CheckListComponent } from '../shared/components/check-list/check-list.component';
import { NgbdToDatePipe, DateToNgbdPipe } from '../shared/pipes/shared.pipes';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  pending: any = {
    employees: [],
    sbs: null,
    pageNo: 1,
    pageSize: 10,
    filters: {},
    totalCount: 0
  };
  termination: any = {
    employees: [],
    sbs: null,
    pageNo: 1,
    pageSize: 10,
    filters: {},
    totalCount: 0
  };
  pendingTask: any = {
    employees: [],
    sbs: null,
    pageNo: 1,
    pageSize: 20,
    filters: {}
  };
  terminationTask: any = {
    employees: [],
    sbs: null,
    pageNo: 1,
    pageSize: 20,
    filters: {}
  };
  constructor(
    private _toastr: ToastrService,
    public commonService: CommonService,
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    private ngbToDatePipe: NgbdToDatePipe,
    private dateToNgbPipe: DateToNgbdPipe
  ) { }

  ngOnInit() {
    this.getPending();
    this.getTermination();
    this.getpendigTotalCount();
    this.getTerminationTotalCount();
    //this.getPendingTask();
    //this.getTerminationTask();
  }
  getPending(loader: boolean = true) {
    var req = `?pageNo=${this.pending.pageNo}&pageSize=${this.pending.pageSize}&isStart=true`;
    if (this.pending.filters.searchText) {
      req += '&searchText=' + this.pending.filters.searchText;
    }
    if (this.pending.sbs) this.pending.sbs.unsubscribe();
    this.pending.sbs = this.dashboardService.getPending(req, loader).subscribe((res: any) => {
      this.pending.employees = res.map((emp) => {
        if (emp.profileImage && !emp.profileImage.toString().includes('http')) {
          emp.profileImage = environment.apiUrl + emp.profileImage;
        }
        return emp;
      });
    });
  }
  getpendigTotalCount() {
    var req = `?isStart=true`;
    if (this.pending.filters.searchText) {
      req += '&searchText=' + this.pending.filters.searchText;
    }
    this.dashboardService.getTotalCount(req).subscribe((res: any) => {
      this.pending.totalCount = res;
    });
  }
  getTermination(loader: boolean = true) {
    var req = `?pageNo=${this.termination.pageNo}&pageSize=${this.termination.pageSize}&isStart=false`;
    if (this.termination.filters.searchText) {
      req += '&searchText=' + this.termination.filters.searchText;
    }
    if (this.termination.sbs) this.termination.sbs.unsubscribe();
    this.termination.sbs = this.dashboardService.getPending(req, loader).subscribe((res: any) => {
      this.termination.employees = res.map((emp) => {
        if (emp.profileImage && !emp.profileImage.toString().includes('http')) {
          emp.profileImage = environment.apiUrl + emp.profileImage;
        }
        return emp;
      });
    });
  }
  getTerminationTotalCount() {
    var req = `?isStart=false`;
    if (this.termination.filters.searchText) {
      req += '&searchText=' + this.termination.filters.searchText;
    }
    this.dashboardService.getTotalCount(req).subscribe((res: any) => {
      this.termination.totalCount = res;
    });
  }
  getPendingTask(loader: boolean = true) {
    var req = `?pageNo=${this.pendingTask.pageNo}&pageSize=${this.pendingTask.pageSize}`;
    if (this.pendingTask.filters.searchText) {
      req += '&searchText=' + this.pendingTask.filters.searchText;
    }
    if (this.pendingTask.sbs) this.pendingTask.sbs.unsubscribe();
    this.pendingTask.sbs = this.dashboardService.getPendingTask(req, loader).subscribe((res: any) => {
      this.pendingTask.employees = this.pendingTask.employees.concat(res).map((emp) => {
        if (emp.profileImage && !emp.profileImage.toString().includes('http')) {
          emp.profileImage = environment.apiUrl + emp.profileImage;
        }
        return emp;
      });
    });
  }
  getTerminationTask(loader: boolean = true) {
    var req = `?pageNo=${this.terminationTask.pageNo}&pageSize=${this.terminationTask.pageSize}`;
    if (this.terminationTask.filters.searchText) {
      req += '&searchText=' + this.terminationTask.filters.searchText;
    }
    if (this.terminationTask.sbs) this.terminationTask.sbs.unsubscribe();
    this.terminationTask.sbs = this.dashboardService.getTerminatedTask(req, loader).subscribe((res: any) => {
      this.terminationTask.employees = this.terminationTask.employees.concat(res).map((emp) => {
        if (emp.profileImage && !emp.profileImage.toString().includes('http')) {
          emp.profileImage = environment.apiUrl + emp.profileImage;
        }
        return emp;
      });
    });
  }

  // Pending
  onPendingScroll(event) {
    if (this.pending.employees && this.pending.employees.length) {
      this.pending.pageNo = event;
      this.getPending();
    }
  }
  resetPendingDetails() {
    this.pending.employees = [];
    this.pending.pageNo = 1;
    this.getPending();
    this.getpendigTotalCount();
  }
  onPendingSearchTxt() {
    this.pending.employees = [];
    this.pending.pageNo = 1;
    this.getPending(false);
    this.getpendigTotalCount();
  }
  onPendingTaskScroll() {
    if (this.pendingTask.employees && this.pending.employees.length) {
      this.pendingTask.pageNo++;
      this.getPendingTask();
    }
  }
  resetPendingTaskDetails() {
    this.pendingTask.employees = [];
    this.pendingTask.pageNo = 1;
    this.getPendingTask();
  }
  onPendingTaskSearchTxt() {
    this.pendingTask.employees = [];
    this.pendingTask.pageNo = 1;
    this.getPendingTask(false);
  }

  // Termination
  onTerminationScroll(event) {
    if (this.termination.employees && this.termination.employees.length) {
      this.termination.pageNo = event;
      this.getTermination();
      this.getpendigTotalCount();
    }
  }
  resetTerminationDetails() {
    this.termination.employees = [];
    this.termination.pageNo = 1;
    this.getTermination();
    this.getpendigTotalCount();
  }
  onTerminationSearchTxt() {
    this.termination.employees = [];
    this.termination.pageNo = 1;
    this.getTermination(false);
    this.getpendigTotalCount();
  }
  onTerminationTaskScroll() {
    if (this.terminationTask.employees && this.terminationTask.employees.length) {
      this.terminationTask.pageNo++;
      this.getTerminationTask();
    }
  }
  resetTerminationTaskDetails() {
    this.terminationTask.employees = [];
    this.terminationTask.pageNo = 1;
    this.getTerminationTask();
  }
  onTerminationTaskSearchTxt() {
    this.terminationTask.employees = [];
    this.terminationTask.pageNo = 1;
    this.getTerminationTask(false);
  }

  showPendingTaskChecklist(emp) {
    const modalRef = this.modalService.open(CheckListComponent, { size: 'xl' });
    modalRef.componentInstance.modalData = emp;
    modalRef.componentInstance.isHR = true;
    modalRef.result.then(() => {
      this.resetPendingDetails();
    }).catch(e => {
    });
  }

  showTerminationTaskChecklist(emp) {
    const modalRef = this.modalService.open(CheckListComponent, { size: 'xl' });
    modalRef.componentInstance.modalData = emp;
    modalRef.componentInstance.isHR = true;
    modalRef.result.then(() => {
      this.resetTerminationTaskDetails();
    }).catch(e => {
    });
  }
  showChecklist(emp) {
    const modalRef = this.modalService.open(CheckListComponent, { size: 'xl' });
    modalRef.componentInstance.modalData = emp;
    modalRef.componentInstance.isHR = true;
    modalRef.result.then(() => {
      // this.resetDetails();
    }).catch(e => {
    });
  }
  showTasks(emp): void {
    if (emp.isExpended) {
      emp.isExpended = false;
      return;
    }
    let _self = this;
    var req = '?employeeId=' + emp.id +
      '&type=' + emp.startStopStatus;
      if(emp.practiceId) {
        req = req + '&practiceId=' + emp.practiceId;
      }
      if(emp.departmentId) {
        req = req + '&departmentId=' + emp.departmentId;
      }
    this.dashboardService.getCheckList(req).subscribe(
      (res: any) => {
        emp.isExpended = true;
        emp.checklists = res.map((r) => {
          r.employeeId = emp.id;
          r.completed = (r.statusId == this.commonService.commonData.taskListStatus.find((c) => { return c.name.toString().toLowerCase().includes('completed') }).id);
          r.editable = false;
          if(!r.deadLine) {
            if (emp.startStopStatus == 'Start') {
              r.deadLine = _self.dateToNgbPipe.transform(emp.itStartDate);
            } else {
              r.deadLine = _self.dateToNgbPipe.transform(emp.itEndDate);
            }
          } else {
            r.deadLine = _self.dateToNgbPipe.transform(r.deadLine);
          }
          return r;
        })
      },
      (error:any)=>{
        emp.isExpended = true;
        emp.checklists = [];
      }
    )
  }
}
