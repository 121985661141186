import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetService } from '../../asset.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-hardwaretypes',
  templateUrl: './add-hardwaretypes.component.html',
  styleUrls: ['./add-hardwaretypes.component.scss']
})
export class AddHardwareTypesComponent implements OnInit {
  @Input() assetDetails: any = {};
  assetsForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _service: AssetService,
    private _toastr: ToastrService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    let subTypes = [];
    if (this.assetDetails.subTypes) {
      subTypes = this.assetDetails.subTypes.map((item) => {
        return this.formBuilder.group({
          type: item.type,
          isActive: true,
          isDeleted: false,
          id: item.id
        });
      })
    } else {
      subTypes.push(this.formBuilder.group({
        type: '',
        isActive: true,
        isDeleted: false,
        id: null
      }));
    }
    this.assetsForm = this.formBuilder.group({
      type: [this.assetDetails.type || null, Validators.required],
      view: [this.assetDetails.view || null, Validators.required],
      subTypes: new FormArray(subTypes)
    });
  }
  addSubtype(subtype) {
    this.modalService.open(subtype)
  }
  saveAsset() {
    if (this.assetsForm.invalid) return;
    var _self = this;
    var req = {
      ..._self.assetsForm.getRawValue()
    };
    if (_self.assetDetails.id) {
      req.id = _self.assetDetails.id;
    }
    req.isParent = false;
    req.parentId = null;
    req.isDeleted = false,
      _self._service.addAssetsType(req).subscribe((res: any) => {
        _self._toastr.success(_self.assetDetails.id ? 'asset updated successfully' : 'asset added successfully');
        _self.activeModal.close(true);
      })
  }
  removeSubtypelist(i) {
    var form = this.assetsForm.get('subTypes') as FormArray;
    form.removeAt(i);
  }
  addSubtypelist() {
    var _self = this;
    var form = _self.assetsForm.get('subTypes') as FormArray;
    var formGroup = _self.formBuilder.group({
      type: '',
      isActive: true,
      isDeleted: false,
      id: null
    });
    form.push(formGroup);
  }
}
