<h1 class="headTitle" translate>dashboard</h1>
<div class="card p-4 b-radius-bl b-radius-br mb-4">
	<div class="row justify-content-between">
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-lg-offset-4">
			<h1 class="mb-1" translate="dashboard_on_boarding"></h1>
		</div>
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filter">
			<div class="row">
				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
					<div class="input-group searchBox">
						<input class="form-control" placeholder="Search by name or email" (keyup)="onPendingSearchTxt()"
							[(ngModel)]="pending.filters.searchText">
						<span class="input-group-addon"><i class="fa fa-search"></i></span>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>
<div class="dashboard-listing">
	<div class="card b-radius" *ngFor="let emp of pending.employees">
		<div class="table-content dashlist-item d-flex justify-content-start align-items-center">
			<div class="cursor-pointer user-img-block">
				<div class="image">
					<img [src]="emp.profileImage" onerror="this.src='assets/images/default-img.png'"
						class="img-circle elevation-4" alt="User">
				</div>
				<div (click)="showTasks(emp);" [attr.aria-expanded]="!emp.isExpended"
					class="cursor-pointer position-post" aria-controls="collapseExample">
					<div class=" mFont">{{emp.firstName + ' '+ emp.lastName}}</div>
					<div><span>{{emp.practice || emp.jobPosition}}</span></div>
					<div><a style="color: rgb(10, 10, 102);text-decoration:underline;font-style: italic;">{{!emp.isExpended? 'Show Checklist': 'Hide Checklist'}}</a></div>
				</div>
			</div>

			<div class="user-mobile">
				<div class="greenFont lightFont">{{'dashboard_mobile' | translate}}</div>
				<div>{{emp.mobileNo}}</div>
			</div>
			<div class="user-department">
				<div class="greenFont lightFont">{{'dashboard_department' | translate}}</div>
				<div>{{emp.department}}</div>
			</div>
			<div class="user-post">
				<div class="greenFont lightFont">{{'dashboard_manager' | translate}}</div>
				<div>{{emp.managerName || emp.practiceLead}}</div>
			</div>
			<div class="user-select-date">
				<div><span class="greenFont lightFont">{{'dashboard_start_date' | translate}}</span>
					<span>{{emp.startTime | date:commonService.dateFormat}}</span>
				</div>
				<div><span class="greenFont lightFont">{{'dashboard_checklist' | translate}}</span>
					<span translate="tasks_completed" *ngIf="(emp.totalCompletedTask != emp.totalTask) else completed"
						[translateParams]="{completed:emp.totalCompletedTask, total:emp.totalTask}"></span>
					<ng-template #completed>
						<span translate="tasks_all_completed"></span>
					</ng-template>
				</div>
			</div>
			<div class="user-new">
				<div class="yellowFont lightFont mFont">{{emp.itStatus || 'New'}}</div>
				<div class="dropdown action">
					<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
						<i class="fa fa-ellipsis-h"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
						<a href="javascript:void(0);" class="dropdown-item" routerLink="/IT/{{emp.id}}"><i
								class="fa fa-pencil mr-2"></i>
							{{'dashboard_edit' | translate}}</a>
						
						<a href="javascript:void(0);" (click)="showChecklist(emp)" class="dropdown-item"><i
							class="fa fa-check mr-2"></i> {{'hr_checklist' | translate}}</a>
					</div>
				</div>
			</div>
		</div>
		<div class="dashlist-task" [ngbCollapse]="!emp.isExpended">
			<div class="form pt-0">
				<app-task-detail [emp]="emp"></app-task-detail>
			</div>
		</div>
	</div>
</div>
<ngb-pagination [(page)]="pending.pageNo" [pageSize]="pending.pageSize" [collectionSize]="pending.totalCount"
	(pageChange)="onPendingScroll($event)"></ngb-pagination>
<!-- <div class="col-lg-6 col-md-6 col-sm-6">
	<div class="listing assets-listing my-5">
		<div class="card b-radius">
			<div class="d-flex justify-content-end align-items-end m-4">
				<div class="w-50">
					<h2 translate="dashboard_on_boarding_task"></h2>
				</div>
				<div class="w-50 filter">
					<div class="input-group searchBox">
						<input class="form-control" placeholder="Search by name or email"
							(keyup)="onPendingTaskSearchTxt()" [(ngModel)]="pendingTask.filters.searchText">
						<span class="input-group-addon"><i class="fa fa-search"></i></span>
					</div>
				</div>
			</div>
			<table class="fixed-header">
				<thead>
					<th translate></th>
					<th translate="dashboard_full_name"></th>
					<th translate="dashboard_job_position"></th>
					<th translate="dashboard_start_date"></th>
					<th translate></th>
				</thead>
				<tbody infiniteScroll (scrolled)="onPendingTaskScroll()" [scrollWindow]="false">
					<tr *ngFor="let emp of pendingTask.employees">
						<td>
							<div class="dashboard-image"><img [src]="emp.profileImage"
									onerror="this.src='assets/images/default-img.png'"
									class="img-circle elevation-4" alt="User">
							</div>
						</td>
						<td routerLink="/IT/{{emp.id}}">{{emp.firstName + ' '+ emp.lastName}}</td>
						<td>{{emp.JobPosition}}</td>
						<td>{{emp.startTime | date:commonService.dateFormat}}</td>
						<td>
							<div class="dropdown action d-inline-block">
								<a class="nav-link p-0" data-toggle="dropdown" href="#" aria-expanded="true">
									<i class="fa fa-ellipsis-h"></i>
								</a>
								<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
									<a href="javascript:void(0);" class="dropdown-item"
										routerLink="/IT/{{emp.id}}"><i class="fa fa-pencil mr-2"></i>
										{{'dashboard_edit' | translate}}</a>
									<a href="javascript:void(0);" (click)="showPendingTaskChecklist(emp)"
									class="dropdown-item"><i class="fa fa-check mr-2"></i>
									{{'dashboard_checklist' | translate}}</a>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div> -->
<div class="card p-4 b-radius-bl b-radius-br mb-4 mt-4">
	<div class="row justify-content-between">
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-lg-offset-4">
			<h1 class="mb-1" translate="dashboard_off_boarding"></h1>
		</div>
		<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filter">
			<div class="row">
				<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
					<div class="input-group searchBox">
						<input class="form-control" placeholder="Search by name or email"
							(keyup)="onTerminationSearchTxt()" [(ngModel)]="termination.filters.searchText">
						<span class="input-group-addon"><i class="fa fa-search"></i></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="dashboard-listing">
	<div class="card b-radius" *ngFor="let emp of termination.employees">
		<div class="table-content dashlist-item d-flex justify-content-start align-items-center">
			<div class="cursor-pointer user-img-block">
				<div class="image"><img [src]="emp.profileImage"
					onerror="this.src='assets/images/default-img.png'" class="img-circle elevation-4"
					alt="User">
				</div>
				<div (click)="showTasks(emp);" [attr.aria-expanded]="!emp.isExpended" class="cursor-pointer"
				aria-controls="collapseExample">
					<div class=" mFont">{{emp.firstName + ' '+ emp.lastName}}</div>
					<div><span>{{emp.practice || emp.jobPosition}}</span></div>
					<div><a style="color: rgb(10, 10, 102);text-decoration:underline;font-style: italic;">{{!emp.isExpended? 'Show Checklist': 'Hide Checklist'}}</a></div>
				</div>
			</div>
			<div class="user-mobile">
				<div class="greenFont lightFont">{{'dashboard_mobile' | translate}}</div>
				<div>{{emp.mobileNo}}</div>
			</div>
			<div class="user-department">
				<div class="greenFont lightFont">{{'dashboard_department' | translate}}</div>
				<div>{{emp.department}}</div>
			</div>
			<div class="user-post">
				<div class="greenFont lightFont">{{'dashboard_manager' | translate}}</div>
				<div>{{emp.managerName || emp.practiceLead}}</div>
			</div>
			<div class="user-select-date">
				<!-- <div>
							<span class="greenFont lightFont">{{'dashboard_stop_date' | translate}}</span>
							<span>{{emp.endTime | date:commonService.dateFormat}}</span>
				</div> -->
				<div>
					<span class="greenFont lightFont">{{'hr_it_termination_date' | translate}}</span>
					<span>{{(emp.itTerminationDate) | date: commonService.dateFormat}}</span>
				</div>
				<div><span class="greenFont lightFont">{{'dashboard_checklist' | translate}}</span>
					<span translate="tasks_completed"
						*ngIf="(emp.totalCompletedTask != emp.totalTask) else completed"
						[translateParams]="{completed:emp.totalCompletedTask, total:emp.totalTask}"></span>
					<ng-template #completed>
						<span translate="tasks_all_completed"></span>
					</ng-template>
				</div>
			</div>
			<div class="user-new">
				<div class="yellowFont lightFont mFont">{{emp.itStatus || 'New'}}</div>
				<div class="dropdown action">
					<a class="nav-link pt-0" data-toggle="dropdown" href="#" aria-expanded="true">
						<i class="fa fa-ellipsis-h"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
						<a href="javascript:void(0);" class="dropdown-item" routerLink="/IT/{{emp.id}}"><i
								class="fa fa-pencil mr-2"></i>
							{{'dashboard_edit' | translate}}</a>
						
						<a href="javascript:void(0);" (click)="showChecklist(emp)" class="dropdown-item"><i
							class="fa fa-check mr-2"></i> {{'hr_checklist' | translate}}</a>
					</div>
				</div>
			</div>
		</div>
		<div class="dashlist-task" [ngbCollapse]="!emp.isExpended">
			<div class="form pt-0">
				<app-task-detail [emp]="emp"></app-task-detail>
			</div>
		</div>	
	</div>
</div>
<ngb-pagination [(page)]="termination.pageNo" [pageSize]="termination.pageSize"
	[collectionSize]="termination.totalCount" (pageChange)="onTerminationScroll($event)"></ngb-pagination>
