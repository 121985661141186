import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Pipe({ name: 'dateToNgbd' })
export class DateToNgbdPipe implements PipeTransform {
  transform(value: any): NgbDate {
    if (!value) return null;
    value = new Date(value);
    return new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
  }
}

@Pipe({ name: 'ngbdToDate' })
export class NgbdToDatePipe implements PipeTransform {
  transform(value: NgbDate): string {
    if (!value) return null;
    return moment([value.year, value.month - 1, value.day]).local().format("yyyy-MM-DDThh:mm:ss");
  }
}

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = 'DD-MM-yyyy';
  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      let mdt = moment(value, this.DT_FORMAT)
    }
    return null;
  }
  format(date: NgbDateStruct): string {
    if (!date) return '';
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.DT_FORMAT);
  }
}