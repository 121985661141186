import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  filters: any = {};
  @ViewChild('asset') asset:any;
  constructor(
    public commonService: CommonService
  ) {
    this.filters.category = "asset_hardware";
   }

  ngOnInit(): void {
  }
}
