import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomHttpParams } from 'src/app/common.service';


@Injectable({
  providedIn: 'root'
})
export class AssetService {
  constructor(
    private http: HttpClient
  ) { }
  //assets 
  addAsset=(data)=> {
    return this.http.post(`api/Assets/AddUpdateAssets`, data);
  }
  getAssets(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Assets/GetAll${req}`, httpOptions);
  }
  getAssetCount(req) {
    return this.http.get(`api/Assets/GetAllCount${req}`);
  }
  getAssetById(req) {
    return this.http.get(`api/Assets/GetById${req}`);
  }
  deleteAsset(id) {
    return this.http.delete(`api/Assets/DeleteById/${id}`);
  }

  //Product
  addProduct(data) {
    return this.http.post(`api/Product/AddUpdateProduct`, data);
  }
  getProducts(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Product/GetAll${req}`, httpOptions);
  }
  getProductCount(req) {
    return this.http.get(`api/Product/GetAllCount${req}`);
  }
  getProductById(req) {
    return this.http.get(`api/Product/GetById${req}`);
  }
  deleteProduct(id) {
    return this.http.delete(`api/Product/DeleteById/${id}`);
  }

  //Reseller
  addReseller(data) {
    return this.http.post(`api/Reseller/AddUpdateReseller`, data);
  }
  getResellers(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Reseller/GetAll${req}`, httpOptions);
  }
  getResellerCount(req) {
    return this.http.get(`api/Reseller/GetAllCount${req}`);
  }
  getResellerById(req) {
    return this.http.get(`api/Reseller/GetById${req}`);
  }
  deleteReseller(id) {
    return this.http.delete(`api/Reseller/DeleteById/${id}`);
  }

  //Vendor
  addVendor(data) {
    return this.http.post(`api/Vendor/AddUpdateVendor`, data);
  }
  getVendors(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Vendor/GetAll${req}`, httpOptions);
  }
  getVendorCount(req) {
    return this.http.get(`api/Vendor/GetAllCount${req}`);
  }
  getVendorById(req) {
    return this.http.get(`api/Vendor/GetById${req}`);
  }
  deleteVendor(id) {
    return this.http.delete(`api/Vendor/DeleteById/${id}`);
  }

  //AssetsType
  addAssetsType(data) {
    return this.http.post(`api/AssetsType/AddUpdateAssetsType`, data);
  }
  getAssetsTypes(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/AssetsType/GetAll${req}`, httpOptions);
  }
  getAssetsTypeCount(req) {
    return this.http.get(`api/AssetsType/GetAllCount${req}`);
  }
  getAssetsTypeById(req) {
    return this.http.get(`api/AssetsType/GetById${req}`);
  }
  deleteAssetsType(id) {
    return this.http.delete(`api/AssetsType/DeleteById/${id}`);
  }

  //Employees
  getEmployees(req) {
    return this.http.get(`api/Employee/GetAllForDropdown`);
  }

  //Employees
  getSubType(id) {
    return this.http.get(`api/Dropdown/GetSubTypesByTypeId?typeId=${id}`);
  }
}
