import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/common.service';
import { MsalService } from 'src/app/msal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  imageToShow: any;
  isImageLoading: boolean;
  username: string;
  @Output("toggleNavbar") toggleNavbar: EventEmitter<any> = new EventEmitter();
  constructor(
    public commonService:CommonService,
    public authService:MsalService
  ) { }

  ngOnInit(): void {
  }

  CallToggleNavbar(){
    this.toggleNavbar.emit();
  }
}
