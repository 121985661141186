// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//For Production Use this
//  export const environment = {
//    production: false,
//    apiUrl: 'https://localhost:44317/',
//    msClientId: '737ebf22-48c3-415f-90d3-6ab93ed50cd7',
//    //msRedirectionUrl: 'https://onboarding.kereby.dk/'
//    msRedirectionUrl: 'http://localhost:4200/'
//  };

//Production
 //export const environment = {
  //production: false,
  //apiUrl: 'https://kereby-api-onboarding.azurewebsites.net/',
  //msClientId: '737ebf22-48c3-415f-90d3-6ab93ed50cd7',
  //msRedirectionUrl: 'https://onboarding.kereby.dk/'
//};

//var environment = {
//  production: false,
//  apiUrl: 'https://kereby-api-onboarding.azurewebsites.net/',
//  msClientId: '737ebf22-48c3-415f-90d3-6ab93ed50cd7',
//  msRedirectionUrl: 'https://onboarding.kereby.dk/'
//};
export const  environment = {
  //production: false,
  production: true,
  //apiUrl: 'https://localhost:44317/',
  //apiUrl: 'https://kereby-api-test-onboarding.azurewebsites.net/',
  apiUrl: 'https://kereby-api-onboarding.azurewebsites.net/',
  msClientId: '737ebf22-48c3-415f-90d3-6ab93ed50cd7',
  //msRedirectionUrl: 'http://localhost:4200/'
  //msRedirectionUrl: 'https://kereby-onboarding-test.azurewebsites.net/'
  msRedirectionUrl: 'https://onboarding.kereby.dk/'
  
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
