import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomHttpParams } from 'src/app/common.service';


@Injectable({
  providedIn: 'root'
})
export class HrService {
  constructor(
    private http: HttpClient
  ) { }
  addEmployee(data) {
    return this.http.post(`api/Employee/AddUpdateEmployee`, data);
  }
  getAll(req, loader) {
    const httpOptions = {
      params: new CustomHttpParams(loader)
    };
    return this.http.get(`api/Employee/GetAll${req}`, httpOptions);
  }
  GetAllForDropdown() {
    return this.http.get(`api/Employee/GetAllForDropdown`);
  }
  GetAllCount(req) {
    const httpOptions = {
      params: new CustomHttpParams(false)
    };
    return this.http.get(`api/Employee/GetAllCount${req}`,httpOptions);
  }
  getEmpById(req) {
    return this.http.get(`api/Employee/GetById/${req}`);
  }

  getEmpByDeptId(req) {
    return this.http.get(`api/Employee/GetEmployeeByDept/${req}`);
  }
  deleteEmp(id) {
    return this.http.delete(`api/Employee/DeleteById/${id}`);
  }
  getPracticeLead(req) {
    return this.http.get(`api/Dropdown/GetPracticeLeadByPracticeId${req}`);
  }
  GetPracticeLeadByDepartmentId(req) {
    return this.http.get(`api/Dropdown/GetPracticeLeadByDepartmentId${req}`);
  }
  GetPracticeByDepartmentId(req) {
    return this.http.get(`api/Dropdown/GetPracticeByDepartmentId${req}`);
  }
  GetPractice(req) {
    return this.http.get(`api/Dropdown/GetPractice`);
  }
  GetPracticeWithManager(req){
    return this.http.get(`api/Dropdown/GetPracticeWithManager`);
  }
  getFCLead(req) {
    return this.http.get(`api/Dropdown/GetFocusContLeadByPracticeLeadId${req}`);
  }
  getCities(req) {
    return this.http.get(`api/Dropdown/GetCityByCountryId${req}`);
  }
}
