<div class="it-info-popup row form m-1 mt-3">
    <div class="col-sm-12">
        <h3 ><span translate="it_user_info"></span> - {{fullName}}</h3>
       
      
    </div>
    <div class="col-sm-12">
        <div class=" row">
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_accountExpirationDate"></label>
                <span>{{info.accountExpirationDate | date:commonService.dateFormat}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_accountLockoutTime"></label>
                    <span>{{info.accountLockoutTime}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_displayName"></label>
                    <span>{{info.displayName}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_email"></label>
                    <span>{{info.email}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_firstname"></label>
                    <span>{{info.firstname}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_Initials"></label>
                    <span>{{info.initials}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_lastLogon"></label>
                <span>{{info.lastLogon | date:commonService.dateFormat}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_lastname"></label>
                    <span>{{info.lastname}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_title"></label>
                    <span>{{info.title}}</span>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="" translate="it_userPrincipalName"></label>
                    <span>{{info.userPrincipalName}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mt-3">
        <button type="button" class="btn btn-primary btn-lg prev" (click)="activeModal.dismiss()"><u>{{'it_cancel' | translate}}</u></button>
    </div>
</div>