import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { InfoComponent } from '../../it/info/info.component';
import { NgbdToDatePipe, DateToNgbdPipe } from '../../shared/pipes/shared.pipes';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {
  @Input() emp;
  constructor(
    private _toastr: ToastrService,
    public commonService: CommonService,
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    private ngbToDatePipe: NgbdToDatePipe,
    private dateToNgbPipe: DateToNgbdPipe
  ) { }

  ngOnInit(): void {
  }
  setCompleted(event, checklist) {
    if (event) {
      checklist.statusId = this.commonService.commonData.taskListStatus.find((c) => { return c.name.toString().toLowerCase().includes('completed') }).id;
    }
  }
  addNewTask(emp) {
    emp.checklists.push({
      editable: true,
      employeeId: emp.id
    });
  }

  CheckStatus(checklist) {
    checklist.completed = (checklist.statusId == this.commonService.commonData.taskListStatus.find((c) => { return c.name.toString().toLowerCase().includes('completed') }).id);
  }
  saveTask(emp) {
    var req = [
      ...emp.checklists.map(r => {
        r.deadLine = this.ngbToDatePipe.transform(r.deadLine);
        return r;
      })
    ]
    this.dashboardService.saveCheckList(req).subscribe((res: any) => {
      emp.isExpended = false;
      window.location.reload();
    })
  }
  getStatusName(statusId) {
    return this.commonService.commonData.taskListStatus.find((r)=>{
      return r.id == statusId;
    }).name;
  }
  addComment(checklist) {
    const modalRef = this.modalService.open(AddCommentComponent);
    modalRef.componentInstance.checklist = checklist;
  }
  deleteCheckList(checklist) {
    this.emp.checklists.splice(this.emp.checklists.indexOf(checklist),1)
  }
}
