import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { EmailTemplateService } from 'src/app/views/emailtemplates/emailtemplate.service';
@Component({
  selector: 'app-emailtemplates',
  templateUrl: './emailtemplates.component.html',
  styleUrls: ['./emailtemplates.component.scss']
})
export class EmailtemplatesComponent implements OnInit {
  emailtemplates:any=[];
  filters: any = {};
  sbs: any = null;
  pageNo: number = 1;
  pageSize: number = 20;
  constructor(
    private emailTemplateService: EmailTemplateService,
    private modalService: NgbModal,
    private _toastr: ToastrService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.resetDetails();
  }

  getAll(loader: boolean = true){
    var req = `?pageNo=${this.pageNo}&pageSize=${this.pageSize}`;
    if (this.filters.searchText) {
      req += '&searchText=' + this.filters.searchText;
    }
    if (this.sbs) this.sbs.unsubscribe();
    this.sbs = this.emailTemplateService.getAll(req,loader).subscribe((res:any)=>{
      this.emailtemplates = this.emailtemplates.concat(res);
    });
  }
  delete(mail) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.modalData = {
      heading: 'Confirm to delete',
      description: 'Do you want to delete template?'
    };
    modalRef.result.then(() => {
      this.emailTemplateService.Delete(mail.id).subscribe((res: any) => {
        this.resetDetails();
        this._toastr.success('template deleted successfully');
      })
    }).catch(e => {
    });
  }
  onScroll() {
    if (this.emailtemplates && this.emailtemplates.length) {
      this.pageNo++;
      this.getAll();
    }
  }
  onSearchTxt() {
    this.emailtemplates = [];
    this.pageNo=1;
    this.getAll(false);
  }
  resetDetails() {
    this.emailtemplates = [];
    this.pageNo=1;
    this.getAll();
  }
}