import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { HrService } from '../../hr-list/hr.service';
import { MailboxService } from '../mailbox.service';

@Component({
  selector: 'app-add-mailbox',
  templateUrl: './add-mailbox.component.html',
  styleUrls: ['./add-mailbox.component.scss']
})
export class AddMailboxComponent implements OnInit {
  mailForm: FormGroup;
  id: any = '';
  constructor(
    private mailService: MailboxService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private _router: Router) {
    var id = this.route.snapshot.paramMap.get('id');
    this.setForm();
    if (id && id != 'new') {
      this.id = id;
      this.mailService.getMailById(`/${this.id}`).subscribe((res: any) => {
        this.setForm(res);
      });
    }
  }
  setForm(data: any = {}) {
    this.mailForm = this.formBuilder.group({
      mailbox: [data.mailbox || null, Validators.required],
      extensionId: [data.extensionId || null, Validators.required],
      status: [data.status || null, Validators.required]
    });
  }

  ngOnInit(): void {
  }
  saveMail() {
    var _self = this;
    var req = {
      ..._self.mailForm.getRawValue()
    };
    if(_self.id) {
      req.id = _self.id;
    }
    _self.mailService.saveMail(req).subscribe((res: any) => {
      _self.toastr.success('Mailbox added successfully');
      _self._router.navigate(['/shared-mailbox']);
    })
  }
}
