import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { AddCommentComponent } from '../shared/components/add-comment/add-comment.component';
import { CheckListComponent } from '../shared/components/check-list/check-list.component';
import { NgbdToDatePipe } from '../shared/pipes/shared.pipes';
import { InfoComponent } from './info/info.component';
import { ItService } from './it.service';

@Component({
  selector: 'app-it',
  templateUrl: './it.component.html',
  styleUrls: ['./it.component.scss']
})
export class ITComponent implements OnInit {
  employees: any = [];
  filters: any = {};
  sbs: any = null;
  countSbs: any = null;
  pageNo: number = 1;
  pageSize: number = 10;
  totalCount: number = 0;
  constructor(
    private itService: ItService,
    private modalService: NgbModal,
    private _toastr: ToastrService,
    public commonService: CommonService,
    private ngbdToDatePipe: NgbdToDatePipe,
  ) { }

  ngOnInit(): void {
    this.filters.start = true;
    this.resetDetails();
    this.getTotalCount();
  }
  getEmployes(loader: boolean = true) {
    var req = `?type=IT&pageNo=${this.pageNo}&pageSize=${this.pageSize}&startstopStatus=${!!this.filters.start ?'Start' : 'Stop'}`;
    if (this.filters.searchText) {
      req += '&searchText=' + this.filters.searchText;
    }
    if (this.filters.startDate && this.filters.start) {
      req += '&startDate=' + this.ngbdToDatePipe.transform(this.filters.startDate);//new Date(this.filters.startDate.year, this.filters.startDate.month - 1, this.filters.startDate.day).toISOString();
    }
    if (this.filters.endDate && !this.filters.start) {
      req += '&endDate=' + this.ngbdToDatePipe.transform(this.filters.endDate);//new Date(this.filters.endDate.year, this.filters.endDate.month - 1, this.filters.endDate.day).toISOString();
    }
    if (this.filters.isActive) {
      req += '&isActive=' + this.filters.isActive;
    }
    if (this.sbs) this.sbs.unsubscribe();
    this.sbs = this.itService.getAll(req, loader).subscribe((res: any) => {
      this.employees = res.map((emp) => {
        if (emp.profileImage && !emp.profileImage.toString().includes('http')) {
          emp.profileImage = environment.apiUrl + emp.profileImage;
        }
        return emp;
      });
    })
  }
  getTotalCount() {
    var req = `?type=IT&startstopStatus=${!!this.filters.start ?'Start' : 'Stop'}`;
    if (this.filters.searchText) {
      req += '&searchText=' + this.filters.searchText;
    }
    if (this.filters.startDate && this.filters.start) {
      req += '&startDate=' + this.ngbdToDatePipe.transform(this.filters.startDate);//new Date(this.filters.startDate.year, this.filters.startDate.month - 1, this.filters.startDate.day).toISOString();
    }
    if (this.filters.endDate && !this.filters.start) {
      req += '&endDate=' + this.ngbdToDatePipe.transform(this.filters.endDate);//new Date(this.filters.endDate.year, this.filters.endDate.month - 1, this.filters.endDate.day).toISOString();
    }
    if (this.filters.isActive) {
      req += '&isActive=' + this.filters.isActive;
    }
    if (this.countSbs) this.countSbs.unsubscribe();
    this.countSbs = this.itService.getAllCount(req).subscribe((res: any) => {
      this.totalCount = res;
    })
  }
  deleteEmp(emp) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.modalData = {
      heading: 'Confirm to delete',
      description: 'Do you want to delete user?'
    };
    modalRef.result.then(() => {
      this.itService.deleteEmp(emp.id).subscribe((res: any) => {
        this.resetDetails();
        this._toastr.success('user deleted successfully');
      })
    }).catch(e => {
    });
  }
  showChecklist(emp) {
    const modalRef = this.modalService.open(CheckListComponent, { size: 'xl' });
    modalRef.componentInstance.modalData = emp;
    modalRef.componentInstance.isHR = false;
    modalRef.result.then(() => {
      this.resetDetails();
    }).catch(e => {
    });
  }
  onScroll(event) {
    if (this.employees && this.employees.length) {
      this.pageNo = event;
      this.getEmployes();
    }
  }
  onSearchTxt() {
    this.employees = [];
    this.pageNo = 1;
    this.getEmployes(false);
    this.getTotalCount();
  }
  resetDetails() {
    this.employees = [];
    this.pageNo = 1;
    this.getEmployes();
    this.getTotalCount();
  }
  onChangeDate(val, model) {
    this.filters[model] = val;
    this.resetDetails();
  }
  resendEmail(emp) {
    this.itService.resendEmail(emp.id, 'WelcomeEmail').subscribe(
      (res: any) => {
        this._toastr.success('Email sent successfully');
      })
  }
  disableUser(emp) {
    this.itService.disableUser(emp.initials || null).subscribe(
      (res: any) => {
        this.itService.removeUserFromGroup(emp.initials).subscribe((resp: any) => {
          this._toastr.success('Employee disabled successfully');
        })
      }
    )
  }
  enableUser(emp) {
    this.itService.enableUser(emp.initials || null).subscribe(
      (res: any) => {
        this._toastr.success('Employee enabled successfully');
      }
    )
  }
  getInitialExt(emp) {
    if(!emp.initials || !emp.extensionId) {
      return '';
    }else {
      var extension = this.commonService.commonData.mailExtensions.find((ext)=>{
        return ext.id == emp.extensionId;
      })
      return (emp.initials + '@' + extension.name);
    }
  }
  showInfo(emp) {
    const modalRef = this.modalService.open(InfoComponent,{size: 'lg'});
    modalRef.componentInstance.modalData = emp;
  }
  addComment(emp) {
    const modalRef = this.modalService.open(AddCommentComponent);
    modalRef.componentInstance.data = {
      modalData:emp,
      api:this.itService.updatePartial,
      commentObj:'comment'
    }
    modalRef.result.then((result) => {
      if (result) {
        this.resetDetails();
      }
    }).catch(e => {
    });
  }
}
