import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/common.service';
import { NgbdToDatePipe, DateToNgbdPipe } from '../../pipes/shared.pipes';
import { SharedService } from '../../services/shared.service';
@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss']
})
export class CheckListComponent implements OnInit {
  @Input() modalData: any = {};
  @Input() isHR: boolean;
  checklists: any = [];
  constructor(
    private service: SharedService,
    public activeModal: NgbActiveModal,
    private config: NgbModalConfig,
    public commonService: CommonService,
    private ngbToDatePipe: NgbdToDatePipe,
    private dateToNgbPipe: DateToNgbdPipe
  ) {
    this.config.size = 'lg';
  }

  ngOnInit(): void {
    let _self = this;
    var req = '?employeeId=' + _self.modalData.id +
      '&type=' + _self.modalData.startStopStatus;
      if(_self.modalData.practiceId) {
        req = req + '&practiceId=' + _self.modalData.practiceId;
      }
      if(_self.modalData.departmentId) {
        req = req + '&departmentId=' + _self.modalData.departmentId;
      }
    _self.service.getCheckList(req).subscribe(
      (res: any) => {
        _self.checklists = res.map((r) => {
          r.employeeId = _self.modalData.id;
          r.completed = (r.statusId == this.commonService.commonData.taskListStatus.find((c) => { return c.name.toString().toLowerCase().includes('completed') }).id);
          r.editable = false;//!(r.responsible == 'HR' && !_self.isHR) || (r.responsible == 'IT' && _self.isHR);
          if (!r.deadLine) {
            if (_self.modalData.startStopStatus == 'Start') {
              r.deadLine = _self.dateToNgbPipe.transform(_self.modalData.itStartDate);
            } else {
              r.deadLine = _self.dateToNgbPipe.transform(_self.modalData.itEndDate);
            }
          } else {
            r.deadLine = _self.dateToNgbPipe.transform(r.deadLine);
          }
          return r;
        })
      }
    )
  }
  getStatusName(statusId) {
    return this.commonService.commonData.taskListStatus.find((r) => {
      return r.id == statusId;
    }).name;
  }
  addNewTask() {
    this.checklists.push({
      editable: true,
      employeeId: this.modalData.id
    });
  }
  setCompleted(event, checklist) {
    if (event) {
      checklist.statusId = this.commonService.commonData.taskListStatus.find((c) => { return c.name.toString().toLowerCase().includes('completed') }).id;
    }
  }
  CheckStatus(checklist) {
    checklist.completed = (checklist.statusId == this.commonService.commonData.taskListStatus.find((c) => { return c.name.toString().toLowerCase().includes('completed') }).id);
  }
  save() {
    var req = [
      ...this.checklists.map(r => {
        r.deadLine = this.ngbToDatePipe.transform(r.deadLine);
        return r;
      })
    ]
    this.service.saveCheckList(req).subscribe((res: any) => {
      this.activeModal.close();
    })
  }
  deleteCheckList(checklist) {
    this.checklists.splice(this.checklists.indexOf(checklist), 1)
  }
}
