import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { MsalService } from './msal';
import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs/operators';
import { Location } from "@angular/common";
import { CanActivate, NavigationEnd, Router } from '@angular/router';
import { Roles, Permissions } from './permission.config';
const GRAPH_ENDPOINT = `https://graph.microsoft.com/v1.0/me/photo/$value`;

//if we need orderBy on displayName also, then we use this url
//https://graph.microsoft.com/v1.0/groups?$count=true&$filter=mailEnabled eq false&securityEnabled eq true&$orderby=displayName


const ALL_GROUP_ENDPOINT = `https://graph.microsoft.com/v1.0/groups?$count=true&$filter=mailEnabled eq false and securityEnabled eq true and onPremisesSyncEnabled eq true&$orderby=displayName`;
const ME_GRAPH_ENDPOINT = `https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true`;
const headers = new HttpHeaders()
  .set('content-type', 'application/json; odata.metadata=minimal; odata.streaming=true; IEEE754Compatible=false; charset=utf-8')
  .set('ConsistencyLevel', 'eventual');

enum Responsible {
  HR = 'HR',
  IT = 'IT',
  Backoffice = 'Backoffice'
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  commonData: any = {
    typeValues: {}
  };
  dateFormat: string = "dd-MM-yyyy";
  profile: any = {};
  allGroups:any = [];
  userRole: Roles = {
    Admin: false,
    IT: false,
    HR: false,
    Backoffice: false
  };
  navigations: any = {};
  languages: any = [];
  public _responsibleEnum = Responsible;
  $roleResolve: Subject<any> = new Subject();
  $updateDefault: Subject<any> = new Subject();
  $commonDataResolve: Promise<any>;
  constructor(
    private http: HttpClient,
    public translate: TranslateService,
    private authService: MsalService,
    private router: Router,
    private location: Location
  ) {
    this.authService.authResolve.subscribe(() => {
      setTimeout(() => {
        this.checkRouteGuards();
      }, 200);
      if(!!sessionStorage.getItem('userRole') && !!sessionStorage.getItem('profile')) {
        this.userRole = JSON.parse(sessionStorage.getItem('userRole'));
        this.profile = JSON.parse(sessionStorage.getItem('profile'));
      } else {
        this.resolveProfile();
      }
    })
  }
  getCommonDetails() {
    return new Promise((resolve, reject) => {
      this.getAllDropdowns();
      this.http.get('api/Translations/GetAllLanguages').subscribe((lngs: any) => {
        this.languages = lngs;
        this.translate.addLangs(lngs.map((lng) => { return lng.id.toString() }));
        this.translate.setDefaultLang(lngs[0].id);
        this.translate.use(lngs[0].id);
        resolve(true);
      })
    })
  }
  getAllDropdowns() {
    this.http.get(`api/Dropdown/GetAll`).subscribe((res: any) => {
      this.commonData = res;
      let typeValues = {};
      this.commonData.dropdownTypeValues.forEach(element => {
        typeValues[element.dropdownTypeName] = element.optionValue;
        return element;
      });
      this.commonData.typeValues = typeValues;
      delete this.commonData.dropdownTypeValues;
      this.$commonDataResolve = new Promise((resolve) => resolve(null));
    })
  }
  updateLanguage(lng) {
    this.translate.use(lng.id);
  }
  resolveProfile() {
    if (!Object.keys(this.profile).length) {
      this.http.get(GRAPH_ENDPOINT, { responseType: 'blob' }).subscribe((img) => {
        this.imgToBase64(img).then((imgUrl) => {
          this.profile.imgUrl = imgUrl;
          this.profile.name = this.authService.getAllAccounts()[0].name;
        });
      });
      this.FetchMeGroups().then(() => {
        this.makeMenuFunctional();
      });
      this.FetchAllGroups().then();
    }
  }
  FetchMeGroups(): Promise<any> {
    var _self = this;
    return new Promise((resolve, reject) => {
      _self.http.get(ME_GRAPH_ENDPOINT, { 'headers': headers }).subscribe((body: any) => {
        _self.profile.groups = body.value;
        if (body['@odata.nextLink']) {
          _self.fetchChildGroups(body, resolve,false);
        } else {
          resolve(null);
        }
      });
    })
  }
  FetchAllGroups() {
    var _self = this;
    return new Promise((resolve, reject) => {
      _self.http.get(ALL_GROUP_ENDPOINT, { 'headers': headers }).subscribe((body: any) => {
        _self.allGroups = body.value;
        _self.profile.groups = body.value;
        if (body['@odata.nextLink']) {
          _self.fetchChildGroups(body, resolve,true);
        } else {
          resolve(null);
        }
      });
    })
  }
  fetchChildGroups=(groupRes, resolve,isAll)=> {
    var _self = this;
    this.http.get(groupRes['@odata.nextLink'], { 'headers': headers }).subscribe((body: any) => {
      groupRes = body;
      if(isAll) this.allGroups = this.allGroups.concat(body.value);
      else this.profile.groups = this.profile.groups.concat(body.value);
      if (!groupRes['@odata.nextLink']) {
        resolve();
      } else {
        this.fetchChildGroups(groupRes, resolve,isAll);
      }
    },
      (error: any) => {
        console.log(error);
      })
  }
  makeMenuFunctional() {
    if (!!this.profile.groups.find(x => x.displayName === 'Onboarding_IT')) {
      this.userRole.IT = true;
    } if (!!this.profile.groups.find(x => x.displayName === 'Onboarding_HR')) {
      this.userRole.HR = true;
    } if (!!this.profile.groups.find(x => x.displayName === 'Onboarding_Admin')) {
      this.userRole.Admin = true;
    } if (!!this.profile.groups.find(x => x.displayName === 'Onboarding_BackOffice')) {
      this.userRole.Backoffice = true;
    }
    // this.userRole = {
    //   IT: false,
    //   Admin: false,
    //   Backoffice: true,
    //   HR: false
    // };
    if (this.router.url == "/dashboard") {
      if (this.userRole.HR) {
        this.router.navigate(['/hr-list']);
      } else if (this.userRole.IT || this.userRole.Backoffice) {
        this.router.navigate(['/IT']);
      }
    }
    sessionStorage.setItem('userRole',JSON.stringify(this.userRole));
    sessionStorage.setItem('profile',JSON.stringify(this.profile));
    this.$roleResolve.next();
  }
  getPhoto(): Observable<Blob> {
    // this.authService.acquireTokenPopup({
    //   scopes: this.authService.getScopesForEndpoint(GRAPH_ENDPOINT)
    // });
    return this.http.get(GRAPH_ENDPOINT, { responseType: 'blob' });
  }
  getRole(): Observable<Blob> {
    // this.authService.acquireTokenPopup({
    //   scopes: this.authService.getScopesForEndpoint(GRAPH_ENDPOINT)
    // });
    return this.http.get(ME_GRAPH_ENDPOINT, { responseType: 'blob' });
  }
  haveRoutePermission(_route) {
    let _userAllowed = false;
    for (var key in this.userRole) {
      if (this.userRole[key] && Permissions[key].Pages.find((page) => { return page.route == _route })
        && Permissions[key].Pages.find((page) => { return page.route == _route }).navigation) {
        _userAllowed = true
      }
    }
    return _userAllowed;
  }

  checkRouteGuards() {
    console.log(this.location.path());
    let path = '';
    this.router.config.some((config) => {
      console.log(config.children);
      var locPath = this.location.path().replace(/[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i, ':id').replace('/', '').replace('/new','/:id');
      if (config.path == locPath) {
        path = config.data.parent || locPath;
        return true;
      } if (locPath && locPath.split('/')[0] == 'settings' && config.path == 'settings') {
        path = 'settings';
        return true;
      }
    });
    if (!this.authService.getAllAccounts().length) {
      return this.router.navigate(['/dashboard']);
    } else if (path == '/') {
      return;
    } else if (this.haveRoutePermission(path.replace('/', ''))) {
      return;
    } else {
      return this.router.navigate(['/dashboard']);
    }
  }
  imgToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  isChecklistDisable(checklist) {
    if (
      this.userRole.Admin ||
      (this.userRole.HR && checklist.responsible == this._responsibleEnum.HR) ||
      (this.userRole.IT && checklist.responsible == this._responsibleEnum.IT) ||
      (this.userRole.Backoffice && checklist.responsible == this._responsibleEnum.Backoffice)) {
      return false;
    }
    return true;
  }
}

export function commonProviderFactory(provider: CommonService) {
  return () => provider.getCommonDetails();
}

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }
  public getTranslation(lang: String): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/Translations/GetTranslationsByLanguageId/' + lang).pipe(
      map(
        (res: any) => {
          var obj = {};
          res.forEach((val) => {
            obj[val.propertyName] = val.propertyValue;
          })
          return obj;
        }
      )
    )
  }
}

export class CustomHttpParams extends HttpParams {
  constructor(public loader: boolean) {
    super();
  }
}
