import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'underscore';

@Component({
  selector: 'multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent implements OnChanges {
  @Input('data') data: any;
  @Input('selected') selected: any;
  checkboxSelected: any = {};
  selectedValues = null;
  filteredList = [];
  searchTxt = '';
  constructor() {
    this.selected = this.selected || [];
    this.setSelected();
  }
  ngOnChanges(changes) {
    var _self = this;
    if (changes.selected && changes.selected.currentValue && changes.selected.currentValue.length) {
      _.each(changes.selected.currentValue, (id) => {
        _self.checkboxSelected[id] = true;
      })
      setTimeout(() => {
        this.setSelected();
      }, 500);
    }
  }

  triggerSelected(event, id) {
    if (event) {
      this.selected.push(id);
    } else {
      this.selected.splice(this.selected.indexOf(id), 1);
    }
    this.setSelected();
  }
  onSearch(event) {
    this.filteredList = this.data.filter((dt: any) => {
      return dt.displayName.toLowerCase().includes(this.searchTxt.toLowerCase());
    })
  }
  reset() {
    this.filteredList = this.data;
    this.searchTxt = '';
  }
  setSelected() {
    if (this.selected && this.selected.length && this.data && this.data.length) {
      let str = this.data.filter((dt: any) => {
        return this.selected.includes(dt.id);
      });
      if (str && str.length) {
        this.selectedValues = str;
      } else {
        this.selectedValues = null;
      }
    } else {
      this.selectedValues = null;
    }
  }
}
