<form [formGroup]="commentForm" (ngSubmit)="saveComment()" class="row form m-1 mt-3">
    <div class="row form m-4">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <h1 class="headTitle">{{'add_comment' | translate}}</h1>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <label for="" translate="commments"></label>
                <textarea class="form-control" tabindex="6" formControlName="comments" placeholder=""
                    style="height: 230px;"></textarea>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <button type="submit" class="btn btn-primary btn-lg next" translate="save"></button>
            <button type="button" class="btn btn-primary btn-lg prev"
                (click)="activeModal.dismiss()"><u>{{'cancel' | translate}}</u></button>
        </div>
    </div>
</form>