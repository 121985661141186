export interface Roles {
    Admin: boolean;
    IT: boolean;
    HR: boolean;
    Backoffice: boolean;
}
export const Permissions = {
    Admin: {
        Pages: [
            {
                route: 'dashboard',
                navigation: true
            },
            {
                route: 'hr-list',
                navigation: true
            },
            {
                route: 'IT',
                navigation: true
            },
            {
                route: 'asset',
                navigation: true
            },
            {
                route: 'tasks',
                navigation: true
            },
            {
                route: 'emailtemplate',
                navigation: true
            },
            {
                route: 'locale',
                navigation: true
            },
            {
                route: 'settings',
                navigation: true
            },
            {
                route: 'reports',
                navigation: true
            }
        ],
    },
    IT: {
        Pages: [
            {
                route: 'dashboard',
                navigation: true
            },
            {
                route: 'hr-list',
                navigation: false
            },
            {
                route: 'IT',
                navigation: true
            },
            {
                route: 'asset',
                navigation: false
            },
            {
                route: 'tasks',
                navigation: false
            },
            {
                route: 'emailtemplate',
                navigation: false
            },
            {
                route: 'locale',
                navigation: false
            }
        ],
    },
    HR: {
        Pages: [
            {
                route: 'dashboard',
                navigation: true
            },
            {
                route: 'hr-list',
                navigation: true
            },
            {
                route: 'IT',
                navigation: false
            },
            {
                route: 'asset',
                navigation: false
            },
            {
                route: 'tasks',
                navigation: false
            },
            {
                route: 'emailtemplate',
                navigation: false
            },
            {
                route: 'locale',
                navigation: false
            }
        ],
    },
    Backoffice: {
        Pages: [
            {
                route: 'dashboard',
                navigation: false
            },
            {
                route: 'hr-list',
                navigation: false
            },
            {
                route: 'IT',
                navigation: true
            },
            {
                route: 'asset',
                navigation: false
            },
            {
                route: 'tasks',
                navigation: false
            },
            {
                route: 'emailtemplate',
                navigation: false
            },
            {
                route: 'locale',
                navigation: false
            }
        ],
    }
}