import { AfterViewInit, Directive, ElementRef, OnChanges, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/common.service';
import * as _ from 'underscore';
@Directive({
    selector: 'form'
})
export class disableFormDirective implements AfterViewInit,OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    constructor(
        private host: ElementRef<HTMLFormElement>,
        private commonService: CommonService
    ) {
        this.commonService.$roleResolve.
            pipe(
                takeUntil(this.destroyed$)).
            subscribe(() => {
                this.disableForm();
            })
    }
    disableForm() {
        if (this.commonService.userRole.Backoffice) {
            _.each(this.host.nativeElement.elements, (element: HTMLFormElement) => {
                if (element.nodeName == 'INPUT' || element.nodeName == 'SELECT' || (element.nodeName == 'BUTTON' && element.type == 'submit')) {
                    element.disabled = true;
                }
            })
        }
    }
    ngAfterViewInit() {
        this.disableForm();
    }
    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}