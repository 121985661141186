<div id="wrap" *ngIf="loggedIn">
  <app-header  (toggleNavbar)="toggleNavbar()" ></app-header>
  <div class="container content-container">
    <div class="d-flex flexSec">
      <div class="col-md-2 leftPanel" id="navbarContent"  [ngClass]="{ 'showLeftPanel': navbarOpen }">
        <div class="card p-2 b-radius ">
          <!-- <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
          </button> -->
          <div class="collapse navbar-collapse"  [ngClass]="{ 'show': navbarOpen }">
            <ul class="navbar-nav ">
              <li class="nav-item"><a class="nav-link" *ngIf="commonService.haveRoutePermission('dashboard')"
                  routerLink="dashboard"><i class="icon icon-home"></i><span>{{'Dashboard' | translate}}</span></a></li>
              <li class="nav-item" *ngIf="commonService.haveRoutePermission('hr-list')"><a class="nav-link"
                  routerLink="hr-list"><i class="icon icon-customer"></i><span>{{'HR' | translate}}</span></a></li>
              <li class="nav-item" *ngIf="commonService.haveRoutePermission('IT')"><a class="nav-link"
                  routerLink="IT"><i class="icon icon-it"></i><span>{{'IT' | translate}}</span></a>
              </li>
              <li class="nav-item" *ngIf="commonService.haveRoutePermission('asset')"><a class="nav-link"
                  routerLink="asset"><i class="icon icon-assets"></i><span>{{'Assets' | translate}}</span></a></li>
              <!-- <li class="nav-item" *ngIf="commonService.userRole.Admin"><a class="nav-link"
                  routerLink="shared-mailbox"><i class="icon icon-mailbox"></i><span>Shared Mailbox</span></a></li> -->
              <li class="nav-item cursor-pointer"
                *ngIf="commonService.haveRoutePermission('tasks') || commonService.haveRoutePermission('emailtemplate') || commonService.haveRoutePermission('locale')"
                (click)="showSettingMenus = !showSettingMenus">
                <a class="nav-link"><i class="icon icon-setting"></i><span>{{'Settings' | translate}}</span></a>
              </li>
              <ng-container *ngIf="showSettingMenus">
                <li class="nav-item p-l-10" *ngIf="commonService.haveRoutePermission('tasks')"><a class="nav-link"
                    routerLink="tasks"><i class="icon icon-task"></i><span>{{'Tasks' | translate}}</span></a></li>
                <li class="nav-item p-l-10" *ngIf="commonService.haveRoutePermission('emailtemplate')"><a
                    class="nav-link" routerLink="emailtemplate"><i class="icon icon-email"></i><span>{{'Email Template'
                      | translate}}</span></a></li>
                <li class="nav-item p-l-10" *ngIf="commonService.haveRoutePermission('locale')"><a class="nav-link"
                    routerLink="locale"><i class="icon icon-locale"></i><span>{{'Locale' | translate}}</span></a></li>
                <li class="nav-item p-l-10" *ngIf="commonService.haveRoutePermission('settings')"><a class="nav-link"
                    routerLink="settings"><i class="icon icon-locale"></i><span>{{'page_settings' | translate}}</span></a></li>
              </ng-container>
              <li class="nav-item" *ngIf="commonService.haveRoutePermission('reports')"><a class="nav-link"
                routerLink="reports"><i class="icon icon-reports"></i><span>{{'Reports' | translate}}</span></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-10 rightPanel">
        <router-outlet *ngIf="!isIframe"></router-outlet>
      </div>
    </div>
  </div>
</div>
<app-landing *ngIf="!loggedIn"></app-landing>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-clockwise-fade">
</ngx-spinner>