<!-- listing -->
<div class="listing assets-listing">
	<div class="card b-radius">
		<table>
			<thead>
				<th translate="products_name"></th>
				<th translate="products_price"></th>
				<th translate="products_type"></th>
				<th translate="products_sub_type"></th>
				<th translate="products_vendor"></th>
				<th translate></th>
			</thead>
			<tbody>
				<tr *ngFor="let asset of assets">
					<td class="cursor-pointer" (click)="addAssets(asset)">{{asset.name}}</td>
					<td>{{asset.price}}</td>
					<td>{{asset.type}}</td>
					<td>{{asset.subType}}</td>
					<td>{{asset.vendor}}</td>
					<td>
						<div class="dropdown action d-inline-block">
							<a class="nav-link p-0" data-toggle="dropdown" href="#" aria-expanded="true">
								<i class="fa fa-ellipsis-h"></i>
							</a>
							<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right logout ">
								<a href="javascript:void(0);" class="dropdown-item" (click)="addAssets(asset)"><i
										class="fa fa-pencil mr-2"></i> {{'products_edit' | translate}}</a>
								<a href="javascript:void(0);" class="dropdown-item" (click)="deleteAsset(asset)"><i
										class="fa fa-times mr-2"></i> {{'products_delete' | translate}}</a>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<ngb-pagination
	[(page)]="pageNo"
	[pageSize]="pageSize"
	[collectionSize]="totalCount"
	(pageChange)="onScroll($event)"
	></ngb-pagination>
</div>