import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common.service';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { MailboxService } from './mailbox.service';

@Component({
  selector: 'app-shared-mailbox',
  templateUrl: './shared-mailbox.component.html',
  styleUrls: ['./shared-mailbox.component.scss']
})
export class SharedMailboxComponent implements OnInit {
  mailboxes:any=[];
  filters: any = {};
  sbs: any = null;
  pageNo: number = 1;
  pageSize: number = 20;
  constructor(
    private mailService: MailboxService,
    private modalService: NgbModal,
    private _toastr: ToastrService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.resetDetails();
  }

  getMails(loader: boolean = true){
    var req = `?pageNo=${this.pageNo}&pageSize=${this.pageSize}`;
    if (this.filters.searchText) {
      req += '&searchText=' + this.filters.searchText;
    }
    if (this.sbs) this.sbs.unsubscribe();
    this.sbs = this.mailService.getMail(req,loader).subscribe((res:any)=>{
      this.mailboxes = this.mailboxes.concat(res);
    });
  }
  deleteMail(mail) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.modalData = {
      heading: 'Confirm to delete',
      description: 'Do you want to delete mailbox?'
    };
    modalRef.result.then(() => {
      this.mailService.deleteEmp(mail.id).subscribe((res: any) => {
        this.resetDetails();
        this._toastr.success('mailbox deleted successfully');
      })
    }).catch(e => {
    });
  }
  onScroll() {
    if (this.mailboxes && this.mailboxes.length) {
      this.pageNo++;
      this.getMails();
    }
  }
  onSearchTxt() {
    this.mailboxes = [];
    this.pageNo=1;
    this.getMails(false);
  }
  resetDetails() {
    this.mailboxes = [];
    this.pageNo=1;
    this.getMails();
  }
}
